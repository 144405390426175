import { Component } from '@angular/core';
import { Blog } from '../../model/blog';
import { ActivatedRoute } from '@angular/router';
import { loadBlog } from '../../action/blog.actions';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrl: './blog-detail.component.scss'
})
export class BlogDetailComponent {
  blog: Blog;
  currentBlogUrl: string;
  sanitizedContent: SafeHtml;
  // sanitizedContent: SafeHtml;
  constructor(private route: ActivatedRoute,private store: Store<{blog:{blog:Blog[]}}>,
    private DomSanitizer:DomSanitizer,private titleService:Title,private metaService:Meta,
    private router: Router, private location: Location,private snackBar: MatSnackBar) {
    this.store.dispatch(loadBlog());
  }

  ngOnInit() {
    const title = this.route.snapshot.paramMap.get('title');
this.store
  .select(state => state.blog)
  .pipe(
    map((blogState: any) => blogState.blog),
    map(blogs =>
      blogs.find(blog =>
        blog.title.replace(/\s+/g, '').toLowerCase() === title.replace(/\s+/g, '').toLowerCase()
      )
    ),
    filter(blog => !!blog),
    take(1)
  )
  .subscribe({
    next: blog => {
      this.blog = blog;
      this.setSeoMetaTags(blog);
    },
    error: error => {
      console.error('Error fetching blog:', error);
    }
  });

  this.currentBlogUrl = `${window.location.origin}${this.location.prepareExternalUrl(this.router.url)}`;
  console.log('Current Blog URL:', this.currentBlogUrl);
    // this.sanitizedContent = this.DomSanitizer.bypassSecurityTrustHtml(this.blog.content);
    // console.log(this.sanitizedContent)
  }


  getImageUrl(blog: Blog): string {
    return blog.imageUrl ? blog.imageUrl : 'default-image-url';
  }
  setSeoMetaTags(blog: any): void {
    // Set the page title
    this.titleService.setTitle(blog.title);

    // Set meta tags for description and keywords
    this.metaService.updateTag({ name: 'description', content: blog.seodescription });
    this.metaService.updateTag({ name: 'keywords', content: blog.seokeyword });

    // Open Graph meta tags (for better social media sharing)
    this.metaService.updateTag({ property: 'og:title', content: blog.seotitle });
    this.metaService.updateTag({ property: 'og:description', content: blog.seodescription });
    // this.metaService.updateTag({ property: 'og:image', content: blog.imageUrl });

    // Twitter Card meta tags (optional, for Twitter sharing)
    this.metaService.updateTag({ name: 'twitter:title', content: blog.seotitle });
    this.metaService.updateTag({ name: 'twitter:description', content: blog.seodescription });
    // this.metaService.updateTag({ name: 'twitter:image', content: blog.imageUrl });
  }

  copyToClipboard(url: string): void {
    navigator.clipboard.writeText(url).then(
      () => this.snackBar.open('URL copied!', 'Close', { duration: 2000 }),
      err => console.error('Error copying URL:', err)
    );
  }
}
