import { Component } from '@angular/core';

@Component({
  selector: 'app-hosbay',
  templateUrl: './hosbay.component.html',
  styleUrls: ['./hosbay.component.scss']
})
export class HosbayComponent {
  selectedclass: number = 1;

  selectclass(index: number): void {
    this.selectedclass = index;
  }
}
