import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { LocationStrategy,HashLocationStrategy } from '@angular/common';
import { MedeilposComponent } from './components/pages/medeilpos/medeilpos.component';
import { MedeilhqComponent } from './components/pages/medeilhq/medeilhq.component';
import { HosbayComponent } from './components/pages/hosbay/hosbay.component';
import { MedeilstandaloneComponent } from './components/pages/medeilstandalone/medeilstandalone.component';
import { AllotmedComponent } from './components/pages/allotmed/allotmed.component';
import { PosnavbarComponent } from './components/common/posnavbar/posnavbar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProductloginComponent } from './components/pages/productlogin/productlogin.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import { NgxSmartModalModule,NgxSmartModalService } from 'ngx-smart-modal';
import { HqnavbarComponent } from './components/common/hqnavbar/hqnavbar.component';
import { MedeilnavbarComponent } from './components/common/medeilnavbar/medeilnavbar.component';

import { AllotmednavbarComponent } from './components/common/allotmednavbar/allotmednavbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import {MatCardModule} from '@angular/material/card';
// import { IgxDatePickerModule } from "igniteui-angular";z

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MedeilpospricingComponent } from './components/pages/productpricing/medeilpospricing/medeilpospricing.component';
import { MedeilhqpricingComponent } from './components/pages/productpricing/medeilhqpricing/medeilhqpricing.component';
import { AllotmedpricingComponent } from './components/pages/productpricing/allotmedpricing/allotmedpricing.component';
import { MedeilpricingComponent } from './components/pages/productpricing/medeilpricing/medeilpricing.component';
import { GeneralpharmacyComponent } from './components/pages/verticals/generalpharmacy/generalpharmacy.component';
import { GeneralopticalsComponent } from './components/pages/verticals/generalopticals/generalopticals.component';
import { GeneralcosmeticsComponent } from './components/pages/verticals/generalcosmetics/generalcosmetics.component';
import { GeneralveterinaryComponent } from './components/pages/verticals/generalveterinary/generalveterinary.component';
import { GeneralhealthcareComponent } from './components/pages/verticals/generalhealthcare/generalhealthcare.component';
import { MedeicaldeviceComponent } from './components/pages/verticals/medeicaldevice/medeicaldevice.component';
import { GeneralagroComponent } from './components/pages/verticals/generalagro/generalagro.component';
import { SurgicaldeviceComponent } from './components/pages/verticals/surgicaldevice/surgicaldevice.component';
import { MedeildownloadComponent } from './components/pages/medeildownload/medeildownload.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ViewblogComponent } from './components/pages/viewblog/viewblog.component';
import { QuillModule } from 'ngx-quill';
import {MatButtonModule} from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { BlogDialogComponent } from './components/pages/blog-dialog/blog-dialog.component';
import { blogReducer } from './components/reducer/blog.reducer';
import { BlogDetailComponent } from './components/pages/blog-detail/blog-detail.component';
import { BlogEffects } from './components/effect/blog.effects';
import { AdminloginComponent } from './components/pages/adminlogin/adminlogin.component';
import { MedeilBlogsComponent } from './components/pages/medeil-blogs/medeil-blogs.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';

import { PosenquiryComponent } from './components/landingpages/posenquiry/posenquiry.component';
import { PosdemorequestComponent } from './components/landingpages/posdemorequest/posdemorequest.component';
import { MedeilhqenquiryComponent } from './components/landingpages/medeilhqenquiry/medeilhqenquiry.component';
import { MedeilhqdemorequestComponent } from './components/landingpages/medeilhqdemorequest/medeilhqdemorequest.component';
import { MedeilenquiryComponent } from './components/landingpages/medeilenquiry/medeilenquiry.component';
import { MedeildemorequestComponent } from './components/landingpages/medeildemorequest/medeildemorequest.component';
import { JsonLdService } from 'ngx-seo';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    FooterStyleOneComponent,
    FooterStyleTwoComponent,
    ErrorComponent,
    LoginRegisterComponent,
    PrivacyPolicyComponent,
    ComingSoonComponent,
    ProductsDetailsComponent,
    ContactComponent,
    MedeilposComponent,
    MedeilhqComponent,
    HosbayComponent,
    MedeilstandaloneComponent,
    AllotmedComponent,
    PosnavbarComponent,
    ProductloginComponent,
    HqnavbarComponent,
    MedeilnavbarComponent,
    BlogDetailComponent,
    AllotmednavbarComponent,
    MedeilpospricingComponent,
    MedeilhqpricingComponent,
    AllotmedpricingComponent,
    MedeilpricingComponent,
    GeneralpharmacyComponent,
    GeneralopticalsComponent,
    GeneralcosmeticsComponent,
    GeneralveterinaryComponent,
    GeneralhealthcareComponent,
    MedeicaldeviceComponent,
    GeneralagroComponent,
    SurgicaldeviceComponent,
    MedeildownloadComponent,
    ViewblogComponent,
    BlogDialogComponent,
    AdminloginComponent,
    MedeilBlogsComponent,
    PosenquiryComponent,
    PosdemorequestComponent,
    MedeilhqenquiryComponent,
    MedeilhqdemorequestComponent,
    MedeilenquiryComponent,
    MedeildemorequestComponent,
    CaseStudyComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    NgSelectModule,
    MatFormFieldModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatInputModule,
    NgxSmartModalModule.forRoot(),
    MatIconModule,
    MatNativeDateModule,
    MatCardModule,
    MatDatepickerModule,
    QuillModule.forRoot(),
    StoreModule.forRoot({blog: blogReducer}, {}),
    EffectsModule.forRoot([BlogEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() })
    // IgxDatePickerModule

  ],
  providers: [NgxSmartModalService,JsonLdService], //{provide:LocationStrategy,useClass:HashLocationStrategy},
  bootstrap: [AppComponent]
})
export class AppModule { }
