

<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>
<!--
<div class="banner-area-two client-slider owl-carousel owl-theme">

    <div class="client-item">
      <picture>
        <source
          srcset="https://medeilpos.blob.core.windows.net/websiteimage/Pharmacyapplication - Medeilcloud pos.webp,
          https://medeilpos.blob.core.windows.net/websiteimage/Pharmacyapplication - Medeilcloud pos.webp,
          https://medeilpos.blob.core.windows.net/websiteimage/Pharmacyapplication - Medeilcloud pos.webp"
          sizes="(max-width: 600px) 100vw,
                 (max-width: 1200px) 50vw,
                 33vw"
          type="image/webp">
        <img
          src="https://medeilpos.blob.core.windows.net/websiteimage/Pharmacyapplication - Medeilcloud pos.webp"
          alt="Pharmacyapplication - Medeilcloud pos"
          width="600" height="400"
          loading="lazy"
          style="width: 100%; height: auto;">
      </picture>
      <div class="overlay-buttons row">
        <div class="col-lg-6">
          <a href="https://secure.medeil.io/medeilpos/eng/23pos/#/usersignup" target="_blank" rel="noopener">
            <button type="button" class="default-btn1">14 Days Free Trial</button>
          </a>
        </div>
        <div class="col-lg-6">
          <button type="button" class="default-btn1" (click)="appointment('Medeil Cloud POS')">Demo Request</button>
        </div>
      </div>
    </div>
    <div class="client-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/retailchainsoftware - medeilcloud HQ.webp" alt="retailchainsoftware - medeilcloud HQ"   loading="lazy" >
        <div class="overlay-buttonshq">
            <button type="button" class="hqbtn" (click)="appointment('Medeil Cloud HQ')">Demo Request</button>
        </div>
    </div>
    <div class="client-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacymanagementsystem - Medeil.webp" alt="pharmacymanagementsystem - Medeil" loading="lazy" >
        <div class="overlay-buttonsstand row">
            <div class="col-lg-6">
                <button type="button" class="standalonebtn">10 Days Trial</button>
            </div>
            <div class="col-lg-6">
                <button type="button" class="standalonebtn" (click)="appointment('Medeil Standalone')">Free Edition Request</button>
            </div>
        </div>
    </div>
    <div class="client-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/Wholesale management software - Allotmed.webp" alt="Wholesale management software - Allotmed"  loading="lazy">
        <div class="overlay-buttonsallotmed">
            <button type="button" class="allotmedbtn" (click)="appointment('Allotmed')">Demo Request</button>
        </div>
    </div>
</div> -->
<div class="slider-area owl-carousel owl-theme">
  <div class="slider-item item-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="slider-content">
                  <h1>Revolutionize Your Pharmacy with Medeil Software</h1>
                          <button type="button" class="default-btn">Start Your 10-Day Free Trial</button>
                          <button type="button" class="default-btn" (click)="appointment('Medeil Standalone')">Request a Free Edition</button>
                </div>
            </div>
        </div>
    </div>
</div>
  <div class="slider-item item-bg1">
      <div class="d-table">
          <div class="d-table-cell">
              <div class="container">
                  <div class="slider-content">
                      <h1>Experience the Power of MedeilCloud POS – Pharmacy & Retail Simplified</h1>

                          <a href="https://secure.medeil.io/medeilpos/eng/23pos/#/usersignup" target="_blank" rel="noopener">
                            <button type="button" class="default-btn">Start Your 14-Day Free Trial</button>
                          </a>

                          <button type="button" class="default-btn" (click)="appointment('Medeil Cloud POS')">Demo Request</button>

                  </div>
              </div>
          </div>
      </div>
  </div>



  <div class="slider-item item-bg3">
      <div class="d-table">
          <div class="d-table-cell">
              <div class="container">
                  <div class="slider-content">
                      <h1>Centralize Your Chain Store Operations with MedeilCloud HQ</h1>
                            <button type="button" class="default-btn">10 Days Trial</button>
                            <button type="button" class="default-btn" (click)="appointment('Medeil Cloud HQ')">Free Edition Request</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="slider-item item-bg5">
      <div class="d-table">
          <div class="d-table-cell">
              <div class="container">
                  <div class="slider-content">
                      <h1>Optimize Your Hospital Inventory with Hosbay</h1>
                            <button type="button" class="default-btn">10 Days Trial</button>
                            <button type="button" class="default-btn" (click)="appointment('Hosbay')">Free Edition Request</button>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="slider-item item-bg4">
      <div class="d-table">
          <div class="d-table-cell">
              <div class="container">
                  <div class="slider-content">
                      <h1>Streamline Your Distribution Operations with AllotMed</h1>
                        <button type="button" class="default-btn" (click)="appointment('Allotmed')">Request a Free Demo</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div #counterSection class="container-fluid counter-section text-center py-3">
  <!-- <h3 class="title">Trusted by Healthcare Professionals & Retailers Worldwide</h3> -->
  <div class="section-title1 gradient-text section-new">
    <h2>Trusted by Healthcare Professionals & Retailer Worldwide</h2>
  </div>
  <div class="row justify-content-center mt-3">
    <div *ngFor="let counter of counters" class="col-md-3 col-sm-6">
      <div class="counter-box">
        <i [class]="counter.icon"></i>
        <h4 class="counter">0</h4> <!-- This is where the counter updates -->
        <h5>{{ counter.label }}</h5>
      </div>
    </div>
  </div>
</div>


<!-- appointment form -->
<ngx-smart-modal #enquirypos identifier="enquirypos" [closable]="false">


   <div class="custom-modal">
      <button class="close-btn" (click)="closeModal()">X</button>
      <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
      </div>
        <div class="modal-body">
          <form [formGroup]="enquiryForm">
            <div class="form-group">
              <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
              <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
              <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
              <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
              <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
               <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
               <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
             </div>
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Country</strong></label>
              <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
              <select class="form-control"  formControlName="countryname1" style="font-size: 12px;" (change)="countrychange()">
               <!-- (change)="countrychange()" -->
               <option select disabled value="0" >---Select Country---</option>
               <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>

             </select>
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
              <div class="row">
                <div class="col-lg-3">
                  <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
                </div>
                <div class="col-lg-9">
                  <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
                  autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
                </div>
              </div>

            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Description</strong></label>
              <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
            </div>
            <!-- <div class="form-group">
              <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
              <input type="text" name="rec" class="form-control" id="recipient-name">
            </div> -->
            <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
            <div class="input-group">
               <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
                (change)="checkCaptcha()"/>
               <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
               [(ngModel)]="captcha"/>
               <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
             </div>
             <div *ngIf="ecaptcha">
               <p style="color: red">{{ errorcaptcha }}</p>
             </div>
            <div class="form-group">

                  <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                      <input type="checkbox" checked="checked" formControlName="checkbox">
                      <span class="checkmark"></span>
                  </label>
            </div>
            <div class="btn-group mt-3" *ngIf="!showElement">
              <button type="button" class="enquiry-btn" (click)="submitenquery()" >Submit</button>

            </div>


          </form>
        </div>
  </div>
</ngx-smart-modal>
<ngx-smart-modal #appointmentform identifier="appointmentform" [closable]="false">


   <div class="custom-modal"  >
      <button class="close-btn" (click)="closeModal()">X</button>
       <div class="modal-header-appointment">
           <h4 class="modal-title" id="exampleModalLabel">Demo Request</h4>
       </div>
         <div class="modal-body">

            <div class="row">
               <div class="col-lg-12">
                  <mat-form-field >

                     <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="slectdate($event)" >
                     <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                     <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>

               </div>
               <div  align="center" *ngIf="showElement">
                  <img height="200px" width="200px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="pharmacy gif image" loading="lazy" >
                </div>
               <div class="col-lg-12" *ngIf="appointment_time">

                   <div class="button-container">
                     <button class="flex-button" *ngFor="let time of timeSlots" (click)="selectTime(time)" [ngClass]="{ 'clicked': selectedTime === time }">{{ time }}</button>
                 </div>
                 <div  align="center" *ngIf="showElementsave">
                  <img height="100px" width="100px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="pharmacy gif image" loading="lazy" >
                </div>
                   <div class="shedule-submit">
                     <button class="shedulebtn" (click)="savelead()">Submit</button>
                   </div>
               </div>
            </div>

         </div>
   </div>
</ngx-smart-modal>




<section class="city-area-two pb-70"   >

    <div class="container">
        <div class="text-center section-title1 gradient-text section-new mt-2 pb-20">
            <h2>Transforming Pharmacy & Selective Retail Management</h2>
        </div>

        <div class="align-items-center no-scroll flex-container mt-4">
            <!-- <div class="city-card owl-carousel owl-theme"> -->
                <div class="flexbox" (click)="selectDiv(1)" [ngClass]="{ 'selected': selectedDiv === 1 }">Medeil</div>
                <div class="flexbox" (click)="selectDiv(2)" [ngClass]="{ 'selected': selectedDiv === 2 }">Medeil Cloud POS</div>
                <div class="flexbox" (click)="selectDiv(3)" [ngClass]="{ 'selected': selectedDiv === 3 }">Medeil Cloud HQ</div>
                <div class="flexbox" (click)="selectDiv(4)" [ngClass]="{ 'selected': selectedDiv === 4 }">Hosbay</div>
                <div class="flexbox" (click)="selectDiv(5)" [ngClass]="{ 'selected': selectedDiv === 5 }">Allotmed</div>
            <!-- </div> -->


        </div>
        <!-- <div class="heading-container mt-5" >
          <h3 class="underlined-heading">Simplify Your Pharmacy or Retail Operations with Our Innovative Solutions</h3>
        </div> -->
        <div class="heading-container mt-5" *ngIf="medeil">
          <h3 class="underlined-heading" >
            Simplify Your Pharmacy or Retail Operations with Our Innovative Solutions
          </h3>
        </div>
        <div class="heading-container mt-5" *ngIf="pos">
          <h3 class="underlined-heading" >
           Transform Your Retail and Pharmacy Operations with MedeilCloud POS – Seamless, Secure, and Scalable Solutions for Modern Businesses.
          </h3>
        </div>
        <div class="heading-container mt-5" *ngIf="hq">
          <h3 class="underlined-heading" >
            <!--  <i class="fas fa-thumbs-up"></i> -->
            Centralized Management for Chain Pharmacies and Multi-Store Operations
          </h3>
        </div>
        <div class="heading-container mt-5" *ngIf="hosbay">
          <h3 class="underlined-heading" >
             Optimize Your Hospital Inventory Management
          </h3>
        </div>
        <div class="heading-container mt-5" *ngIf="allotmed">
          <h3 class="underlined-heading" >
          Transform Your Distribution Management
          </h3>
        </div>

        <div class="row product-container pt-45 mt-5" *ngIf="pos">

            <div class="col-lg-4 col-md-12">
                <div class="city-card new-city">
                    <p style="font-size: 17px;color: black;font-weight: 600;">
                      Our Pharmacy POS / Retail POS system offers a comprehensive solution for managing sales, inventory, and customer data. Designed for ease of use, it provides real-time analytics and reporting to help you make informed business decisions. Enhance your retail operations with a system that supports growth and improves customer experiences. our solution ensures seamless transactions and improved customer satisfaction. Optimize your workflow and reduce errors with our state-of-the-art POS technology.
                    </p>
                    <!-- <a routerLink="/"><img src="assets/img/products/Eng black iphone.png" alt="Images"></a> -->
                    <!-- <div class="content content-new">
                        <a routerLink="/"><h3>Fort Worth</h3></a>
                        <span>17 Locations</span>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="city-card">
                    <!-- <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/cloudpharmacysoftware - medeilcloudpos.webp" alt="cloudpharmacysoftware - medeilcloudpos" (click)="enquirynow('Medeil Cloud POS')" loading="lazy" ></a> -->
                    <a routerLink="/"><img src="assets/img/test/pos.png" alt="cloud-pharmacy-software - medeilcloudpos" (click)="enquirynow('Medeil Cloud POS')" loading="lazy" ></a>
                    <!-- <div class="content content-new">
                        <a routerLink="/"><h3>San Antonio</h3></a>
                        <span>13 Locations</span>
                    </div> -->
                </div>
                <div class="mt-4" >
                  <h4 class="underlined-heading" >
                     Pharmacy POS Solutions / Retail POS
                  </h4>
                </div>
            </div>

            <div class="col-lg-12 mt-4">
                <div class="city-card row display-card">

                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/industry/pharmacy-management-software">Pharmacies</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/industry/optical-store-management">Optical</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/industry/medical-store-software">Healthcare stores</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/industry/beauty-software">Beauty & Cosmetics</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/industry/veterinary-software">Veterinary stores</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/industry/agriculture-software">Agrochemical</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="vertical-list">
                            <a routerLink="/industry/medical-device-software">Medical Device</a>
                        </div>
                    </div>
                </div>

            </div>
            <hr class="mt-4">
            <!-- <div class="col-lg-9 displaycol mt-4 ">
              Unlock the full capabilities of our Pharmacy and Retail POS systems with a 14-day trial. Available on both web and mobile platform
            </div>
            <div class="col-lg-3 col-sm-6 mt-4">
                <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Medeil Cloud POS')"> Start Your Free 14-Day Trial</button>
            </div> -->
            <div class="row align-items-start justify-content-between trial-section">
              <div class="col-lg-9 col-md-12 text-content">
                  Unlock the full capabilities of our Pharmacy and Retail POS systems with a 14-day trial.
                  Available on both web and mobile platforms.
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 button-container">
                  <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Medeil Cloud POS')">
                      Start Your Free 14-Day Trial
                  </button>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 button-container">
                  <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Medeil Cloud POS - Demo Request')">
                    Demo Request
                  </button>
              </div>
          </div>


        </div>
        <div class="row product-container pt-45 mt-5" *ngIf="medeil">


            <div class="col-lg-4 col-md-12">
                <div class="city-card new-city">
                    <p style="font-size: 17px;color: black;font-weight: 600;">
                      Medeil is a comprehensive Standalone software for pharmacy management system designed to streamline operations for pharmacies. With its user-friendly interface and robust features, Medeil helps pharmacist to manage drug inventory, pharmacy billing, CRM, Distributor information, short expiry management, expired product management, schedule drug, pharmacist register, patient records,  and more efficiently.
                    </p>
                </div>
            </div>

                <div class="col-lg-8 col-md-12" >
                    <div class="city-card medeil-card">

                        <!-- <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacysoftware - medeil standalone.webp"   alt="pharmacysoftware - medeil standalone" loading="lazy" (click)="enquirynow('Medeil Standalone')"></a> -->
                        <a routerLink="/"><img src="assets/img/test/medeil.png" loading="lazy" (click)="enquirynow('Medeil Standalone')"></a>

                        <!-- <button class="medeil-btn" (click)="enquirynow('Medeil Standalone')">Download</button> -->
                    </div>
                    <div class="heading-container mt-4">
                      <h4 class="underlined-heading" >
                        Pharmacy Management Software
                      </h4>
                    </div>
                </div>

            <div class="col-lg-12 mt-4">
                <div class="city-card row display-card">

                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/pharmacy-management-software">Pharmacies</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/optical-store-management">Optical</a>
                     </div>
                 </div>

                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/medical-store-software">Healthcare stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/beauty-software">Beauty & Cosmetics</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/veterinary-software">Veterinary stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/agriculture-software">Agrochemical</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/medical-device-software">Medical Device</a>
                     </div>
                 </div>
                </div>
            </div>
            <hr class="mt-4">
            <!-- <div class="col-lg-9 displaycol mt-4 ">
              Start Your Journey to Efficient Healthcare Management with Medeil Free Edition!
            </div>
            <div class="col-lg-3 col-sm-6  mt-2">
                <button type="submit" class="default-btn sign-up-btn"  (click)="enquirynow('Medeil Standalone')">Free Edition Download</button>
            </div> -->
            <div class="row align-items-start justify-content-between trial-section">
              <div class="col-lg-9 col-md-12 text-content">
                Start Your Journey to Efficient Healthcare Management with Medeil Free Edition!
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 button-container">
                <button type="submit" class="default-btn sign-up-btn"  (click)="enquirynow('Medeil Standalone')">Free Edition Download</button>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 button-container">
                <button type="submit" class="default-btn sign-up-btn"  (click)="enquirynow('Medeil Standalone- Demo Request')">Demo Request</button>
              </div>
          </div>

        </div>

        <div class="row product-container pt-45 mt-5" *ngIf="hq">
            <div class="col-lg-4 col-md-12">
                <div class="city-card new-city">
                    <p style="font-size: 16px;color: black;font-weight: 600;">
                      MedeilCloud HQ offers a powerful solution for managing chain pharmacies and multiple store locations with ease. This software provides centralized control over inventory, sales, and customer data, ensuring consistency and efficiency across all your outlets. With real-time analytics and reporting, MedeilCloud HQ empowers you to make data-driven decisions that enhance operational performance and profitability.
                    </p>

                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="city-card">
                    <!-- <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacychainsoftware - medeilcloud hq.webp"   alt="pharmacychainsoftware - medeilcloud hq" (click)="enquirynow('Medeil Cloud HQ')" loading="lazy"></a> -->
                    <a routerLink="/"><img src="assets/img/test/hq.png"   alt="pharmacy-chain-software - medeilcloud hq" (click)="enquirynow('Medeil Cloud HQ')" loading="lazy"></a>

                </div>
                <div class="heading-container mt-4">
                  <h4 class="underlined-heading" >
                    Large pharmacy chains and franchises Store software
                  </h4>
                </div>
            </div>

            <div class="col-lg-12 mt-4">
                <div class="city-card row display-card">

                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/pharmacy-management-software">Pharmacies</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/optical-store-management">Optical</a>
                     </div>
                 </div>

                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/medical-store-software">Healthcare stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/beauty-software">Beauty & Cosmetics</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/veterinary-software">Veterinary stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/agriculture-software">Agrochemical</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/medical-device-software">Medical Device</a>
                     </div>
                 </div>
                </div>

            </div>
            <hr class="mt-4">
            <!-- <div class="col-lg-9 displaycol mt-4 ">
              Scale Your Pharmacy Chain Or Optical chain or Chain Store software
            </div>
            <div class="col-lg-3 col-sm-6 mt-4">
                <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Medeil Cloud HQ')"> Request a Demo</button>
            </div> -->

            <div class="row align-items-start justify-content-between trial-section">
              <div class="col-lg-9 col-md-12 text-content">
                Scale Your Pharmacy Chain Or Optical chain or Chain Store software
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 button-container">
                <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Medeil Cloud HQ')"> Request a Free Edition</button>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 button-container">
                <button type="submit" class="default-btn sign-up-btn"  (click)="enquirynow('Medeil Cloud HQ - Demo Request')">Demo Request</button>
              </div>
          </div>

        </div>
        <div class="row product-container pt-45 mt-5" *ngIf="hosbay">
          <div class="col-lg-4 col-md-12">
              <div class="city-card new-city">
                  <p style="font-size: 16px;color: black;font-weight: 600;">
                    Hosbay is a cutting-edge hospital inventory management system designed to enhance the efficiency and accuracy of your healthcare operations. With features like automated stock tracking, real-time inventory updates, and comprehensive reporting, Hosbay ensures that your hospital maintains optimal stock levels, reduces waste, and improves patient care. This software is essential for hospitals looking to streamline their supply chain and enhance operational efficiency.
                  </p>

              </div>
          </div>

          <div class="col-lg-8 col-md-12">
              <div class="city-card">
                  <!-- <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/distributionmanagementsoftware- allotmed.webp"   alt="distribution management software- allotmed" (click)="enquirynow('Allotmed')" loading="lazy"></a> -->
                  <a routerLink="/"><img src="assets/img/test/hosbay.png"   alt="distribution management software- allotmed" (click)="enquirynow('Allotmed')" loading="lazy"></a>

              </div>
              <div class="heading-container mt-4">
                <h4 class="underlined-heading" >
                  Streamlined Solutions for Efficient Hospital Inventory management & Operations
                </h4>
              </div>
          </div>

          <div class="col-lg-12 mt-4">
              <div class="city-card row display-card">

                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                   <div class="vertical-list">
                       <a routerLink="/industry/pharmacy-management-software">Pharmacies</a>
                   </div>
               </div>
               <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                   <div class="vertical-list">
                       <a routerLink="/industry/optical-store-management">Optical</a>
                   </div>
               </div>

               <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                   <div class="vertical-list">
                       <a routerLink="/industry/medical-store-software">Healthcare stores</a>
                   </div>
               </div>
               <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                   <div class="vertical-list">
                       <a routerLink="/industry/beauty-software">Beauty & Cosmetics</a>
                   </div>
               </div>
               <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                   <div class="vertical-list">
                       <a routerLink="/industry/veterinary-software">Veterinary stores</a>
                   </div>
               </div>
               <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                   <div class="vertical-list">
                       <a routerLink="/industry/agriculture-software">Agrochemical</a>
                   </div>
               </div>
               <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                   <div class="vertical-list">
                       <a routerLink="/industry/medical-device-software">Medical Device</a>
                   </div>
               </div>
              </div>
          </div>
          <hr class="mt-4">
          <!-- <div class="col-lg-9 displaycol mt-4 ">
            See How Hosbay Can Revolutionize Your Hospital's Inventory Management!"
          </div>
          <div class="col-lg-3 col-sm-6 mt-2">
              <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Allotmed')">Request a Demo</button>
          </div> -->

          <div class="row align-items-start justify-content-between trial-section">
            <div class="col-lg-9 col-md-12 text-content">
              See How Hosbay Can Revolutionize Your Hospital's Inventory Management!
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 button-container">
              <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Hosbay - Demo Request')"> Request a Free Edition</button>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 button-container">
              <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Hosbay')">Request a Demo</button>
            </div>
        </div>
      </div>
        <div class="row product-container pt-45 mt-5" *ngIf="allotmed">
            <div class="col-lg-4 col-md-12">
                <div class="city-card new-city">
                    <p style="font-size: 16px;color: black;font-weight: 600;">
                      Allotmed is a robust distribution management software designed to optimize your supply chain processes. With features like order management, inventory tracking, and real-time analytics, Allotmed ensures seamless distribution operations, reduces costs, and enhances customer satisfaction. This software is ideal for businesses looking to improve their distribution efficiency and gain a competitive edge in the market.
                    </p>

                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="city-card">
                    <!-- <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/distributionmanagementsoftware- allotmed.webp"   alt="distribution management software- allotmed" (click)="enquirynow('Allotmed')" loading="lazy"></a> -->
                    <a routerLink="/"><img src="assets/img/test/allotmed.png"   alt="distribution management software- allotmed" (click)="enquirynow('Allotmed')" loading="lazy"></a>
                </div>
                <div class="heading-container mt-4">
                  <h4 class="underlined-heading" >
                    Efficient Solutions for Streamlined Supply Chain Operations
                  </h4>
                </div>
            </div>

            <div class="col-lg-12 mt-4">
                <div class="city-card row display-card">

                  <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/pharmacy-management-software">Pharmacies</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/optical-store-management">Optical</a>
                     </div>
                 </div>

                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/medical-store-software">Healthcare stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/beauty-software">Beauty & Cosmetics</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/veterinary-software">Veterinary stores</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/agriculture-software">Agrochemical</a>
                     </div>
                 </div>
                 <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                     <div class="vertical-list">
                         <a routerLink="/industry/medical-device-software">Medical Device</a>
                     </div>
                 </div>
                </div>

            </div>
            <hr class="mt-4">
            <!-- <div class="col-lg-9 displaycol mt-4 ">
              Discover How Allotmed Can Elevate Your Distribution Management!
            </div>
            <div class="col-lg-3 col-sm-6 mt-2">
                <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Allotmed')">Request a Demo</button>
            </div> -->
            <div class="row align-items-start justify-content-between trial-section">
              <div class="col-lg-9 col-md-12 text-content">
                Discover How Allotmed Can Elevate Your Distribution Management!
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 button-container">
                <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Allotmed - Demo Request')"> Request a Free Edition</button>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 button-container">
                <button type="submit" class="default-btn sign-up-btn" (click)="enquirynow('Allotmed')">Request a Demo</button>
              </div>
          </div>
        </div>

    </div>
</section>

<ngx-smart-modal #myModal identifier="myModal" [closable]="false">

    <div class="custom-modal">
        <button class="close-btn" (click)="closepopup()">X</button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
        </div>
        <div class="modal-body">
         <form [formGroup]="enquiryForm">
           <div class="form-group">
             <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
             <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
            </div>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Country</strong></label>
             <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
             <select class="form-control"  formControlName="countryname1" style="font-size: 13px;" (change)="countrychange()">
              <!-- (change)="countrychange()" -->
              <option select disabled value="0" >---Select Country---</option>
              <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>

            </select>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
             <div class="row">
               <div class="col-lg-3">
                 <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
               </div>
               <div class="col-lg-9">
                 <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
                 autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
               </div>
             </div>

           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Description</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
           </div>
           <!-- <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name">
           </div> -->
           <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
           <div class="input-group">
              <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
               (change)="checkCaptcha()"/>
              <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
              [(ngModel)]="captcha"/>
              <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
            </div>
            <div *ngIf="ecaptcha">
              <p style="color: red">{{ errorcaptcha }}</p>
            </div>
           <div class="form-group">

                 <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                     <input type="checkbox" checked="checked" formControlName="checkbox">
                     <span class="checkmark"></span>
                 </label>
           </div>
           <div  align="center" *ngIf="showElementsave">
            <img height="100px" width="100px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="medeil gif" loading="lazy" >
          </div>
           <div class="btn-group mt-3">
             <button type="button" class="enquiry-btn" (click)="savesignup()">Submit</button>

           </div>


         </form>
       </div>
    </div>

</ngx-smart-modal>

<!-- <div class="video-container">
   <iframe src="https://www.youtube.com/embed/dDcM1iqrYn4" frameborder="0" allowfullscreen></iframe>
</div> -->
<div class="newslider" >

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/anu-pharmacy.webp"   alt="medeil client image for anu-pharmacy" loading="lazy" >
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/apollo-hospitals.webp"   alt="medeil client image for apollo-hospitals" loading="lazy" >
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/bellamed-healthcare.webp"   alt="medeil client image for bellamed-healthcare" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/bk-heart-hospital.webp"   alt="medeil client image for bk-heart-hospital" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/booncholai-super-market.webp"   alt="medeil client image for booncholai-super-market" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/emedsure.webp"   alt="medeil client image for emedsure" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/express-pharmacy.webp"    alt="medeil client image for express-pharmacy" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/falcon-healthcare.webp"    alt="medeil client image for falcon-healthcare" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/global-bridge-pharmaceutical.webp"   alt="medeil client image for global-bridge-pharmaceutical" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/hawi-family-hostpital.webp"   alt="medeil client image for hawi-family-hostpital" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/henry-jackson-foundation.webp"   alt="medeil client image for henry-jackson-foundation" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/imcri.webp"   alt="medeil client image for imcri" loading="lazy">
  </div>

</div>

<div class="container">

    <div class="section-title1 gradient-text text-center mb-5">
        <h2>Our Solutions – Empowering Healthcare Providers</h2>
    </div>


    <div class="row pt-20 newfeature mt-4">
        <div class="col-lg-2 col-sm-6">
            <div class="category-btn"(click)="solutionlist(1)" >

                <ng-container>
                    <ng-container >
                      <div class="text-center" >
                         <label>

                            <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/supplychain.webp" alt="supply chain image"   class="fab" loading="lazy"/>

                            </a>

                         </label>
                         <p class="mt-2">Supply Chain Management</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
        </div>

        <div class="col-lg-2 col-sm-6 full-container" >
            <div class="category-btn" (click)="solutionlist(2)">

                <ng-container>
                    <ng-container >
                      <div  class="text-center">
                         <label>
                            <a>
                              <img src="https://medeilpos.blob.core.windows.net/websiteimage/manufacturer.webp" alt="medeil manufacturer image"   class="fab" loading="lazy" />


                            </a>

                         </label>
                         <p class="mt-2">Manufacturer</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>

        </div>



        <div class="col-lg-2 col-sm-6" >
            <div class="category-btn"(click)="solutionlist(3)" >

                <ng-container>
                    <ng-container >
                      <div  class="text-center">
                         <label>
                            <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/importexport.webp" alt="medeil import export image"    class="fab" loading="lazy" />

                            </a>

                         </label>
                         <p class="mt-2">Importer & Export Companies</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
        </div>

        <div class="col-lg-2 col-sm-6">
            <div class="category-btn" (click)="solutionlist(4)">

                <ng-container>
                    <ng-container >
                      <div  class="text-center">
                         <label>
                            <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/holesale.webp" alt="medeil holesale image" loading="lazy"  class="fab" />

                            </a>

                         </label>
                         <p class="mt-2">C&F & Wholesaler</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
        </div>
        <div class="col-lg-2 col-sm-6">
            <div class="category-btn" (click)="solutionlist(5)">

                <ng-container>
                    <ng-container >
                      <div class="text-center">
                         <label>
                            <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/retailchainstore.webp" alt="medeil retail chain store"  loading="lazy" class="fab" />

                            </a>

                         </label>
                         <p class="mt-2">Pharmacy Chain stores / Brand stores</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
        </div>
        <div class="col-lg-2 col-sm-6" >
            <div class="category-btn" (click)="solutionlist(6)">

                <ng-container>
                    <ng-container >
                      <div class="text-center">
                         <label>
                            <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/retail.webp" alt="medeil retail image"  class="fab" loading="lazy"/>

                            </a>

                         </label>
                         <p class="mt-2">Independent Pharmacy stores</p>
                      </div>
                    </ng-container>
                   </ng-container>
            </div>
        </div>

    </div>
    <section class="top-section">
        <div class="container">
          <div class="row" *ngIf="suplaychain">
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 start-product">
                <div class="row product-info">
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                       <ng-container>
                        <ng-container >
                          <div class="text-center">
                             <label>
                                <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/healthcare.webp" alt="medeil healthcare image"   class="fab" loading="lazy" />

                                </a>

                             </label>
                             <p class="mt-2">Healthcare</p>
                          </div>
                        </ng-container>
                       </ng-container>
                    </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">


                        <ng-container>
                            <ng-container >
                              <div  class="text-center">
                                 <label>
                                    <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.webp" alt="medeil medical device image"   class="fab" loading="lazy"/>

                                    </a>

                                 </label>
                                 <p class="mt-2">Medical device</p>
                              </div>
                            </ng-container>
                           </ng-container>

                    </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">


                        <ng-container>
                            <ng-container >
                              <div class="text-center">
                                 <label>
                                    <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharma.webp" alt="medeil pharmacy image icon"   class="fab" loading="lazy"/>

                                    </a>

                                 </label>
                                 <p class="mt-2">Surgical devices</p>
                              </div>
                            </ng-container>
                           </ng-container>

                    </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">


                        <ng-container>
                            <ng-container >
                              <div  class="text-center">
                                 <label>
                                    <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacitology.webp" alt="medeil pharmacitology image"   class="fab" loading="lazy"/>

                                    </a>

                                 </label>
                                 <p class="mt-2">Pharmaceutical and Life Sciences</p>
                              </div>
                            </ng-container>
                           </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 align-self-center">
              <div class="accordions-area">

                  <div class="ac-head">
                      <span>Supply chain management</span>
                  </div>

                  <div class="ac-body">
                      <div class="content">
                       <p>Supply chain management (SCM) software is used across a wide range of industries to streamline and optimize various aspects of the supply chain process. Here are some industries where SCM software is commonly used to manage start from manufacturing, wholesaling, Retailing logistics & transportation and managing distribution and warehousing in the single cloud application with mobility enabled app solution </p>
                      </div>

                  </div>

            </div>
            </div>
          </div>


          <div class="row" *ngIf="manufacturer">
            <div class="col-lg-6 start-product">
                <div class="row product-info" *ngIf="manufacturermainicon">

                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon" (click)="manfacturer(1)">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/lifescience.webp" alt="lifescience"   class="fab" loading="lazy"/>

                                 </a>

                              </label>
                              <p class="mt-2">Life sciences</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                    <!-- <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon" (click)="manfacturer(2)">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="assets/img/blog/food.png" alt="" class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Consumer Food Products companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div> -->

                    <!-- <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon"  (click)="manfacturer(3)">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="assets/img/blog/apparel.png" alt="" class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Apparel & fashions</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div> -->
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">


                        <ng-container>
                            <ng-container >
                              <div  class="text-center">
                                 <label>
                                    <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.webp" alt="medical device"   class="fab" loading="lazy"/>

                                    </a>

                                 </label>
                                 <p class="mt-2">Medical device</p>
                              </div>
                            </ng-container>
                           </ng-container>

                    </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon"  (click)="manfacturer(4)">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/veterinary.webp" alt="veterinary"   class="fab" loading="lazy"/>

                                 </a>

                              </label>
                              <p class="mt-2">Veterinary manufacturer</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                </div>
                <!-- ==========lifescience========== -->
                <div class="row product-info" *ngIf="lifescience">

                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharma.webp" alt="pharma"   class="fab" loading="lazy"/>

                                 </a>

                              </label>
                              <p class="mt-2">Pharmaceutical companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/bio.webp" alt="bio"   class="fab" loading="lazy"/>

                                 </a>

                              </label>
                              <p class="mt-2">Biotech companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.webp" alt="medeical device"  loading="lazy" class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Medical device companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/ayush.webp" alt="ayush" loading="lazy"   class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">AYUSH Companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/manfact.webp" alt="manfacturer" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Contract Manufacturing Organizations (CMOs)</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/research1.webp" alt="research" loading="lazy"   class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Contract Research Organizations (CROs)</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacitology.webp" alt="pharmacitology" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Generic Drug Manufacturers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/prod.webp" alt="prod"  loading="lazy" class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Nutraceutical Companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                </div>

                <div class="row product-info" *ngIf="veterinary">

                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vat.webp" alt="vat image"  loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Veterinary food product manufacturer</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vatvat.webp" alt="vatvat" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Veterinary Pharmaceutical companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>


                </div>
            </div>
            <div class="col-lg-6 align-self-center">

                <div class="accordions-area">
                  <div class="ac-head">
                      <span>Manufacturers</span>
                  </div>

                  <div class="ac-body">
                      <div class="content">
                        <p>Manufacturing business involves considering various factors such as scalability, industry-specific features, integration capabilities, user-friendliness, and cost-effectiveness of implementing solution help to improve your manufacturing capabilities by automation </p>
                      </div>

                  </div>

            </div>


          </div>
          </div>
          <div class="row" *ngIf="importexport">
            <div class="col-lg-6 start-product">
                <div class="row product-info" *ngIf="importexportmianicon">
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon"(click)="importexportmethod(1)">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/lifescience.webp" alt="life science" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Life sciences</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>


                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">


                        <ng-container>
                            <ng-container >
                              <div  class="text-center">
                                 <label>
                                    <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.webp" alt="medecil device"  loading="lazy" class="fab" />

                                    </a>

                                 </label>
                                 <p class="mt-2">Medical Device Exporter</p>
                              </div>
                            </ng-container>
                           </ng-container>

                    </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon" (click)="importexportmethod(4)">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/veterinary.webp" alt="veterinary" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Veterinary</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>


                </div>
                <div class="row product-info" *ngIf="ielifescience">
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/trade12.webp" alt="trade image" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Trading Houses and Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/trading.webp" alt="trading"  loading="lazy" class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Cold Chain Logistics Providers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/cosmetics.webp" alt="cosmetics"loading="lazy"   class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Cosmeceutical Companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/imp.webp" alt="import image" loading="lazy"   class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Pharmaceutical importer</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/impexp2.webp" alt="import and export image" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Pharmaceutical Exporter</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/dtrade.webp" alt="trade image" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Pharmaceutical Traders</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                </div>

                <div class="row product-info" *ngIf="ieveterrinary">
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vat.webp" alt="vat image" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Animal Health Product Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vatvat1.webp" alt="medeil vat image" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Veterinary Equipment Manufacturers and Suppliers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vatvat.webp" alt="vat image" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Veterinary Feed Additives and Nutrition Companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/veterinary.webp" alt="veterinary image" loading="lazy"   class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Veterinary Diagnostics and Testing Providers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>

                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/bio.webp" alt="bio image" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Animal Vaccines and Biological Suppliers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/consumer.webp" alt="consumer image" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Livestock and Poultry Health Management Companies</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                    <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/impexp2.webp" alt="import export image"  loading="lazy" class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Pet Care Product Importers and Exporters</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                </div>

            </div>
            <div class="col-lg-6 align-self-center">

                <div class="accordions-area">
                  <div class="ac-head">
                      <span>Importer & Export Companies</span>
                  </div>

                  <div class="ac-body">
                      <div class="content">
                       <p>Importer and exporter companies are crucial players in global trade, facilitating the movement of goods across borders.The specific activities, focus areas, and business models of each company can vary widely based on factors such as industry sector, geographic location, and market niche with best sotware automation in your Business.</p>
                      </div>

                  </div>

            </div>


          </div>
          </div>
          <div class="row" *ngIf="candf">
            <div class="col-lg-6 start-product">
                <div class="row product-info" *ngIf="cfmainicon">

                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacymed.webp" alt="pharmacy" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Pharmaceutical Wholesalers or Pharmaceutical Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.webp" alt="medical device"loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Medical Device Wholesalers or Medical Device Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/bio.webp" alt="bio image" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Biotechnology Wholesalers or Biotechnology Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/research1.webp" alt="research" loading="lazy"  class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Laboratory Equipment and Supplies Wholesalers</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/otherretail.webp" loading="lazy" alt="otherretail image"   class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Specialty Pharmaceuticals Wholesalers or Specialty Product Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/vat.webp" alt="vat image"  loading="lazy" class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Animal Health Product Wholesalers or Veterinary Product Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                     <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                        <ng-container>
                         <ng-container >
                           <div class="text-center">
                              <label>
                                 <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/consumer.webp" alt="consumer image" loading="lazy"   class="fab" />

                                 </a>

                              </label>
                              <p class="mt-2">Home Healthcare Product Distributors</p>
                           </div>
                         </ng-container>
                        </ng-container>
                     </div>
                </div>

            </div>
            <div class="col-lg-6 align-self-center">

                <div class="accordions-area">
                  <div class="ac-head">
                      <span>C&F & Wholesaler</span>


                  </div>

                  <div class="ac-body">
                      <div class="content">
                       <p>Wholesalers act as intermediaries between manufacturers and retailers, helping to distribute goods efficiently. Overall, both C&F and wholesalers play crucial roles in facilitating trade by managing the movement of goods and ensuring efficient distribution to end consumers.</p>
                      </div>

                  </div>

            </div>


          </div>
          </div>
          <div class="row" *ngIf="retailchainstore">
            <div class="col-lg-6 start-product">
                <div class="row product-info" *ngIf="retailchainmainicon">

                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacitology.webp" alt="pharmacitology" loading="lazy"  class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Pharmacy chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>

                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/healthcare.webp" alt="healthcare image"  loading="lazy"  class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Health and Wellness Retail Chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.webp" loading="lazy" alt="medical device image"   class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Medical Equipment and Supplies Chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/optical.webp" alt="optical image" loading="lazy"  class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Optical Retail Chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharma.webp" alt="pharma image" loading="lazy"   class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Urgent Care and Walk-in Clinic Chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/consumer.webp" alt="consumer image" loading="lazy"  class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Specialty Healthcare Chains</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/ayush.webp" alt="ayush iamge" loading="lazy"  class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">AYUSH Chain stores</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>

                </div>

            </div>
            <div class="col-lg-6 align-self-center">

                <div class="accordions-area">
                  <div class="ac-head">
                      <span>Retail Chain stores / Brand stores</span>

                  </div>

                  <div class="ac-body">
                      <div class="content">
                       <p>Retail chain stores are part of a larger network of stores operated by a single company. These stores typically have multiple locations across different regions or even countries. Retail chains can encompass various industries, including apparel, electronics, home goods, and more. Regarding Brand stores, also known as flagship stores or brand retail outlets, are retail locations operated by a specific brand or manufacturer to sell their own products directly to consumers.</p>
                      </div>

                  </div>

            </div>


          </div>
          </div>
          <div class="row" *ngIf="independentretail">
            <div class="col-lg-6 start-product">
                <div class="row product-info" *ngIf="independentmainicon">
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/pharmacymed.webp" loading="lazy" alt="pharmacy medeil image"   class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Pharmacies</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>

                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/ayush.webp" alt="ayush image" loading="lazy"  class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Ayurvedic stores</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                  <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/consumer.webp" alt="consumer image" loading="lazy"  class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Wellness store</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>

                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/healthcare.webp" alt="healthcare image" loading="lazy"  class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Healthcare store</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/mededevice.webp" loading="lazy" alt="medical device image"   class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Surgical store</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/optical.webp" alt="optical image" loading="lazy"  class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Optical shop </p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>
                 <div class="col-sm-6 col-xs-6 col-md-4 col-lg-4 product-icon">

                     <ng-container>
                      <ng-container >
                        <div class="text-center">
                           <label>
                              <a><img src="https://medeilpos.blob.core.windows.net/websiteimage/prod.webp" alt="product image" loading="lazy"  class="fab" />

                              </a>

                           </label>
                           <p class="mt-2">Agrochemical</p>
                        </div>
                      </ng-container>
                     </ng-container>
                  </div>

                </div>

            </div>
            <div class="col-lg-6 align-self-center">

                <div class="accordions-area">
                  <div class="ac-head">
                      <span>Independent Retail stores</span>


                  </div>

                  <div class="ac-body">
                      <div class="content">
                       <p>Independent retail stores are more than just places to shop—they are integral parts of the social, economic, and cultural fabric of their communities. By supporting independent retailers, consumers contribute to the vitality and diversity of their local economies while enjoying the benefits of personalized service and unique shopping experiences</p>
                      </div>

                  </div>

            </div>

          </div>
          </div>
        </div>
    </section>

</div>

<div class="application-area-two mt-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="application-content">

                    <div class="section-title">
                        <!-- <span>Download app</span> -->
                        <!-- <h2><strong>Medeil</strong> Your Pharmacy Companion</h2>
                        <p>Streamline Your Pharmacy Operations Effortlessly</p> -->
                        <h2>MedeilCloud POS – Your Pharmacy in Your Pocket!</h2>
                        <p>Manage sales, inventory, and billing on the go with our powerful Android POS solution. Simplify operations and boost efficiency anytime, anywhere.</p>
                        <p>Get Started Today – Download the App Now!</p>
                    </div>

                    <div class="application-btn">
                        <a class="application-play-btn"  href="https://play.google.com/store/apps/details?id=com.medeilpos.app" target="_blank" rel="noopener">
                            <img src="https://medeilpos.blob.core.windows.net/websiteimage/google-play.webp" alt="medeil play store image"  loading="lazy">
                            <div class="btn-content">
                                <span>ANDROID APP ON</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a routerLink="/coming-soon" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg" alt="image">
                            <div class="btn-content">
                                <span>DOWNLOAD ON THE</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 text-center" >
                <div class="application-img-two">
                    <!-- <img src="https://medeilpos.blob.core.windows.net/websiteimage/mobile-black.webp" alt="medeil mobile image" height="250px" width="130px" loading="lazy" > -->
                    <img src="assets/img/test/mt.png" alt="medeil mobile image">
                </div>
            </div>
        </div>
    </div>
</div>
<section class="category-box-area mt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="category-box-card">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/award_financeonline.webp" alt="award_financeonline"  loading="lazy" ></a>

                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="category-box-card">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/capterra.webp" alt="capterra" loading="lazy" ></a>

                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="category-box-card">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/financeonline.webp" alt="financeonline"  loading="lazy"></a>

                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="category-box-card ">
                    <a routerLink="/"><img src="https://medeilpos.blob.core.windows.net/websiteimage/software-suggest.webp" alt="software-suggest" loading="lazy" ></a>

                </div>
            </div>


        </div>
    </div>
</section>

<!-- ---------------------------------------- -->


<app-footer-style-two></app-footer-style-two>
 <!-- <div class="privacy-data" *ngIf="isVisible">
   <div class="col-data">
     <p>We use cookies to improve your experience on our site. By using our site you consent to cookies. <a href="https://www.vanuston.com/Terms-and-Conditions/">Learn more</a>
       <button class="cookie-btn" (click)="Acceptall()">Accept All</button>
       <button class="cookie-btn" (click)="declineAll()">Decline All</button>
     </p>
   </div>
 </div> -->
