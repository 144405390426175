import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';

import { ContactComponent } from './components/pages/contact/contact.component';
import { MedeilposComponent } from './components/pages/medeilpos/medeilpos.component';
import { MedeilhqComponent } from './components/pages/medeilhq/medeilhq.component';
import { HosbayComponent } from './components/pages/hosbay/hosbay.component';
import { MedeilstandaloneComponent } from './components/pages/medeilstandalone/medeilstandalone.component';
import { AllotmedComponent } from './components/pages/allotmed/allotmed.component';

import { PosnavbarComponent } from './components/common/posnavbar/posnavbar.component';
import { ProductloginComponent } from './components/pages/productlogin/productlogin.component';



import { AllotmedpricingComponent } from './components/pages/productpricing/allotmedpricing/allotmedpricing.component';
import { MedeilhqpricingComponent } from './components/pages/productpricing/medeilhqpricing/medeilhqpricing.component';
import { MedeilpospricingComponent } from './components/pages/productpricing/medeilpospricing/medeilpospricing.component';
import { MedeilpricingComponent } from './components/pages/productpricing/medeilpricing/medeilpricing.component';

// main vertical

import { GeneralagroComponent } from './components/pages/verticals/generalagro/generalagro.component';
import { GeneralcosmeticsComponent } from './components/pages/verticals/generalcosmetics/generalcosmetics.component';
import { GeneralhealthcareComponent } from './components/pages/verticals/generalhealthcare/generalhealthcare.component';
import { GeneralopticalsComponent } from './components/pages/verticals/generalopticals/generalopticals.component';
import { GeneralpharmacyComponent } from './components/pages/verticals/generalpharmacy/generalpharmacy.component';
import { GeneralveterinaryComponent } from './components/pages/verticals/generalveterinary/generalveterinary.component';
import { SurgicaldeviceComponent } from './components/pages/verticals/surgicaldevice/surgicaldevice.component';
import { MedeicaldeviceComponent } from './components/pages/verticals/medeicaldevice/medeicaldevice.component';

import { MedeildownloadComponent } from './components/pages/medeildownload/medeildownload.component';

const routes: Routes = [
    {path: '',component: HomeThreeComponent},
    {path: 'home-two', component: HomeTwoComponent},
    // {path: 'english/:id', },
    // {path: 'products-details', component: ProductsDetailsComponent},

    {path: 'error', component: HomeThreeComponent},
    {path: 'login-register', component: LoginRegisterComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'coming-soon', component: ComingSoonComponent},

    {path: 'contact', component: HomeThreeComponent},
    {path: 'medeilcloudpos', component: MedeilposComponent},
    {path:'medeilcloudhq',component:MedeilhqComponent},
    {path:'hosbay',component:HosbayComponent},
    {path:'medeil',component:MedeilstandaloneComponent},
    {path:'allotmed',component:AllotmedComponent},

    {path:'posnavbar',component:PosnavbarComponent},
    {path:'freesoftwaredownload',component:ProductloginComponent},
  
    {path:'allotmedprice',component:AllotmedpricingComponent},
    {path:'medeilhqprice',component:MedeilhqpricingComponent},
    {path:'medeilposprice',component:MedeilpospricingComponent},
    {path:'medeilprice',component:MedeilpricingComponent},
    

    // main vertical
    {path:'vertical/agriculturesoftware',component:GeneralagroComponent},
    {path:'vertical/beautysoftware',component:GeneralcosmeticsComponent},
    {path:'vertical/softwareformedicalstore',component:GeneralhealthcareComponent},
    {path:'vertical/opticalshopmanagementsystem',component:GeneralopticalsComponent},
    {path:'vertical/pharmacymanagementsoftware',component:GeneralpharmacyComponent},
    {path:'vertical/veterinarysoftware',component:GeneralveterinaryComponent},
    {path:'vertical/surgicalvertical',component:SurgicaldeviceComponent},
    {path:'vertical/medicaldevicesoftware',component:MedeicaldeviceComponent},

    {path:'download',component:MedeildownloadComponent},
    // Here add new pages component
 
    {path: '**', component: HomeThreeComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes)], //,{ enableTracing: true, useHash:true }
    exports: [RouterModule]
})
export class AppRoutingModule {}