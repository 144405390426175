import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';

import { ContactComponent } from './components/pages/contact/contact.component';
import { MedeilposComponent } from './components/pages/medeilpos/medeilpos.component';
import { MedeilhqComponent } from './components/pages/medeilhq/medeilhq.component';
import { HosbayComponent } from './components/pages/hosbay/hosbay.component';
import { MedeilstandaloneComponent } from './components/pages/medeilstandalone/medeilstandalone.component';
import { AllotmedComponent } from './components/pages/allotmed/allotmed.component';

import { PosnavbarComponent } from './components/common/posnavbar/posnavbar.component';
import { ProductloginComponent } from './components/pages/productlogin/productlogin.component';



import { AllotmedpricingComponent } from './components/pages/productpricing/allotmedpricing/allotmedpricing.component';
import { MedeilhqpricingComponent } from './components/pages/productpricing/medeilhqpricing/medeilhqpricing.component';
import { MedeilpospricingComponent } from './components/pages/productpricing/medeilpospricing/medeilpospricing.component';
import { MedeilpricingComponent } from './components/pages/productpricing/medeilpricing/medeilpricing.component';

// main vertical

import { GeneralagroComponent } from './components/pages/verticals/generalagro/generalagro.component';
import { GeneralcosmeticsComponent } from './components/pages/verticals/generalcosmetics/generalcosmetics.component';
import { GeneralhealthcareComponent } from './components/pages/verticals/generalhealthcare/generalhealthcare.component';
import { GeneralopticalsComponent } from './components/pages/verticals/generalopticals/generalopticals.component';
import { GeneralpharmacyComponent } from './components/pages/verticals/generalpharmacy/generalpharmacy.component';
import { GeneralveterinaryComponent } from './components/pages/verticals/generalveterinary/generalveterinary.component';
import { SurgicaldeviceComponent } from './components/pages/verticals/surgicaldevice/surgicaldevice.component';
import { MedeicaldeviceComponent } from './components/pages/verticals/medeicaldevice/medeicaldevice.component';

import { MedeildownloadComponent } from './components/pages/medeildownload/medeildownload.component';
import { ViewblogComponent } from './components/pages/viewblog/viewblog.component';
import { BlogDetailComponent } from './components/pages/blog-detail/blog-detail.component';
import { AdminloginComponent } from './components/pages/adminlogin/adminlogin.component';
import { MedeilBlogsComponent } from './components/pages/medeil-blogs/medeil-blogs.component';
import { AuthGuard } from './components/auth/authguard ';


import { PosenquiryComponent } from './components/landingpages/posenquiry/posenquiry.component';
import { PosdemorequestComponent } from './components/landingpages/posdemorequest/posdemorequest.component';
import { MedeilhqenquiryComponent } from './components/landingpages/medeilhqenquiry/medeilhqenquiry.component';
import { MedeilhqdemorequestComponent } from './components/landingpages/medeilhqdemorequest/medeilhqdemorequest.component';
import { MedeilenquiryComponent } from './components/landingpages/medeilenquiry/medeilenquiry.component';
import { MedeildemorequestComponent } from './components/landingpages/medeildemorequest/medeildemorequest.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';

const routes: Routes = [
    // {path: '',component: HomeThreeComponent},
    // {path: 'error', component: HomeThreeComponent},
    // {path: 'login-register', component: LoginRegisterComponent},
    // {path: 'privacy-policy', component: PrivacyPolicyComponent},
    // {path: 'coming-soon', component: ComingSoonComponent},

    // {path: 'contact', component: HomeThreeComponent},
    // {path: 'medeilcloudpos', component: MedeilposComponent},
    // {path:'medeilcloudhq',component:MedeilhqComponent},
    // {path:'hosbay',component:HosbayComponent},
    // {path:'medeil',component:MedeilstandaloneComponent},
    // {path:'allotmed',component:AllotmedComponent},

    // {path:'posnavbar',component:PosnavbarComponent},
    // {path:'freesoftwaredownload',component:ProductloginComponent},

    // {path:'allotmedprice',component:AllotmedpricingComponent},
    // {path:'medeilhqprice',component:MedeilhqpricingComponent},
    // {path:'medeilposprice',component:MedeilpospricingComponent},
    // {path:'medeilprice',component:MedeilpricingComponent},
    // // main vertical
    // {path:'vertical/agriculturesoftware',component:GeneralagroComponent},
    // {path:'vertical/beautysoftware',component:GeneralcosmeticsComponent},
    // {path:'vertical/softwareformedicalstore',component:GeneralhealthcareComponent},
    // {path:'vertical/opticalshopmanagementsystem',component:GeneralopticalsComponent},
    // {path:'vertical/pharmacymanagementsoftware',component:GeneralpharmacyComponent},
    // {path:'vertical/veterinarysoftware',component:GeneralveterinaryComponent},
    // {path:'vertical/surgicalvertical',component:SurgicaldeviceComponent},
    // {path:'vertical/medicaldevicesoftware',component:MedeicaldeviceComponent},
    // {path:'download',component:MedeildownloadComponent},
    // {path:'viewblog',component:ViewblogComponent,canActivate: [AuthGuard]},
    // {path:'blog/:title',component:BlogDetailComponent},
    // {path:'wp-admin',component:AdminloginComponent},
    // {path:'medeilblogs',component:MedeilBlogsComponent},
    // {path:'casestudy',component:CaseStudyComponent},
    // // Here add new pages component
    //     // landingpage DemorequestformComponent
    // {path:'medeilcloudposenquiry',component:PosenquiryComponent},
    // {path:'medeilcloudposdemorequest',component:PosdemorequestComponent},
    // {path:'medeilhqenquiry',component:MedeilhqenquiryComponent},
    // {path:'medeilhqdemorequest',component:MedeilhqdemorequestComponent},
    // {path:'medeilenquiry',component:MedeilenquiryComponent},
    // {path:'medeildemorequest',component:MedeildemorequestComponent},
    // {path: '**', component: HomeThreeComponent}

    { path: '', component: HomeThreeComponent },
    { path: 'error', component: HomeThreeComponent }, // Use a separate 404 page
    { path: 'login-register', component: LoginRegisterComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'contact-us', component: ContactComponent }, // SEO-friendly

    // Product Pages (Use Descriptive URLs)
    { path: 'medeil-cloud-pos', component: MedeilposComponent },
    { path: 'medeil-cloud-hq', component: MedeilhqComponent },
    { path: 'hosbay-healthcare', component: HosbayComponent },
    { path: 'medeil-pharmacy-software', component: MedeilstandaloneComponent },
    { path: 'allotmed-medical-supply', component: AllotmedComponent },

    { path: 'point-of-sale', component: PosnavbarComponent },
    { path: 'free-software-download', component: ProductloginComponent },

    // Pricing Pages
    { path: 'allotmed-pricing', component: AllotmedpricingComponent },
    { path: 'medeil-hq-pricing', component: MedeilhqpricingComponent },
    { path: 'medeil-pos-pricing', component: MedeilpospricingComponent },
    { path: 'medeil-pricing', component: MedeilpricingComponent },

    // Vertical Industry Solutions (SEO Optimized)
    { path: 'industry/agriculture-software', component: GeneralagroComponent },
    { path: 'industry/beauty-software', component: GeneralcosmeticsComponent },
    { path: 'industry/medical-store-software', component: GeneralhealthcareComponent },
    { path: 'industry/optical-store-management', component: GeneralopticalsComponent },
    { path: 'industry/pharmacy-management-software', component: GeneralpharmacyComponent },
    { path: 'industry/veterinary-software', component: GeneralveterinaryComponent },
    { path: 'industry/surgical-equipment-software', component: SurgicaldeviceComponent },
    { path: 'industry/medical-device-software', component: MedeicaldeviceComponent },

    // Downloads & Blogs
    { path: 'download-software', component: MedeildownloadComponent },
    { path: 'blogs', component: MedeilBlogsComponent },
    { path: 'case-studies', component: CaseStudyComponent },
    { path: 'view-blogs', component: ViewblogComponent, canActivate: [AuthGuard] },
    { path: 'blog/:title', component: BlogDetailComponent }, // Use `slug` instead of `title`
    { path: 'wp-admin', component: AdminloginComponent },

    // Demo Requests & Enquiries
    { path: 'medeil-cloud-pos-enquiry', component: PosenquiryComponent },
    { path: 'medeil-cloud-pos-demo', component: PosdemorequestComponent },
    { path: 'medeil-hq-enquiry', component: MedeilhqenquiryComponent },
    { path: 'medeil-hq-demo', component: MedeilhqdemorequestComponent },
    { path: 'medeil-enquiry', component: MedeilenquiryComponent },
    { path: 'medeil-demo', component: MedeildemorequestComponent },

    // Catch-All (404 Page)
    { path: '**', component: HomeThreeComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabledBlocking'
    })], //,{ enableTracing: true, useHash:true }
    exports: [RouterModule]
})
export class AppRoutingModule {}