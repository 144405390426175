import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from "@angular/router";
import { AuthServiceService } from "./auth-service.service";

@Injectable({
    providedIn:'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authservice:AuthServiceService,private router:Router){

    }
    canActivate(): boolean {
       if(this.authservice.isLoggedIn()){
        return true;
       }else{
        this.router.navigate(['']);
        return false;
       }
    }
}