<app-navbar-style-two></app-navbar-style-two>

<!-- <app-posnavbar></app-posnavbar> -->
<!-- <div class="banner-area-two client-slider owl-carousel owl-theme">
    <div class="client-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/agriculturesoftware - medeilcloud pos.png" alt="agriculture software - medeilcloud pos" >
        <div class="overlay-buttons row">
            <div class="col-lg-6">
                <button type="button" class="default-btn1">14 Days Free Trial</button>
            </div>
            <div class="col-lg-6">
                <button type="button" class="default-btn1"  (click)="appointment('Agrochemical vertical')">Demo Request</button>
            </div>
        </div>
    </div>
</div> -->
<div class="slider-area owl-carousel owl-theme">
  <div class="slider-item item-bg-agro">
      <div class="d-table">
          <div class="d-table-cell">
              <div class="container">
                  <div class="slider-content">
                      <h1>Grow Your Agriculture Business with Medeil – Smart Agriculture Store Software</h1>
                      <h2>Manage Seeds, Fertilizers, and Equipment with Advanced Inventory and Billing Abilities</h2>
                          <button type="button" class="default-btn" (click)="appointment('Agrochemical Vertical')">Get Started Now</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<ngx-smart-modal #enquirypos identifier="enquirypos" [closable]="false">


  <div class="custom-modal">
     <button class="close-btn" (click)="closeModal()">X</button>
     <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
     </div>
       <div class="modal-body">
         <form [formGroup]="enquiryForm">
           <div class="form-group">
             <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
             <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
            </div>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Country</strong></label>
             <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
             <select class="form-control"  formControlName="countryname1" style="font-size: 12px;" (change)="countrychange()">
              <!-- (change)="countrychange()" -->
              <option select disabled value="0" >---Select Country---</option>
              <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>

            </select>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
             <div class="row">
               <div class="col-lg-3">
                 <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
               </div>
               <div class="col-lg-9">
                 <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
                 autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
               </div>
             </div>

           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Description</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
           </div>
           <!-- <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name">
           </div> -->
           <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
           <div class="input-group">
              <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
               (change)="checkCaptcha()"/>
              <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
              [(ngModel)]="captcha"/>
              <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
            </div>
            <div *ngIf="ecaptcha">
              <p style="color: red">{{ errorcaptcha }}</p>
            </div>
           <div class="form-group">

                 <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                     <input type="checkbox" checked="checked" formControlName="checkbox">
                     <span class="checkmark"></span>
                 </label>
           </div>
           <div class="btn-group mt-3" *ngIf="!showElement">
             <button type="button" class="enquiry-btn" (click)="submitenquery()" >Submit</button>

           </div>


         </form>
       </div>
 </div>
</ngx-smart-modal>
<ngx-smart-modal #appointmentform identifier="appointmentform" [closable]="false">


  <div class="custom-modal"  >
     <button class="close-btn" (click)="closeModal()">X</button>
      <div class="modal-header-appointment">
          <h4 class="modal-title" id="exampleModalLabel">Demo Request</h4>
      </div>
        <div class="modal-body">

           <div class="row">
              <div class="col-lg-12">
                 <mat-form-field >

                    <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="slectdate($event)" >
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                 </mat-form-field>

              </div>
              <div  align="center" *ngIf="showElement">
                 <img height="200px" width="200px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
               </div>
              <div class="col-lg-12" *ngIf="appointment_time">

                  <div class="button-container">
                    <button class="flex-button" *ngFor="let time of timeSlots" (click)="selectTime(time)" [ngClass]="{ 'clicked': selectedTime === time }">{{ time }}</button>
                 </div>
                 <div  align="center" *ngIf="showElementsave">
                  <img height="100px" width="100px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
                </div>
                  <div class="shedule-submit">
                    <button class="shedulebtn" (click)="savelead()">Submit</button>
                  </div>
              </div>
           </div>

        </div>
  </div>
</ngx-smart-modal>
<div class="newslider" >

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/anu-pharmacy.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/apollo-hospitals.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/bellamed-healthcare.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/bk-heart-hospital.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/booncholai-super-market.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/emedsure.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/express-pharmacy.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/falcon-healthcare.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/global-bridge-pharmaceutical.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/hawi-family-hostpital.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/henry-jackson-foundation.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/imcri.webp" alt="image" loading="lazy">
  </div>

</div>
<!-- <div class="section-title container gradient-text mb-2" >

    <h2>Features</h2>
</div> -->
<!-- ---------------------------- -->


<div id="services" class="services section">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
            <div class="gradient-text"><h1>Amazing  Services &amp; Features </h1>for you</div>
            <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
            <p >
                Agrochemical stores of the future should integrate sustainable methods and cutting-edge technologies to maximize agricultural output, reduce environmental impact, and cater to the changing needs of agricultural experts and farmers.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="service-item first-service">
            <div class="icon"></div>
            <h6>Precision Agriculture Solutions</h6>
           <p style="line-height: 1.3;">
            Providing farmers with tools to monitor crop health, identify pests and diseases, and maximize inputs like water, fertilizer, and pesticides. These tools include drones, satellite imaging, and soil sensors. These technologies increase resource efficiency and facilitate data-driven decision-making.
           </p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item second-service">
            <div class="icon"></div>
            <h6>Biological and Organic Inputs</h6>
          <p style="line-height: 1.3;">

            Increasing the variety of biological and organic inputs that are offered in the shop, such as soil amendments made from natural sources, biopesticides, and fertilizers. These goods satisfy consumer demand for sustainable farming methods and provide eco-friendly substitutes for conventional agrochemicals.
          </p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item third-service">
            <div class="icon"></div>
            <h6>Smart Farming Machinery and Equipment</h6>
          <p style="line-height: 1.5;">
            Supplying machinery and equipment with automation, GPS, and Internet of Things (IoT) sensors to boost output, cut labor costs, and improve farm management. Precision irrigation systems, robotic weed eaters, and driverless tractors are a few examples.
          </p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item fourth-service">
            <div class="icon"></div>
            <h6>Digital Farming</h6>
            <p style="line-height: 1.9;">
                Offering digital farm management platforms that combine information from multiple sources, such as crop scouting reports, soil tests, weather forecasts, and equipment telemetry, to give farmers real-time insights and suggestions for improving farm operations.
            </p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ---------------------------- -->

<!-- about  -->
<div id="about" class="about-us section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 align-self-center">
          <div class="section-heading">
            <h4>About who we are?</h4>
            <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
            <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
         <p>
            At Vanuston, our area of expertise is offering vital agrochemical goods and services to help farmers, ranchers, and crop producers maximize crop yields and maximize farm production. With an emphasis on quality, sustainability, and client happiness, we provide a wide range of agrochemical solutions that are specifically designed to satisfy the various demands of our customers.
         </p>
          </div>
          <div class="row">
              <div class="col-lg-6">
                  <div class="box-item" routerLink="/medeil">
                    <h5><a >Standalone Version</a></h5>
                    <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="box-item" routerLink="/medeilcloudpos">
                    <h5><a >SaaS Version</a></h5>
                    <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="box-item" routerLink="/medeilcloudhq">
                    <h5><a >Chain Store Version</a></h5>
                    <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="box-item" routerLink="/allotmed">
                    <h5><a >Distribution Version</a></h5>
                    <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                  </div>
                </div>
            <div class="col-lg-12">
           <p>
            Vanuston is a reliable resource and partner for farmers and agricultural enterprises, not just a supplier of agrochemicals. With a thorough awareness of the difficulties and possibilities facing contemporary agriculture, we are dedicated to assisting our clients in becoming successful by giving them the tools, know-how, and assistance they require to prosper in the cutthroat agribusiness environment of today.
           </p>
              <div>
                <!-- <button class="gradient-button"> Start Free Trial</button> -->
               </div>
              <!-- <span>*No Credit Card Required</span> -->
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="right-image">
            <!-- <img src="assets/images/about-right-dec.png" alt=""> -->
            <img src="https://medeilpos.blob.core.windows.net/websiteimage/medicalposbilling- medeilcloudpos.gif" alt="medical pos billing- medeilcloud pos" loading="lazy">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ---- -->
<!-- ---------------------------- -->
<div class="container mt-4">
    <div class="section-title gradient-text mt-2 mb-2" >

        <h1>Frequently Asked Questions</h1>
    </div>
    <div class="row pt-4">
        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: How does your software support precision agriculture and variable rate application techniques?</h4>
                <p><strong>A:</strong> This question pertains to the software's functionalities for implementing precision agriculture practices, such as variable rate seeding, fertilization, and irrigation. Responses may include precision mapping tools, prescription generation algorithms, and equipment integration capabilities that enable precise input application based on spatial and temporal variability in the field.</p>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: How does the agricultural value chain's stakeholders' cooperation and data exchange become supported by your farm software?</h4>
                <p><strong>A:</strong> This inquiry focuses on how well the software can help farmers, agronomists, suppliers, distributors, and other stakeholders collaborate, communicate, and share data. In order to facilitate smooth communication and cooperation throughout the agricultural ecosystem, possible solutions include cloud-based collaboration platforms, data exchange standards, and API connections.   </p>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: Which are the sub vertical in agriculture software space do you support?</h4>
                <p><strong>A:</strong> We support manufacturer, distribution, retailing for agro finished products, pesticide & insecticide stores, fertilizer shop, dairy industries, etc.</p>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: Is it possible for your program to offer documents related to compliance and traceability for food safety and regulatory requirements?</h4>
                <p><strong>A:</strong>This inquiry concerns the software's ability to trace agricultural products' origin, manufacturing method, and distribution in order to guarantee food safety and legal compliance. Features that assist farmers in adhering to industry norms and regulatory obligations, such as batch traceability, audit trails, and compliance reporting capabilities, may be included in the response. </p>
            </div>
        </div>
    </div>


</div>
<div class="row mb-3 mt-3" style="width: 100%;">
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Crowd Review</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.3/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Google</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.8/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Capterra</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.4/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Software Suggest</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.7/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>G2 Crowd</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.1/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Trust Radius</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.6/5.0</p>
        </div>
  </div>
</div>
<app-footer-style-two></app-footer-style-two>
