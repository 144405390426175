import { Component, OnInit } from '@angular/core';
import { MainserviceService } from 'src/app/mainservice.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

  constructor( private Mainservice:MainserviceService,private router:Router) { }
  getcode:any;
  language:any;
  ngOnInit(): void {

   

//  this.Mainservice.getIPaddress().subscribe((data:any)=>{
      
  
//       this.getcode=data.country_code;

//       this.Mainservice.getlanguage(this.getcode).subscribe(
//         (data) => {
//           console.log(data, 'received data');
//           this.language=data[0]; 
//         },
//         (error) => {
//           console.error(error, 'error occurred'); 
//         }
//       );
     
//     })


  }
  openvauston(){
    window.open('https://www.vanuston.com/', '_blank');

  }
}
