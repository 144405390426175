import { Component,OnInit,TemplateRef,ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormGroup } from '@angular/forms';
import { FormBuilder,Validators } from '@angular/forms';
import { MainserviceService } from 'src/app/mainservice.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-medeilpospricing',
  templateUrl: './medeilpospricing.component.html',
  styleUrls: ['./medeilpospricing.component.scss'],
  providers:[MainserviceService,DatePipe]
})
export class MedeilpospricingComponent {
  opencomparison:boolean=false;
  showgif:boolean=false;
  comparisonform:FormGroup;

  enquiryForm:FormGroup;
  totalcountry:any=[];
  captcha:any
  errorcaptcha:any
  ecaptcha:boolean=false;
  dialcode:any;
  showElement: boolean = false;
  leads:any;
  constructor(private ngxsmartservice:NgxSmartModalService,private fb:FormBuilder,private mainservice:MainserviceService,private datePipe: DatePipe){


    this.comparisonform=this.fb.group({
      leadname:[''],
      moblieno:[''],
      emailid:['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]]
    })
    this.enquiryForm=this.fb.group({
      leadname: ['',[ Validators.required]],
      organizationname: ['',[Validators.required]],
      leadsourceid: ['',[]],
      leadsource: ['medeil website',[]],
      sourceRef1: ['',[]],
      sourceRef2: ['',[]],
      description: ['',[ Validators.required]],
      primaryphone: ['',[Validators.required]],
      primaryemail: ['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      address1: ['',[]],
      address2: ['',[]],
      country: [sessionStorage.getItem('country'),[],[]], 
      countryname1: ['0',[]],
      state: [sessionStorage.getItem('state'),[]], 
      city: [sessionStorage.getItem('city'),[]], 
      enteredcaptcha: ['',[]],
      generatedcaptcha: ['',[]],
      phonedialcode:['',[]],
      checkbox:[false,[]],
      appfrmtime:['',[]],
      apptotime:['',[]],
      appointmentdate:['',[]],
    })
  }

  ngOnInit(){
    this.mainservice.generatecaptcha();
    this.captcha = localStorage.getItem('captcha')
     // ----country list-- 
     this.mainservice.getCountryList().subscribe(data=>{
       console.log(data);
       this.totalcountry=data;
     })
  }

  trynowbtn(data:any){
    // this.enquiryForm.reset();
    this.enquiryForm.get('sourceRef1').setValue(data);
    this.ngxsmartservice.open('myModal');
  
  }
  countrychange(){

    this.mainservice.getMobileCode(this.enquiryForm.get('countryname1').value).subscribe((data)=>{
      //console.log(data);
      this.dialcode=data.Dailingcode;
      console.log(this.dialcode);
     // this.registerForm.get('phonedialcode').patchValue(this.dialcode[1]);
    })
  }
  checkCaptcha(){
  
    var usercaptcha = this.enquiryForm.get('enteredcaptcha').value
  
    if (this.captcha == usercaptcha) {
      this.ecaptcha = false
  
    } else {
  
      this.errorcaptcha = 'Enter correct Captcha'
      this.ecaptcha = true
    }
  }
  generatecaptcha(){
    this.mainservice.generatecaptcha()
    this.captcha = localStorage.getItem('captcha')
  }
  closepopup(){
    this.ngxsmartservice.close('myModal');
    this.enquiryForm.reset();

    this.enquiryForm.get('countryname1').setValue('0');
    this.generatecaptcha();
  }
  savectable(){
    this.showgif=true;
    this.mainservice.savecomparison(this.comparisonform.value).subscribe(data=>{
      console.log(data,'skjdlakdas');
      if(data){
        this.showgif=false;
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Data saved successfully',
          text: 'We will contact you shortly.',
          showConfirmButton: false,
          timer: 4000 
        });
        this.comparisonform.reset();
      }
    })
  }
  toggleComparison() {
    this.opencomparison = !this.opencomparison; 
  }
  validation(){
    let name=this.enquiryForm.get('leadname').value
    let organizationname=this.enquiryForm.get('organizationname').value
    let primaryemail=this.enquiryForm.get('primaryemail').value
    let primaryphone=this.enquiryForm.get('primaryphone').value
    let captcha=this.enquiryForm.get('enteredcaptcha').value
    let checkbox=this.enquiryForm.get('checkbox').value
  
    // var usercaptcha = this.registerForm.get('enteredcaptcha').value
    if(!name){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Name',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!organizationname){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Organization Name',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!primaryemail){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Your Email',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!primaryphone){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Your Mobile Number',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!captcha){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Captcha',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(captcha != this.captcha){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Correct Captcha',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(checkbox==false){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Select Accept & Terms condition',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
  
  
    return true
  }
  savesignup(){
   
   
    let valid=this.validation();
  
    if (valid) {
      this.showElement=true;
      let phone = this.enquiryForm.get('primaryphone').value 
  
      let mobileno=this.enquiryForm.get('phonedialcode').value+this.enquiryForm.get('primaryphone').value 
      this.enquiryForm.get('primaryphone').setValue(mobileno)
      
       console.log('form',this.enquiryForm.value);
  
  this.mainservice.savelead(this.enquiryForm.value).subscribe((data: any) => {
    this.leads = data
    // console.log("this.leads",this.leads)
    if (data) {
      // this.enquiryForm.reset()
      // window.location.href= 'https://www.vanuston.com/thank-u-vanuston'
      this.enquiryForm.reset();
      this.showElement=false;
      // this.submitenquery();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Data saved successfully',
        text: 'We will contact you shortly.',
        showConfirmButton: false,
        timer: 4000 
      });
      this.ngxsmartservice.close('myModal');
    } 
     else{
      this.enquiryForm.get('primaryphone').setValue(phone);
      this.showElement=false;
    }
    this.enquiryForm.get('countryname1').setValue('0');
    this.generatecaptcha();
  })
  
    }
    else{
      this.showElement=false;
    }
  
  
  }
}
