<app-navbar-style-two></app-navbar-style-two>

<!-- <app-posnavbar></app-posnavbar> -->
<!-- <div class="banner-area-two client-slider owl-carousel owl-theme">
    <div class="client-item">
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/opticalsoftware -medeilcloudpos.png" alt="optical software -medeilcloud pos">
        <div class="overlay-buttons row">
            <div class="col-lg-6">
                <button type="button" class="default-btn1">14 Days Free Trial</button>
            </div>
            <div class="col-lg-6">
                <button type="button" class="default-btn1" (click)="appointment('Opticals Vertical')">Demo Request</button>
            </div>
        </div>
    </div>
</div> -->
<div class="slider-area owl-carousel owl-theme">
  <div class="slider-item item-bg-optical">
      <div class="d-table">
          <div class="d-table-cell">
              <div class="container">
                  <div class="slider-content">
                      <h1>Revolutionize Your Optical Store with Medeil – Smart Optical Management Software</h1>
                      <h2>Track Inventory, Manage Prescriptions, and Boost Sales with Ease</h2>
                          <button type="button" class="default-btn" (click)="appointment('Agrochemical Vertical')">Get Started Now</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<ngx-smart-modal #enquirypos identifier="enquirypos" [closable]="false">


  <div class="custom-modal">
     <button class="close-btn" (click)="closeModal()">X</button>
     <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
     </div>
       <div class="modal-body">
         <form [formGroup]="enquiryForm">
           <div class="form-group">
             <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
             <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
            </div>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Country</strong></label>
             <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
             <select class="form-control"  formControlName="countryname1" style="font-size: 12px;" (change)="countrychange()">
              <!-- (change)="countrychange()" -->
              <option select disabled value="0" >---Select Country---</option>
              <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>

            </select>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
             <div class="row">
               <div class="col-lg-3">
                 <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
               </div>
               <div class="col-lg-9">
                 <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
                 autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
               </div>
             </div>

           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Description</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
           </div>
           <!-- <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name">
           </div> -->
           <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
           <div class="input-group">
              <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
               (change)="checkCaptcha()"/>
              <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
              [(ngModel)]="captcha"/>
              <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
            </div>
            <div *ngIf="ecaptcha">
              <p style="color: red">{{ errorcaptcha }}</p>
            </div>
           <div class="form-group">

                 <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                     <input type="checkbox" checked="checked" formControlName="checkbox">
                     <span class="checkmark"></span>
                 </label>
           </div>
           <div class="btn-group mt-3" *ngIf="!showElement">
             <button type="button" class="enquiry-btn" (click)="submitenquery()" >Submit</button>

           </div>


         </form>
       </div>
 </div>
</ngx-smart-modal>
<ngx-smart-modal #appointmentform identifier="appointmentform" [closable]="false">


  <div class="custom-modal"  >
     <button class="close-btn" (click)="closeModal()">X</button>
      <div class="modal-header-appointment">
          <h4 class="modal-title" id="exampleModalLabel">Demo Request</h4>
      </div>
        <div class="modal-body">

           <div class="row">
              <div class="col-lg-12">
                 <mat-form-field >

                    <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="slectdate($event)" >
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                 </mat-form-field>

              </div>
              <div  align="center" *ngIf="showElement">
                 <img height="200px" width="200px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
               </div>
              <div class="col-lg-12" *ngIf="appointment_time">

                  <div class="button-container">
                    <button class="flex-button" *ngFor="let time of timeSlots" (click)="selectTime(time)" [ngClass]="{ 'clicked': selectedTime === time }">{{ time }}</button>
                </div>
                <div  align="center" *ngIf="showElementsave">
                  <img height="100px" width="100px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
                </div>
                  <div class="shedule-submit">
                    <button class="shedulebtn" (click)="savelead()">Submit</button>
                  </div>
              </div>
           </div>

        </div>
  </div>
</ngx-smart-modal>
<div class="newslider" >

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/anu-pharmacy.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/apollo-hospitals.webp" alt="image"loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/bellamed-healthcare.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/bk-heart-hospital.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/booncholai-super-market.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/emedsure.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/express-pharmacy.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/falcon-healthcare.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/global-bridge-pharmaceutical.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/hawi-family-hostpital.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/henry-jackson-foundation.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/imcri.webp" alt="image" loading="lazy">
  </div>

</div><!-- <div class="section-title container gradient-text mb-2" >

    <h2>Features</h2>
</div> -->
<!-- ---------------------------- -->


<div id="services" class="services section">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
            <div class="gradient-text"><h1>Amazing  Services &amp; Features </h1>for you</div>
            <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
           <p>Modern features and technology should be incorporated into optical store software in the future to improve customer satisfaction, expedite processes, and spur company expansion.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="service-item first-service">
            <div class="icon"></div>
            <h4>Initiatives for Environmental Sustainability</h4>
            <p>Putting into effect eco-friendly methods, like providing eco-friendly frame materials, recycling programs for used eyeglasses, and cutting down on packaging waste.</p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item second-service">
            <div class="icon"></div>
            <h4>Personalized suggestions</h4>
            <p>Based on consumer preferences, prescription information, and facial measurements, machine learning algorithms are used to generate suggestions for specific eyewear goods.</p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item third-service">
            <div class="icon"></div>
            <h4>Remote Vision Testing</h4>
      <p>
        By integrating telehealth capabilities, remote vision testing is made possible, enabling clients to use a computer or smartphone to perform basic vision exams from the comfort of their homes.
      </p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item fourth-service">
            <div class="icon"></div>
            <h4>Health Records</h4>
          <p>Enabling the smooth exchange of patient data between optometrists, ophthalmologists, and optical merchants by integrating optical store software with electronic health record (EHR) systems.</p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- ---------------------------- -->

<!-- about  -->
<div id="about" class="about-us section">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 align-self-center">
        <div class="section-heading">
          <h4>What we do?</h4>
          <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
          <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
         <p>At Vanuston, we specialize in developing state-of-the-art optical software solutions tailored to meet the unique needs of eyewear retailers, optometrists, and ophthalmologists</p>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="box-item" routerLink="/medeil">
                  <h5><a >Standalone Version</a></h5>
                  <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                </div>
              </div>
              <div class="col-lg-6">
                <div class="box-item" routerLink="/medeilcloudpos">
                  <h5><a >SaaS Version</a></h5>
                  <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                </div>
              </div>
              <div class="col-lg-6">
                <div class="box-item" routerLink="/medeilcloudhq">
                  <h5><a >Chain Store Version</a></h5>
                  <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                </div>
              </div>
              <div class="col-lg-6">
                <div class="box-item" routerLink="/allotmed">
                  <h5><a >Distribution Version</a></h5>
                  <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                </div>
              </div>
          <div class="col-lg-12">
            <p>
                At Vanuston, we specialize in developing state-of-the-art optical software solutions tailored to meet the unique needs of eyewear retailers, optometrists, and ophthalmologists.
            </p>
            <div>
              <!-- <button class="gradient-button"> Start Free Trial</button> -->
             </div>
            <!-- <span>*No Credit Card Required</span> -->
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="right-image">
          <img src="https://medeilpos.blob.core.windows.net/websiteimage/medicalposbilling- medeilcloudpos.gif" alt="medical pos billing- medeilcloud pos" loading="lazy">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ---- -->
<div class="container mt-4">
    <div class="section-title gradient-text mt-2 mb-2" >

        <h1>Frequently Asked Questions</h1>
    </div>
    <div class="row pt-4">
        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: Which functions are available in your optical software? </h4>
                <p><strong>A:</strong> Clients frequently inquire about the features and functionalities of the platform, such as the ability to virtually try on items, manage frame inventories, schedule appointments, integrate with electronic health records (EHR), and access reporting tools.</p>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: Does your optical software work with the systems and hardware we now have? </h4>
                <p><strong>A:</strong> Yes, this inquiry pertains to compatibility issues with current hardware, including electronic health records (EHR), point-of-sale (POS) systems, and other software programs utilized by optical enterprises. Our solution is compatible with current computers, tablets, and smartphones.</p>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q:  Is it possible to modify your optical program to meet our unique requirements? </h4>
                <p><strong>A:</strong> Many optical firms want to know if the software can be customized to meet their specific demands because they have different requirements and workflows. Customizing fields, reports, processes, and integrations may fall under this category.</p>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: How long does it take to get up and running, and what is the process like for implementation? </h4>
                <p><strong>A:</strong> Optical firms frequently want to know about the implementation process, timeline, training options, and continuing support provided because it can be a substantial undertaking to implement new software. The SaaS application is our solution. Your subscription account can be activated in a matter of minutes.</p>
            </div>
        </div>
    </div>


</div>
<div class="row mb-3 mt-3" style="width: 100%;">
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Crowd Review</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.3/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Google</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.8/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Capterra</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.4/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Software Suggest</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.7/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>G2 Crowd</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.1/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Trust Radius</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.6/5.0</p>
        </div>
  </div>
</div>
<app-footer-style-two></app-footer-style-two>
