import { Component } from '@angular/core';
import { FormGroup,FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthServiceService } from '../../auth/auth-service.service';
@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrl: './adminlogin.component.scss'
})
export class AdminloginComponent {
  loginForm:FormGroup

  constructor(private fb:FormBuilder,private snackBar: MatSnackBar,private router:Router,private AuthService:AuthServiceService){
    this.loginForm=this.fb.group({
      username:new FormControl('', [Validators.required]),
      password:new FormControl('', [Validators.required])
    })
  }
  submit(){
    console.log(this.loginForm.value);
// vanuston.info@gmail.com
// Vanust@2023
    if(this.loginForm.valid){
      if(this.loginForm.get('username')?.value=="vanuston.info@gmail.com" && this.loginForm.get('password')?.value=="Vanust@2023"){
        if(this.AuthService.login(this.loginForm.get('username')?.value,this.loginForm.get('password')?.value)){
          this.router.navigate(['/view-blogs'])
        }else{
          this.snackBar.open('Invalid username or password!', 'Close', { duration: 3000 });
        }

      }else{
        this.snackBar.open('Invalid username or password!', 'Close', { duration: 3000 });
      }
    }else{
      this.snackBar.open('Please fill out all fields', 'Close', { duration: 3000 });
    }
  }
  showPassword: boolean = false;

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
