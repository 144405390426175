<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="coming-soon-content">
                    <img src="assets/img/logo/medeil_logo_1.png"  alt="Images" height="50px" width="160px" loading="lazy">
                    <h2>Download Edition</h2>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices.</p> -->
                    <!-- <div id="timer">
                        <div id="days"></div>
                        <div id="hours"></div>
                        <div id="minutes"></div>
                        <div id="seconds"></div>
                    </div> -->
                    <a class="btn" [ngClass]="{ 'clicked': indianEditionClicked }" (click)="toggleIndianEdition()" href="https://www.medeil.com/downloads/MedeilGST1.6_Setup.exe">Indian Edition</a>
                    <a class="btn btn-default" [ngClass]="{ 'clicked': internationalEditionClicked }"(click)="toggleInternationalEdition()"   href="https://www.medeil.com/downloads/Medeil1.2_Setup_14102023.exe">International Edition</a>
                    <!-- <a class="btn btn-default" [ngClass]="{ 'clicked': internationalEditionClicked }" href="https://www.medeil.com/downloads/Medeil1.2_Setup_14102023.exe">International Edition</a> -->
                    <!-- <form class="newsletter-form">
                        <input type="email" class="input-newsletter" placeholder="Enter email address" name="EMAIL" required autocomplete="off">
                        <button type="submit" class="default-btn">Download</button>
                    </form>  -->

                </div>
            </div>
        </div>
    </div>
</div>
