import { Injectable } from '@angular/core';
import { environment } from '../environments/environment.prod';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Pipe } from '@angular/core';
import { HttpClient,HttpRequest,HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MainserviceService {

  constructor(private http:HttpClient){

  }
  baseUrl: string = environment.backend.baseURL;
  baseResUrl: string = environment.backend.baseResUrl;
  paymentUrl:string=environment.backend.paymentUrl;



  getCountryList():Observable<any>{
    return this.http.get(this.paymentUrl+'getcountry/')
  } 

  // --dial code api--
  getMobileCode(data:any): Observable<any>{

    // var headerToken = {'Authorization': `Bearer `+ localStorage.getItem('token')};
    return this.http.get(this.baseResUrl + 'getCountrycode/'+ data) //, {  headers: headerToken, responseType: 'json' }
  }

  //  apiUrl:any = 'http://localhost:8090'; 
  // // getip:any = '/savestudentdata';



  getIpAddress1(): Observable<any>{
    return this.http.get('https://ipapi.co/json')
  }
  getIpAddress() {
    return this.http.get('https://ipinfo.io/?token=9f091731b1cb99').pipe(tap((res:any) =>{}));
}
  getdailcode(ip:string): Observable<any>{
    return this.http.get("https://secure.medeil.io/medeilpospay/payment"+'/countryname2/'+ip)
  }
  captcha!:string
  generatecaptcha(){
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var lenString = 7;
	var randomstring = '';

  for (var i=0; i<lenString; i++) {
		var rnum = Math.floor(Math.random() * characters.length);
		randomstring += characters.substring(rnum, rnum+1);
	}

  this.captcha = randomstring
  console.log("randomstring",randomstring);
 localStorage.setItem('captcha',this.captcha)
  }
  savelead(data:any){
    return this.http.post(this.baseResUrl + 'saveleadlanding',data)
  }
  gettimeSlots(data:any): Observable<any>{
    var headerToken = {'Authorization': `Bearer `+ localStorage.getItem('token')};
    return this.http.post(this.baseResUrl + 'UTCTimezoneConverter',data, {  headers: headerToken, responseType: 'json' });
  }
  savecomparison(data:any){
    return this.http.post(this.baseResUrl + 'saveinterestlead',data)
  }

  private readonly consentKey = 'cookie_consent';

  hasConsented(): boolean {
    return localStorage.getItem(this.consentKey) === 'true';
  }

  setConsent(consent: boolean): void {
    localStorage.setItem(this.consentKey, consent.toString());
  }
  internData(data:any){
    return this.http.post(this.baseResUrl+'saveintendlead',data).pipe(tap((res)=>{

    }))
  }
}
