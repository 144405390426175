

<div style="background-color: rgb(252, 249, 249);display: flex; justify-content: flex-end; align-items: center;">
    <!-- <div class="col-lg-8">

    </div> -->
   
   
    <div class="flexbox">
        <div class="nav-item dropdown">
            <a class="nav-link" href="#" id="languageDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                <!-- <div class="row">
                    <div class="col-lg-4">
                        <i class="fa fa-language" style="font-size: 26px; color: rgb(8, 8, 168);">
                          
                        </i>
                    </div>
                    <div class="col-lg-4">  <span style="font-size: 16px;">{{language}}</span></div>
                    <div class="col-lg-4">  <span style="font-size: 16px;">English</span></div>
                </div> -->
                
            </a>
            <div class="dropdown-menu" aria-labelledby="languageDropdown" style="background-color: white;width: 250px; border-top: 2px solid blue;border-left: none;border-right: none;border-bottom: none;border-radius: 0;">
                <div class="nav-item">
                 
                       
                    <div class="row">
                        <div class="col-lg-6  mt-2">
                            <div class="row">
                              
                                <div class="col-md-4 col-5">
                                    <a href="#" style="height:20px;width: 40px; display: flex;justify-content: center;">
                                        <i class="fa fa-globe" aria-hidden="true"></i>
                                    </a>
                                </div>
                                
                                <!-- <div class="col-md-4 col-5">
                                    <a href="#" >English</a>
                                </div> -->
                              
                            </div>
                           
                        </div>
                        <!-- <div class="col-lg-6 mt-2">
                            <div class="row">
                                <div class="col-md-4 col-5">
                                    <a href="#" style="height:10px;width: 20px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="flag-icons-in" viewBox="0 0 512 512">
                                        <path fill="#f93" d="M0 0h512v170.7H0z"/>
                                        <path fill="#fff" d="M0 170.7h512v170.6H0z"/>
                                        <path fill="#128807" d="M0 341.3h512V512H0z"/>
                                        <g transform="translate(256 256)scale(3.41333)">
                                          <circle r="20" fill="#008"/>
                                          <circle r="17.5" fill="#fff"/>
                                          <circle r="3.5" fill="#008"/>
                                          <g id="in-d">
                                            <g id="in-c">
                                              <g id="in-b">
                                                <g id="in-a" fill="#008">
                                                  <circle r=".9" transform="rotate(7.5 -8.8 133.5)"/>
                                                  <path d="M0 17.5.6 7 0 2l-.6 5z"/>
                                                </g>
                                                <use xlink:href="#in-a" width="100%" height="100%" transform="rotate(15)"/>
                                              </g>
                                              <use xlink:href="#in-b" width="100%" height="100%" transform="rotate(30)"/>
                                            </g>
                                            <use xlink:href="#in-c" width="100%" height="100%" transform="rotate(60)"/>
                                          </g>
                                          <use xlink:href="#in-d" width="100%" height="100%" transform="rotate(120)"/>
                                          <use xlink:href="#in-d" width="100%" height="100%" transform="rotate(-120)"/>
                                        </g>
                                      </svg></a>
                                </div>
                                <div class="col-md-4 col-5">
                                    <a href="#" >India</a>
                                </div>
                              
                            </div>                                    
                           
                        </div>
                        <div class="col-lg-6 mt-4">
                            <div class="row">
                                <div class="col-md-4 col-5">
                                    <a href="#" style="height:10px;width: 20px;margin-left: 10px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="flag-icons-cn" viewBox="0 0 512 512">
                                            <defs>
                                              <path id="cn-a" fill="#ff0" d="M1-.3-.7.8 0-1 .6.8-1-.3z"/>
                                            </defs>
                                            <path fill="#ee1c25" d="M0 0h512v512H0z"/>
                                            <use xlink:href="#cn-a" width="30" height="20" transform="translate(128 128)scale(76.8)"/>
                                            <use xlink:href="#cn-a" width="30" height="20" transform="rotate(-121 142.6 -47)scale(25.5827)"/>
                                            <use xlink:href="#cn-a" width="30" height="20" transform="rotate(-98.1 198 -82)scale(25.6)"/>
                                            <use xlink:href="#cn-a" width="30" height="20" transform="rotate(-74 272.4 -114)scale(25.6137)"/>
                                            <use xlink:href="#cn-a" width="30" height="20" transform="matrix(16 -19.968 19.968 16 256 230.4)"/>
                                          </svg></a>
                                </div>
                                <div class="col-md-4 col-5">
                                    <a href="#" >chinese</a>
                                </div>
                               
                            </div>
                           
                        </div>
                        <div class="col-lg-6 mt-4">
                            <div class="row">
                                <div class="col-md-4 col-5">
                                    <a href="#" style="height:10px;width: 20px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-is" viewBox="0 0 512 512">
                                            <defs>
                                              <clipPath id="is-a">
                                                <path fill-opacity=".7" d="M85.4 0h486v486h-486z"/>
                                              </clipPath>
                                            </defs>
                                            <g fill-rule="evenodd" stroke-width="0" clip-path="url(#is-a)" transform="translate(-90)scale(1.0535)">
                                              <path fill="#003897" d="M0 0h675v486H0z"/>
                                              <path fill="#fff" d="M0 189h189V0h108v189h378v108H297v189H189V297H0z"/>
                                              <path fill="#d72828" d="M0 216h216V0h54v216h405v54H270v216h-54V270H0z"/>
                                            </g>
                                          </svg></a>
                                </div>
                                <div class="col-md-4 col-5">
                                    <a href="#" >Isrel</a>
                                </div>
                               
                            </div>
                            
                        </div>
                        <div class="col-lg-6 mt-4">
                            <div class="row">
                                <div class="col-md-4 col-5">
                                    <a href="#" style="height:10px;width: 20px;margin-left: 10px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-it" viewBox="0 0 512 512">
                                            <g fill-rule="evenodd" stroke-width="1pt">
                                              <path fill="#fff" d="M0 0h512v512H0z"/>
                                              <path fill="#009246" d="M0 0h170.7v512H0z"/>
                                              <path fill="#ce2b37" d="M341.3 0H512v512H341.3z"/>
                                            </g>
                                          </svg></a>
                                </div>
                                <div class="col-md-4 col-5">
                                    <a href="#" >Italy</a>
                                </div>
                               
                            </div>
                           
                        </div>
                        <div class="col-lg-6 mt-4">
                            <div class="row">
                                <div class="col-md-4 col-5">
                                    <a href="#" style="height:10px;width: 20px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="flag-icons-ke" viewBox="0 0 512 512">
                                            <defs>
                                              <path id="ke-a" stroke-miterlimit="10" d="m-28.6 47.5 1.8 1 46.7-81c2.7-.6 4.2-3.2 5.7-5.8 1-1.8 5-8.7 6.7-17.7a58 58 0 0 0-11.9 14.7c-1.5 2.6-3 5.2-2.3 7.9z"/>
                                            </defs>
                                            <path fill="#fff" d="M0 0h512v512H0z"/>
                                            <path fill="#000001" d="M0 0h512v153.6H0z"/>
                                            <path fill="#060" d="M0 358.4h512V512H0z"/>
                                            <g id="ke-b" transform="matrix(3.2 0 0 3.2 255.8 256)">
                                              <use xlink:href="#ke-a" width="100%" height="100%" stroke="#000"/>
                                              <use xlink:href="#ke-a" width="100%" height="100%" fill="#fff"/>
                                            </g>
                                            <use xlink:href="#ke-b" width="100%" height="100%" transform="matrix(-1 0 0 1 511.7 0)"/>
                                            <path fill="#b00" d="M255.8 102.4c-19.2 0-51.2 51.2-60.8 76.8H0v153.6h195c9.7 25.6 41.7 76.8 60.9 76.8 19.2 0 51.2-51.2 60.8-76.8H512V179.2H316.6c-9.6-25.6-41.6-76.8-60.8-76.8"/>
                                            <path id="ke-c" d="M316.6 332.8a220 220 0 0 0 16-76.8 220 220 0 0 0-16-76.8 220 220 0 0 0-16 76.8 220 220 0 0 0 16 76.8"/>
                                            <use xlink:href="#ke-c" width="100%" height="100%" transform="matrix(-1 0 0 1 511.7 0)"/>
                                            <g fill="#fff" transform="matrix(3.2 0 0 3.2 255.8 256)">
                                              <ellipse rx="4" ry="6"/>
                                              <path id="ke-d" d="M1 5.8s4 8 4 21-4 21-4 21z"/>
                                              <use xlink:href="#ke-d" width="100%" height="100%" transform="scale(-1)"/>
                                              <use xlink:href="#ke-d" width="100%" height="100%" transform="scale(-1 1)"/>
                                              <use xlink:href="#ke-d" width="100%" height="100%" transform="scale(1 -1)"/>
                                            </g>
                                          </svg></a>
                                </div>
                                <div class="col-md-4 col-5">
                                    <a href="#" >Kenya</a>
                                </div>
                             
                            </div>
                           
                        </div>
                        <div class="col-lg-6 mt-4">
                            <div class="row">
                                <div class="col-md-4 col-5">
                                    <a href="#" style="height:10px;width: 20px;margin-left: 10px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-ng" viewBox="0 0 512 512">
                                            <g fill-rule="evenodd" stroke-width="1pt">
                                              <path fill="#fff" d="M0 0h512v512H0z"/>
                                              <path fill="#008753" d="M341.3 0H512v512H341.3zM0 0h170.7v512H0z"/>
                                            </g>
                                          </svg></a>
                                </div>
                                <div class="col-md-4 col-5">
                                    <a href="#" >Nigeria</a>
                                </div>
                               
                            </div>
                            
                        </div>
                        <div class="col-lg-6  mt-4">
                            <div class="row">
                                <div class="col-md-4 col-5">
                                    <a href="#" style="height:10px;width: 20px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-ru" viewBox="0 0 512 512">
                                            <g fill-rule="evenodd" stroke-width="1pt">
                                              <path fill="#fff" d="M0 0h512v512H0z"/>
                                              <path fill="#0039a6" d="M0 170.7h512V512H0z"/>
                                              <path fill="#d52b1e" d="M0 341.3h512V512H0z"/>
                                            </g>
                                          </svg></a>
                                </div>
                                <div class="col-md-4 col-5">
                                    <a href="#" >Russia</a>
                                </div>
                              
                            </div>
                            
                        </div> -->
                       
                    </div>
                
                </div>

               
            </div>
        </div>
    </div>
    <div class="flexbox">
        <div class="nav-item">
            <a class="nav-link" href="https://www.vanuston.com/">About Us</a>
        </div>
        
    </div>
    <div class="flexbox">
        <div class="nav-item">
            <a class="nav-link" href="https://www.vanuston.com/">Contact Us</a>
        </div>
    </div>
    <div class="flexbox" (click)="openvauston()">
        <a>
            <img src="assets/img/logo/vanuston-image.png" alt="Logo" height="22px" width="90px">
        </a>
    </div>
</div>

