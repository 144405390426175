


<div class="navbar-area">
    <nav class="navbar navbar-expand-lg navbar-light ">
      <div class="container-fluid">

        <!-- <a routerLink="" class="navbar-brand"><img src="https://medeilpos.blob.core.windows.net/posimage/medeil_logo_1.png" loading="lazy" height="50px" width="140px" alt="Medeil pharmacy logo"></a> -->
        <a routerLink="" class="navbar-brand"><img src="assets/img/test/Medei-logo1.png" loading="lazy" height="50px" width="140px" alt="Medeil pharmacy logo"></a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>


        <div class="collapse navbar-collapse" id="navbarNavDropdown">


          <ul class="navbar-nav">
            <li class="nav-item">
                <a routerLink="" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
            </li>
            <li class="nav-item dropdown">
                <a href="javascript:void(0)" class="nav-link">Products</a>

                <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style="min-width: 230px;">

                    <li class="nav-item"><a routerLink="/medeil-cloud-pos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">MedeilCloud POS</a></li>
                    <li class="nav-item"><a routerLink="/medeil-cloud-hq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">MedeilCloud HQ</a></li>
                    <li class="nav-item"><a routerLink="/medeil-pharmacy-software" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Medeil</a></li>
                    <li class="nav-item"><a routerLink="/hosbay-healthcare" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hosbay</a></li>
                    <li class="nav-item"><a routerLink="/allotmed-medical-supply" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Allotmed</a></li>
                </ul>
              </li>
            <li class="nav-item dropdown">
                <a href="javascript:void(0)" class="nav-link">Vertical</a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style="min-width: 230px;">
                  <li><a routerLink="/industry/pharmacy-management-software" class="nav-link" routerLinkActive="active">Pharmacy</a></li>
                  <li><a routerLink="/industry/optical-store-management" class="nav-link" routerLinkActive="active">Optical</a></li>
                  <li><a routerLink="/industry/beauty-software" class="nav-link" routerLinkActive="active">Beauty & Cosmetics</a></li>
                  <li><a routerLink="/industry/veterinary-software" class="nav-link" routerLinkActive="active">Veterinary</a></li>
                  <li><a routerLink="/industry/medical-store-software" class="nav-link" routerLinkActive="active">Healthcare Store</a></li>
                  <li><a routerLink="/industry/medical-device-software" class="nav-link" routerLinkActive="active">Medical Device</a></li>
                  <li><a routerLink="/industry/agriculture-software" class="nav-link" routerLinkActive="active">Agrochemical</a></li>

                </ul>
            </li>
            <li class="nav-item dropdown">
                <a href="javascript:void(0)" class="nav-link">Resource</a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style="min-width: 230px;">
                  <li><a routerLink="/blogs" class="nav-link" routerLinkActive="active">Blog</a></li>
                  <li><a routerLink="/case-study" class="nav-link" routerLinkActive="active">Case Study</a></li>
                  <li><a class="nav-link">Video</a></li>
                </ul>
            </li>

              <li class="nav-item">
                <a routerLink="/free-software-download" class="default-btn" routerLinkActive="active">Free Trial</a>
              </li>


          </ul>
        </div>
      </div>
    </nav>
  </div>