

<app-posnavbar></app-posnavbar>
<div class="inner-banner inner-bghosbay">
    <div class="overlay-buttons"> 
        <button type="button" class="default-btn-trial">Start Free Trial</button>
    </div>
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-6 textparadesign">
                <span>A One-Stop Solution For Entire Pharmacy Business Verticals</span>
                <p>You can now automate tedious tasks while improving your business. Your day-to-day pharmacy management with patients, inventory, vendors, and pharmaceutical products have never been as easy.</p>
                <div class="row mt-2">
                    <div class="col-lg-6">
                        <button class="trial-btn">Start 14-Day Free Trial</button>
                    </div>
                    <div class="col-lg-6">
                        <button class="trial-btn">Explore Demo</button>
                    </div>
                </div>
             
            </div>
           
        </div>
        
    </div> -->
</div>
<div class="newslider" >

    <div class="place-list-item">
        <img src="assets/img/home-three/anu-pharmacy.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/apollo-hospitals.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/bellamed-healthcare.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/bk-heart-hospital.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/booncholai-super-market.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/emedsure.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/express-pharmacy.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/falcon-healthcare.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/global-bridge-pharmaceutical.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/hawi-family-hostpital.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/henry-jackson-foundation.webp" alt="image">
    </div>

    <div class="place-list-item">
        <img src="assets/img/home-three/imcri.webp" alt="image">
    </div>

</div>


    
   

<div class="about-area pt-30 pb-30">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title gradient-text">
                       
                        <h2>A Awesome History Behind Our Success</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>

                    <div class="about-list">
                        <ul>
                            <li><i class="flaticon-curve-arrow"></i> Mea omnium explicari sit vidit harum</li>
                            <li><i class="flaticon-curve-arrow"></i> His no legere feugaitoer in laudem</li>
                            <li><i class="flaticon-curve-arrow"></i> Temporeum dicant partem scripserit, doctus</li>
                        </ul>
                    </div>
                    <a routerLink="/team" class="default-btn border-radius">Try Now</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/products/demo_img.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<section class="category-area pt-50 pb-50">
    <div class="container">
        <div class="section-title new-section ">
          
            <h2>Choice Your Vertical</h2>
        </div>
        
        <div class="row pt-45 newfeature">
            <div class="col-lg-2 col-sm-6 " (click)="selectclass(1)">
                <div class="category-box" [ngClass]="{ 'selected': selectedclass === 1 }">
                    <a><i class="flaticon-bake"></i></a>
                    <a><h3>Pharmacy</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6" (click)="selectclass(2)">
                <div class="category-box" [ngClass]="{ 'selected': selectedclass === 2 }">
                    <a><i class="flaticon-hotel"></i></a>
                    <a><h3>Veterinary</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6" (click)="selectclass(3)">
                <div class="category-box" [ngClass]="{ 'selected': selectedclass === 3 }">
                    <a><i class="flaticon-barbell"></i></a>
                    <a><h3>Fitness Store</h3></a>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6" (click)="selectclass(4)">
                <div class="category-box" [ngClass]="{ 'selected': selectedclass === 4 }">
                    <a><i class="flaticon-store"></i></a>
                    <a><h3>Agro Chemical</h3></a>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6" (click)="selectclass(5)">
                <div class="category-box" [ngClass]="{ 'selected': selectedclass === 5 }">
                    <a><i class="flaticon-hotel"></i></a>
                    <a><h3>Super Market</h3></a>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6" (click)="selectclass(6)">
                <div class="category-box" [ngClass]="{ 'selected': selectedclass === 6 }">
                    <a><i class="flaticon-event"></i></a>
                    <a><h3>Optical</h3></a>
                </div>
            </div>

         
        </div>
    </div>
</section>

<div class="pr-feature container mt-3">
    
    <section>
        <div class="section-title gradient-text mt-3 mb-3">
            <h2>Our Features</h2>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2>Find Your Best Features Right Now!</h2>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <button class="feature-btn btn">Inventory</button>
                        </div>
                        <div class="col-md-4 mb-3">
                            <button class="feature-btn btn ">Finance</button>
                        </div>
                        <div class="col-md-4 mb-3">
                            <button class="feature-btn btn ">Purchase</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6">
               
                <div class="f-card">
                    <ul>
                        <li>Inventory Management</li>
                        <hr>
                        <li>Billing and Insurance</li>
                        <hr>
                        <li>Reporting and Analytics</li>
                        <hr>
                        <li>Customer Support</li>
                        <hr>
                        <li>Integration with EHR</li>
                        <hr>
                        <li>User Authentication</li>
                        <hr>
                        <li>Customizable Workflows</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <!-- <img src="../../../../assets/img/testimonial/pexels4.webp" height="423px"  class="img-fluid" alt="Image" width="100%"> -->
                <img src="assets/img/testimonial/pexels4.webp" height="423px" width="100%">
            </div>
            <div class="col-lg-4 col-md-6">
                <h5>Extra info about vertical</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempor pack incididunt ut labore et dolore magna aliqua quised ipsum suspendisse.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempor pack incididunt ut labore et dolore magna aliqua quised ipsum suspendisse.</p> 

                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempor pack incididunt ut labore et dolore magna aliqua quised ipsum suspendisse.</p>

                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
        </div>
      </section>
</div>


<div class="container">
    <div class="section-title gradient-text mt-4 mb-4" >
       
        <h2>Frequently Asked Questions</h2>
    </div>
    <div class="row pt-45">
        <div class="col-lg-6">
            <div class="faq-item">
                <h3>Q: How Can I Create a Free Listing?</h3>
                <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="faq-item">
                <h3>Q: How Much Time Take It Get a Approval?</h3>
                <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="faq-item">
                <h3>Q: Is It My Listing?</h3>
                <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="faq-item">
                <h3>Q: Is My Listing Secure?</h3>
                <p><strong>A:</strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat, metus ut tincidunt vestibulum,nunc tellus fringilla lorem, ut auctor metus felis vel est. Mauris tristique leo nulla, id blandit sem malesuada ac. Orci varius natoque penatibus et magnis dis parturient montes.</p>
            </div>
        </div>
    </div>
 
</div>
<div class="application-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="application-content">
                    <div class="mapp-title">
                       
                        <h2>Get More In Our Application Sit Back And Enjoy</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    </div>

                    <div class="application-btn">
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/google-play.svg" alt="svg">
                            <div class="btn-content">
                                <span>ANDROID APP ON</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DOWNLOAD ON THE</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="application-img-two" >
                    <img src="assets/img/products/mobile-black.png" alt="Images" height="250px" width="130px">
                </div>
            </div>
        </div>
    </div>
</div>

    <div class="container">
        <div class="section-title gradient-text mt-4 mb-4" >
          
            <h2>Testimonials</h2>
        </div> 
        <div class="text-center">
           
            <h4>What Our <b>Clients Say</b></h4>
        </div>
        
        <div class="row pt-25">
            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h3>Sanaik Tubi</h3>
                    <span>Arbon Restaurant</span>
                    <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <div class="testimonial-top"><img src="assets/img/testimonial/client1.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h3>Oli Rubion</h3>
                    <span>Rubion Inc</span>
                    <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <div class="testimonial-top"><img src="assets/img/testimonial/client3.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h3>Mashrof Ruin</h3>
                    <span>Pice Cafe</span>
                    <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <div class="testimonial-top"><img src="assets/img/testimonial/client2.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h3>Steven Smith</h3>
                    <span>Arbon Restaurant</span>
                    <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <div class="testimonial-top"><img src="assets/img/testimonial/client1.png" alt="Images"></div>
                </div>
            </div>
        </div>
    </div>

<div class="row mb-3 mt-3" style="width: 100%;">
    <div class="col-lg-2">
        <div class="rating-section">
            <h6>Crowd Review</h6>
            <div class="rating-stars">
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9734;</div>
            </div>
            <p>4.0/5.0</p>
          </div>
    </div>
    <div class="col-lg-2">
        <div class="rating-section">
            <h6>Google</h6>
            <div class="rating-stars">
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9734;</div>
            </div>
            <p>4.0/5.0</p>
          </div>
    </div>
    <div class="col-lg-2">
        <div class="rating-section">
            <h6>Capterra</h6>
            <div class="rating-stars">
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9734;</div>
              <div class="star">&#9734;</div>
            </div>
            <p>3.0/5.0</p>
          </div>
    </div>
    <div class="col-lg-2">
        <div class="rating-section">
            <h6>Software Suggest</h6>
            <div class="rating-stars">
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9734;</div>
            </div>
            <p>4.0/5.0</p>
          </div>
    </div>
    <div class="col-lg-2">
        <div class="rating-section">
            <h6>G2 Crowd</h6>
            <div class="rating-stars">
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9734;</div>
              <div class="star">&#9734;</div>
            </div>
            <p>3.0/5.0</p>
          </div>
    </div>
    <div class="col-lg-2">
        <div class="rating-section">
            <h6>Trust Radius</h6>
            <div class="rating-stars">
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9733;</div>
              <div class="star">&#9734;</div>
            </div>
            <p>4.0/5.0</p>
          </div>
    </div>
</div>
   
    
<app-footer-style-two></app-footer-style-two>
      

<!-- <footer class="footer-area footer-bg2">
    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <a routerLink="/" class="logo"><img src="assets/img/logo/vanuston-logo-white-transparent-footer.png" alt="Logo"></a>
                        <p>Pilar Financial Services, Inc. 200 Wood Avenue South</p>
                        <ul class="footer-contact-list">
                            <li>Wonder Street, USA, New York</li>
                            <li><span>Phone :</span> <a href="tel:2151234567"> 215 - 123 - 4567</a></li> 
                            <li><span>Email :</span> <a href="mailto:info@pilar.com"> info@pilar.com</a></li> 
                        </ul>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget pl-1">
                        <h3>SUPPORT</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">My Account</a></li>
                            <li><a routerLink="/checkout">Checkout</a></li>
                            <li><a routerLink="/cart">Cart</a></li>
                            <li><a routerLink="/faq">FAQ's</a></li>
                            <li><a routerLink="/login-register">Register</a></li>
                            <li><a routerLink="/">Help & Support</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget pl-5">
                        <h3>QUICK LINKS</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/about">About Us</a></li>
                            <li><a routerLink="/listing">Listing</a></li>
                            <li><a routerLink="/">How It Works</a></li>
                            <li><a routerLink="/faq">FAQ's</a></li>
                            <li><a routerLink="/contact">Contact Us</a></li>
                            <li><a routerLink="/login-register">Register</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget">
                        <h3>NEWSLETTER</h3>
                        <p>To get the latest news and latest updates from us</p>
                        <div class="footer-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Your Email*">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">SUBSCRIBE now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copy-right-area copy-right-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="copy-right-list">
                    <ul>
                        <li><a routerLink="/terms-condition" target="_blank">Terms of Use</a></li> 
                        <li><a routerLink="/privacy-policy" target="_blank">Privacy Policy</a></li> 
                        <li><a routerLink="/blog" target="_blank">Blog</a></li> 
                    </ul>
                </div>
            </div>

            <div class="col-lg-8 col-md-8">
                <div class="copy-right-text text-end">
                    <p>© Vanuston is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                </div>
            </div>
        </div>
    </div>
</div> -->


