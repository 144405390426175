<!-- <app-preloader ngSkipHydration></app-preloader>
<router-outlet></router-outlet> -->
<!-- <app-preloader *ngIf="isLoading" ngSkipHydration></app-preloader> -->
<router-outlet></router-outlet>
<button class="chat-icon" (click)="openChat()">
  <i class="fas fa-comment-dots"></i>
</button>
<div *ngIf="isVisible" class="cookie-consent">
  <div class="cookie-consent-popup">
    <p>We use cookies to improve your experience on our site. By using our site you consent to cookies. <a href="https://www.vanuston.com/Terms-and-Conditions/" target="_blank" rel="noopener">Learn more</a>
      <button class="cookie-btn" (click)="accept()">Accept All</button>
      <button class="cookie-btn" (click)="decline()">Decline</button>
  </div>

  </div>
