import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainserviceService } from 'src/app/mainservice.service';

@Component({
  selector: 'app-medeilhqdemorequest',
  templateUrl: './medeilhqdemorequest.component.html',
  styleUrl: './medeilhqdemorequest.component.scss'
})
export class MedeilhqdemorequestComponent {
    enquiryForm:FormGroup;
     constructor(private fb:FormBuilder,private mainservice:MainserviceService){
           this.enquiryForm=this.fb.group({
                leadname: ['',[ Validators.required]],
                organizationname: ['',[Validators.required]],
                leadsourceid: ['',[]],
                leadsource: ['medeil website',[]],
                sourceRef1: ['',[]],
                sourceRef2: ['',[]],
                description: ['',[ Validators.required]],
                primaryphone: ['',[Validators.required]],
                primaryemail: ['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
                address1: ['',[]],
                address2: ['',[]],
                country: [sessionStorage.getItem('country'),[],[]],
                countryname1: ['0',[]],
                state: [sessionStorage.getItem('state'),[]],
                city: [sessionStorage.getItem('city'),[]],
                enteredcaptcha: ['',[]],
                generatedcaptcha: ['',[]],
                phonedialcode:['',[]],
                checkbox:[false,[]],
                appfrmtime:['',[]],
                apptotime:['',[]],
                appointmentdate:['',[]],
              })
        }
}
