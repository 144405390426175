<app-navbar-style-two></app-navbar-style-two>
<!-- <div class="container">
  <div class="blog-header">
    <h2>Blog List</h2>
    <button mat-raised-button color="primary" (click)="openAddBlogDialog()">Add Blog</button>
  </div>
  <div class="blog-list">
    <mat-card *ngFor="let blog of blog$" class="blog-card">
      <mat-card-header>
        <mat-card-title>{{ blog.title }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p [innerHTML]="blog.content"></p>
      </mat-card-content>
    </mat-card>
  </div>
</div> -->

<!-- <div class="container">
  <div class="blog-header">
    <h2>Blog List</h2>
    <button mat-raised-button color="primary" (click)="openAddBlogDialog()">Add Blog</button>
  </div>
  <div class="blog-list">
    <mat-card *ngFor="let blog of blog$" class="blog-card" (click)="openBlogDetail(blog)">
      <mat-card-header>
        <mat-card-title>{{ blog.title }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="image-container">
          <img [src]="getImageUrl(blog)" alt="{{ blog.title }}" />
        </div>
        <p [innerHTML]="blog.content | slice:0:100"></p>
      </mat-card-content>
    </mat-card>
  </div>
</div> -->
<div class="container">
  <div class="blog-area">
    <div class="container">
      <div class="blog-header text-center">
        <h2>Latest Blog Posts</h2>
        <button mat-raised-button color="primary" (click)="openAddBlogDialog()">Add Blog</button>
      </div>

    </div>
  </div>
  <div class="row pt-45">
    <div class="col-lg-4 col-md-4 col-sm-6 col-12" *ngFor="let blog of blog$">
      <div class="blog-card">
        <div class="card-header">
          <a (click)="openBlogDetail(blog)">
            <img [src]="getImageUrl(blog)" alt="{{ blog.title }}" />
          </a>
        </div>
        <div class="card-body">
          <h3 class="card-title">
            <a (click)="openBlogDetail(blog)">{{ blog.title }}</a>
          </h3>
          <p class="card-text">
            {{ blog.description | slice: 0:100 }}...
          </p>
          <a (click)="openBlogDetail(blog)" class="read-more">Read More</a>
        </div>
        <div class="card-footer">
          <button class="icon-button" (click)="editBlog(blog)">
            <i class="fas fa-edit"></i> Edit
          </button>
          <button class="icon-button" (click)="deleteBlog(blog)">
            <i class="fas fa-trash"></i> Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<app-footer-style-two></app-footer-style-two>
<!-- <mat-card-subtitle>{{ blog | date: 'medium' }}</mat-card-subtitle>

<img mat-card-image [src]="blog" alt="Blog Image" *ngIf="blog.imageUrl" /> -->