import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor() { }

  private isAuthenticated=false;
  login(username:string,password:string ){
    if(username && password){
      this.isAuthenticated=true;
      return true;
    }
    this.isAuthenticated=false;
    return false;
  }
  isLoggedIn():boolean{
    return this.isAuthenticated;
  }
}
