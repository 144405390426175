import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TawkService {

  private isLoaded = false;

  constructor() {}

  // loadTawk(): void {
  //   if (window['Tawk_API']) {
  //     console.log("Tawk.to is already loaded.");
  //     return;
  //   }

  //   let script = document.createElement("script");
  //   script.async = true;
  //   script.src = `https://embed.tawk.to/565c0f030f76a5ca39f80867/default`; // Use the correct embed URL
  //   script.charset = "UTF-8";
  //   script.setAttribute("crossorigin", "anonymous"); // Allow cross-origin requests

  //   script.onload = () => {
  //     console.log("Tawk.to chat script loaded.");
  //   };

  //   document.body.appendChild(script);
  // }
  loadTawk(): void {
    if (this.isLoaded) {
      console.log("Tawk.to is already loaded.");
      return;
    }

    let script = document.createElement("script");
    script.async = true;
    script.src = `https://embed.tawk.to/565c0f030f76a5ca39f80867/default`; // Replace with your Tawk.to ID
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    script.onload = () => {
      console.log("Tawk.to chat script loaded.");
      this.isLoaded = true;

      window['Tawk_API'] = window['Tawk_API'] || {};
      window['Tawk_API'].onLoad = function () {
        window['Tawk_API'].hideWidget(); // Hide the chat widget after loading
      };
    };

    document.body.appendChild(script);
  }

  openChat(): void {
    if (window['Tawk_API']) {
      window['Tawk_API'].maximize(); // Open chat on click
    }
  }
}
