import { Component, OnInit,Renderer2 } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MainserviceService } from './mainservice.service';
import { TawkService } from './services/tawk.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        MainserviceService
    ]
})
export class AppComponent implements OnInit {
    title = 'Medeil-web';
    location: any;
    routerSubscription: any;
    isVisible = false;
    interndata:any;
    isLoading = true;
    showPreloader = true;
    constructor(private router: Router,private mainservice:MainserviceService,private renderer: Renderer2,private tawkService: TawkService) {
      this.tawkService.loadTawk();
        setTimeout(() => {
            this.isLoading = false;
          }, 100);
    }

    ngOnInit(){

      this.addJsonLd();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.showPreloader = false;
            }
          });
        this.isVisible = !this.mainservice.hasConsented();
//    localStorage.getItem('cid');

        this.mainservice.getIpAddress1().subscribe(data=>{
            console.log(data);
            this.interndata=data;
            sessionStorage.setItem('dialcode',data.country_calling_code)
            sessionStorage.setItem('countryname',data.country_name);
          //   sessionStorage.setItem('statename',data.countrydetails.regionName);
            sessionStorage.setItem('cityname',data.city)
            sessionStorage.setItem('timezone',data.timezone);
            sessionStorage.setItem('ip',data.ip);
            this.mainservice.getdailcode(data.ip).subscribe(data=>{

                  console.log(data);
                //   sessionStorage.setItem('dialcode',data.Dialcode.Dailingcode)
                  sessionStorage.setItem('language',data.language[0][2]);
                //   sessionStorage.setItem('statename',data.countrydetails.regionName);
                //   sessionStorage.setItem('cityname',data.countrydetails.city)
                })
          })

        this.recallJsFuntions();

    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('./assets/js/custom.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    accept(): void {
        this.mainservice.internData(this.interndata).subscribe(data=>{
            console.log(data);

        })
        this.mainservice.setConsent(true);
        this.isVisible = false;
      }

      decline(): void {
        this.mainservice.setConsent(false);
        this.isVisible = false;
      }

      addJsonLd() {
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = `
        {
           "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Medeil Pharmacy SaaS",
            "description": "Pharmacy Software,Medeil Pharmacy SaaS is an advanced pharmacy software solution for Retail SaaS, Pharmacy SaaS, Optical Stores, and Retail Distribution. It streamlines pharmacy management with cloud-based features and automation.",
            "applicationCategory": "BusinessApplication",
            "operatingSystem": "Web-based",
            "keywords": "Retail SaaS, Pharmacy SaaS, Pharmacy Software, Pharmacy Management System, Pharmacy App, Optical Store Management"
        }`;

        this.renderer.appendChild(document.head, script);
      }

      openChat() {
        this.tawkService.openChat(); // Open chat when clicking the icon
      }
}