import { Component, OnInit,TemplateRef,ViewChild, AfterViewInit   } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormGroup } from '@angular/forms';
import { FormBuilder,Validators } from '@angular/forms';
import { MainserviceService } from 'src/app/mainservice.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.scss'],
  providers:[MainserviceService,DatePipe]


})
export class HomeThreeComponent implements OnInit {
  // queryParams:any ='2';
  constructor(private router:Router,private ngxsmartservice:NgxSmartModalService,private fb:FormBuilder,
    private mainservice:MainserviceService,private datePipe: DatePipe,private title:Title,private meta:Meta,private route: ActivatedRoute) { 

      this.title.setTitle('Revolutionize Pharmacy, Optical & Retail with SaaS Solutions');

      // Add meta tags dynamically
      this.meta.addTag({ name: 'description', content: 'Embrace the future of pharmacy management, optical store, and selective retail business management with our cutting-edge SaaS platform. Learn more!' });
      this.meta.addTag({name:'keywords', content:'Pharmacy software, Medeil, Retail SaaS, Pharmacy SaaS, Pharmacy app, Pharmacy management system,SaaS Application for Pharmacy management, Optical stores & Selective Retail & Distribution software’s'});
      
      // this.route.queryParams.subscribe(params => {
      //   this.queryParams = params;
      // });
      // this.meta.addTag({ name: 'keywords', content: 'keyword1, keyword2' });
    }
  @ViewChild('otherContentRef') otherContentRef: TemplateRef<any>;
  selectedDiv: number = 1;
  pos:boolean=false;
  medeil:boolean;
  hosbay:boolean=false;
  hq:boolean=false;
  allotmed:boolean=false;
  selectedTime: string | null = null;
  appointment_time:boolean=false;
 
  @ViewChild('picker') picker!: MatDatepicker<Date>;
  @ViewChild('inputField') inputField!: any;
  enquiryForm:FormGroup;
  totalcountry:any=[];
  showElement: boolean = false;
  showElementsave: boolean = false;
  timezone:any;
  cookie:any;
  // isVisible:boolean=false;

  ngOnInit(): void {
    // this.navigateToHomeWithQueryParams();
    this.timezone=sessionStorage.getItem('timezone');
    
    this.enquiryForm=this.fb.group({
      leadname: ['',[ Validators.required]],
      organizationname: ['',[Validators.required]],
      leadsourceid: ['',[]],
      leadsource: ['medeil website',[]],
      sourceRef1: ['',[]],
      sourceRef2: ['',[]],
      description: ['',[ Validators.required]],
      primaryphone: ['',[Validators.required]],
      primaryemail: ['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      address1: ['',[]],
      address2: ['',[]],
      country: [sessionStorage.getItem('country'),[],[]], 
      countryname1: ['0',[]],
      state: [sessionStorage.getItem('state'),[]], 
      city: [sessionStorage.getItem('city'),[]], 
      enteredcaptcha: ['',[]],
      generatedcaptcha: ['',[]],
      phonedialcode:['',[]],
      checkbox:[false,[]],
      appfrmtime:['',[]],
      apptotime:['',[]],
      appointmentdate:['',[]],
    })
   this.selectDiv(this.selectedDiv);
   this.mainservice.generatecaptcha();
   this.captcha = localStorage.getItem('captcha')
    // ----country list-- 
    this.mainservice.getCountryList().subscribe(data=>{
      console.log(data);
      this.totalcountry=data;
    })
   
   
  }
  navigateToHomeWithQueryParams() {
    // Specify the route and query parameters
    const queryParams = { lang: sessionStorage.getItem('language'), country: sessionStorage.getItem('countryname') };

    // Navigate to the home route with the specified query parameters
    this.router.navigate(['/'], { queryParams });
  }
  selectTime(time: string): void {
    const currentTime = new Date();
    const formattedTime = this.datePipe.transform(currentTime, 'hh:mm:ss a');
    console.log(formattedTime);
    this.selectedTime = time;
    // this.enquiryForm.get('apptotime').setValue(time);
    this.enquiryForm.get('appfrmtime').setValue(time);
    
  }
  
 
  selectDiv(index: number): void {
  
    this.selectedDiv = index;

    if(index==1){
      // alert('hi');
      // this.ngOnInit();
      this.pos=false;
      this.hq=false;
      this.allotmed=false;
      this.hosbay=false;
      this.medeil=true;
     
    }
    if(index==2){
      this.pos=true;
      this.medeil=false;
      this.hq=false;
      this.allotmed=false;
      this.hosbay=false;
    }
    if(index==3){
      this.pos=false;
      this.medeil=false;
      this.hq=true;
      this.allotmed=false;
      this.hosbay=false;
    }
    if(index==4){
      this.pos=false;
      this.medeil=false;
      this.hq=false;
      this.allotmed=false;
      this.hosbay=true;
    }
    if(index==5){
      this.pos=false;
      this.medeil=false;
      this.hq=false;
      this.allotmed=true;
      this.hosbay=false;
    }
  }

 
    enquirynow(data:any){
      this.enquiryForm.get('sourceRef1').setValue(data);
      this.ngxsmartservice.open('myModal');
    }
    appointment(datasource:any){
      this.enquiryForm.get('sourceRef1').setValue(datasource);
      this.ngxsmartservice.open('enquirypos');
      // this.ngxsmartservice.open('appointmentform');
    }
  
    closeModal(): void {
      this.ngxsmartservice.close('enquirypos');
      this.ngxsmartservice.close('appointmentform');
      this.enquiryForm.reset();

      this.enquiryForm.get('countryname1').setValue('0');
      this.generatecaptcha();
  }
  closepopup(){
    this.ngxsmartservice.close('myModal');
    this.enquiryForm.reset();

    this.enquiryForm.get('countryname1').setValue('0');
    this.generatecaptcha();
  }
  submitenquery(){
    let valid=this.validation();
    if(valid){
      this.ngxsmartservice.close('enquirypos');
      this.ngxsmartservice.open('appointmentform');
      this.picker.open();
      this.inputField.focus();
    }
   
  }
  currentTime: Date;
  timeSlots:any=[];
  slectdate(event: any){
    this.showElement=true;
    const selectedDate = event.value;
    const finalDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
    this.enquiryForm.get('appointmentdate').setValue(finalDate);
   this.currentTime = new Date();

   let map={
    "timezone":this.timezone,
    "orginaltimezone":this.currentTime
  }
  this.mainservice.gettimeSlots(map).subscribe(data=>{
    this.timeSlots = data[0].map((time:any)=> {
      const [hours, minutes] = time.split(':');
      const hours12 = ((parseInt(hours) + 11) % 12) + 1;
      const ampm = parseInt(hours) < 12 ? 'AM' : 'PM';
      return `${hours12}:${minutes} ${ampm}`;
    });
    this.showElement=false;
    console.log(this.timeSlots);
  })
 
    this.appointment_time=true;
  }
  suplaychain:boolean=true;
  manufacturer:boolean=false;
  importexport:boolean=false;
  candf:boolean=false;
  retailchainstore:boolean=false;
  independentretail:boolean=false;
  solutionlist(value:any){
      if(value==1){
        this.suplaychain=true;
        this.manufacturer=false;
        this.importexport=false;
        this.candf=false;
        this.retailchainstore=false;
        this.independentretail=false;

      }else if(value==2){
        this.manufacturermainicon=true;
        this.suplaychain=false;
        this.manufacturer=true;
        this.importexport=false;
        this.candf=false;
        this.retailchainstore=false;
        this.independentretail=false;

        // -----------------------
        this.lifescience=false;
        this.consumerfood=false;
        this.apparel=false;
        this.veterinary=false;
      
      }else if(value==3){
        this.importexportmianicon=true;
        this.suplaychain=false;
        this.manufacturer=false;
        this.importexport=true;
        this.candf=false;
        this.retailchainstore=false;
        this.independentretail=false;

        // -----------------
        this.ielifescience=false;
        this.ieconsumerfood=false;
        this.ieappearal=false;
        this.ieveterrinary=false;
        this.iefooter=false;
     
      }else if(value==4){
        this.cfmainicon=true;
        this.suplaychain=false;
        this.manufacturer=false;
        this.importexport=false;
        this.candf=true;
        this.retailchainstore=false;
        this.independentretail=false;

        // ------------------------
        this.cflifescience=false;
        this.cfcpg=false;
        this.cfapparel=false;

      }else if(value==5){
        this.retailchainmainicon=true;
        this.suplaychain=false;
        this.manufacturer=false;
        this.importexport=false;
        this.candf=false;
        this.retailchainstore=true;
        this.independentretail=false;

        // --------retailmethod----
       
        this.retaillifescience=false;
        this.retailcpgcmg=false;
        this.retailappearel=false;
      
      }else if(value==6){
        this.independentmainicon=true;
        this.suplaychain=false;
        this.manufacturer=false;
        this.importexport=false;
        this.candf=false;
        this.retailchainstore=false;
        this.independentretail=true;

        // ---------independent retail----------

       
        this.independenthealthcare=false;
        this.independentsupermarket=false;
        this.independentappearel=false;
        this.independentothers=false;
      }
  }

  manufacturermainicon:boolean=true
  lifescience:boolean=false;
  consumerfood:boolean=false;
  apparel:boolean=false;
  veterinary:boolean=false;
  manfacturer(val:any){
    if(val==1){
      this.manufacturermainicon=false;
      this.lifescience=true;
      this.consumerfood=false;
      this.apparel=false;
      this.veterinary=false;
    }
   else if(val==2){
      this.manufacturermainicon=false;
      this.lifescience=false;
      this.consumerfood=true;
      this.apparel=false;
      this.veterinary=false;
    }
    else if(val==3){
      this.manufacturermainicon=false;
      this.lifescience=false;
      this.consumerfood=false;
      this.apparel=true;
      this.veterinary=false;
    }
    else if(val==4){
      this.manufacturermainicon=false;
      this.lifescience=false;
      this.consumerfood=false;
      this.apparel=false;
      this.veterinary=true;
    }
  }

  importexportmianicon:boolean=true;
  ielifescience:boolean=false;
  ieconsumerfood:boolean=false;
  ieappearal:boolean=false;
  ieveterrinary:boolean=false;
  iefooter:boolean=false;

  importexportmethod(val:any){
    if(val==1){
      this.importexportmianicon=false;
      this.ielifescience=true;
      this.ieconsumerfood=false;
      this.ieappearal=false;
      this.ieveterrinary=false;
      this.iefooter=false;
    

    }else if(val==2){

      this.importexportmianicon=false;
      this.ielifescience=false;
      this.ieconsumerfood=true;
      this.ieappearal=false;
      this.ieveterrinary=false;
      this.iefooter=false;
    }else if(val==3){
      this.importexportmianicon=false;
      this.ielifescience=false;
      this.ieconsumerfood=false;
      this.ieappearal=true;
      this.ieveterrinary=false;
      this.iefooter=false;
    
    }else if(val==4){
      this.importexportmianicon=false;
      this.ielifescience=false;
      this.ieconsumerfood=false;
      this.ieappearal=false;
      this.ieveterrinary=true;
      this.iefooter=false;
   
    }else if(val==5){
      this.importexportmianicon=false;
      this.ielifescience=false;
      this.ieconsumerfood=false;
      this.ieappearal=false;
      this.ieveterrinary=false;
      this.iefooter=true;
    }
  }

  // ----------- c and f ------------

  cfmainicon:boolean=true;
  cflifescience:boolean=false;
  cfcpg:boolean=false;
  cfapparel:boolean=false;


  cfmethod(val:any){
    if(val==1){
      this.cfmainicon=false;
      this.cflifescience=true;
      this.cfcpg=false;
      this.cfapparel=false;
    }else if(val==2){
      this.cfmainicon=false;
      this.cflifescience=false;
      this.cfcpg=true;
      this.cfapparel=false;

    }else if(val==3){
      this.cfmainicon=false;
      this.cflifescience=false;
      this.cfcpg=false;
      this.cfapparel=true;
    }
  }

  // ------------- retail chain store ---------
  retailchainmainicon:boolean=true;
  retaillifescience:boolean=false;
  retailcpgcmg:boolean=false;
  retailappearel:boolean=false;

  retailmethod(val:any){
    if(val==1){
      this.retailchainmainicon=false;
      this.retaillifescience=true;
      this.retailcpgcmg=false;
      this.retailappearel=false;
    }else if(val==2){
      this.retailchainmainicon=false;
      this.retaillifescience=false;
      this.retailcpgcmg=true;
      this.retailappearel=false;
    }else if(val==3){
      this.retailchainmainicon=false;
      this.retaillifescience=false;
      this.retailcpgcmg=false;
      this.retailappearel=true;
    }
   
  }

  // --------- independent ------------

  independentmainicon:boolean=true;
  independenthealthcare:boolean=false;
  independentsupermarket:boolean=false;
  independentappearel:boolean=false;
  independentothers:boolean=false;

  independentmethod(val:any){
    if(val==1){

      this.independentmainicon=false;
      this.independenthealthcare=true;
      this.independentsupermarket=false;
      this.independentappearel=false;
      this.independentothers=false;

    }else if(val==2){

      this.independentmainicon=false;
      this.independenthealthcare=false;
      this.independentsupermarket=true;
      this.independentappearel=false;
      this.independentothers=false;
    
    }else if(val==3){

      this.independentmainicon=false;
      this.independenthealthcare=false;
      this.independentsupermarket=false;
      this.independentappearel=true;
      this.independentothers=false;

    }else if(val==4){

      this.independentmainicon=false;
      this.independenthealthcare=false;
      this.independentsupermarket=false;
      this.independentappearel=false;
      this.independentothers=true;
    }
  }


  // country dialcode
  dialcode:any;

  countrychange(){

    this.mainservice.getMobileCode(this.enquiryForm.get('countryname1').value).subscribe((data)=>{
      //console.log(data);
      this.dialcode=data.Dailingcode;
      console.log(this.dialcode);
     // this.registerForm.get('phonedialcode').patchValue(this.dialcode[1]);
    })
  }
  captcha:any
  errorcaptcha:any
  ecaptcha:boolean=false;
  checkCaptcha(){

    var usercaptcha = this.enquiryForm.get('enteredcaptcha').value

    if (this.captcha == usercaptcha) {
      this.ecaptcha = false

    } else {

      this.errorcaptcha = 'Enter correct Captcha'
      this.ecaptcha = true
    }
  }
  generatecaptcha(){
    this.mainservice.generatecaptcha()
    this.captcha = localStorage.getItem('captcha')
  }
  validation(){
    let name=this.enquiryForm.get('leadname').value
    let organizationname=this.enquiryForm.get('organizationname').value
    let primaryemail=this.enquiryForm.get('primaryemail').value
    let primaryphone=this.enquiryForm.get('primaryphone').value
    let captcha=this.enquiryForm.get('enteredcaptcha').value
    let checkbox=this.enquiryForm.get('checkbox').value

    // var usercaptcha = this.registerForm.get('enteredcaptcha').value
    if(!name){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Name',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!organizationname){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Organization Name',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!primaryemail){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Your Email',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!primaryphone){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Your Mobile Number',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!captcha){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Captcha',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(captcha != this.captcha){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Correct Captcha',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(checkbox==false){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Select Accept & Terms condition',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }


    return true
  }
  leads:any;
  savelead(){
    this.showElementsave=true;
   


    // if (valid) {

      let phone = this.enquiryForm.get('primaryphone').value 

      let mobileno=this.enquiryForm.get('phonedialcode').value+this.enquiryForm.get('primaryphone').value 
      this.enquiryForm.get('primaryphone').setValue(mobileno)
      
       console.log('form',this.enquiryForm.value);

  this.mainservice.savelead(this.enquiryForm.value).subscribe((data: any) => {
    this.leads = data
    // console.log("this.leads",this.leads)
    if (data) {
      // this.enquiryForm.reset()
      this.showElementsave=false;
      // window.location.href= 'https://www.vanuston.com/thank-u-vanuston'
      this.enquiryForm.reset();
      // this.showElement=false;
      // this.submitenquery();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Data saved successfully',
        text: 'We will contact you shortly.',
        showConfirmButton: false,
        timer: 4000 
      });
      this.ngxsmartservice.close('enquirypos');
      this.ngxsmartservice.close('appointmentform');
      this.enquiryForm.reset();
      this.enquiryForm.get('countryname1').setValue('0');
      this.generatecaptcha();
    } 
     else{
      this.enquiryForm.get('primaryphone').setValue(phone);
      // this.showElement=false;
    }
  })

    // }
    // else{
    //   this.showElement=false;
    // }


  }
  savesignup(){
   
   
    let valid=this.validation();

    if (valid) {
      this.showElementsave=true;
      let phone = this.enquiryForm.get('primaryphone').value 

      let mobileno=this.enquiryForm.get('phonedialcode').value+this.enquiryForm.get('primaryphone').value 
      this.enquiryForm.get('primaryphone').setValue(mobileno)
      
       console.log('form',this.enquiryForm.value);

  this.mainservice.savelead(this.enquiryForm.value).subscribe((data: any) => {
    this.leads = data
    // console.log("this.leads",this.leads)
    if (data) {
      // this.enquiryForm.reset()
      // window.location.href= 'https://www.vanuston.com/thank-u-vanuston'
      this.enquiryForm.reset();
      this.showElementsave=false;
      // this.submitenquery();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Data saved successfully',
        text: 'We will contact you shortly.',
        showConfirmButton: false,
        timer: 4000 
      });
      this.ngxsmartservice.close('myModal');
      this.enquiryForm.get('countryname1').setValue('0');
      this.generatecaptcha();
    } 
     else{
      this.enquiryForm.get('primaryphone').setValue(phone);
      this.showElement=false;
    }
  })

    }
    else{
      this.showElement=false;
    }


  }

 
  
  // Acceptall(){
  //  localStorage.setItem('cid','0');
  //   this.isVisible=false;
  // }
  // declineAll(){
  //   this.isVisible=false;
  // }

}

