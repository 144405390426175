<!-- <div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/home-three" class="logo"><img src="assets/img/logo/medeil_logo_1.png" alt="Logo"></a>
    </div>
   
    <div class="nav-two main-nav">
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/home-three"><img src="assets/img/logo/medeil_logo_1.png" alt="Logo" height="50px" width="140px"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                       m-auto
                        <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Products</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/medeilstandalone" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Medeil</a></li>
                                <li class="nav-item"><a routerLink="/medeilpos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">MedeilCloud POS</a></li>
                                <li class="nav-item"><a routerLink="/medeilhq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">MedeilCloud HQ</a></li>
                                <li class="nav-item"><a routerLink="/medeilstandalone" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Medeil Standalone</a></li>
                                <li class="nav-item"><a routerLink="/allotmed" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Allotmed</a></li>
                               
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Vertical</a>
                     
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/pharmacyvertical" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pharmacy</a></li>
                                <li class="nav-item"><a routerLink="/opticalsvertical" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Optical</a></li>
                                <li class="nav-item"><a routerLink="/cosmeticsvertical" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Beauty and cosmetics</a></li>
                                <li class="nav-item"><a routerLink="/veterinaryvertical" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Veterinary</a></li>
                                <li class="nav-item"><a routerLink="/healthcarevertical" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Healthcare store</a></li>
                                <li class="nav-item"><a routerLink="/medicaldevicevertical" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Medical device</a></li>
                                <li class="nav-item"><a routerLink="/agochemicalvertical" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Agrochemical</a></li>
                                <li class="nav-item"><a routerLink="/surgicalvertical" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Surgical device</a></li>
                               
                            </ul>
                        </li>
                       
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Resource</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Community</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Training</a></li>
                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service</a></li>
                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Support</a></li>
                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link">Type</a></li>
                    </ul>
                    <div class="side-nav d-in-line align-items-center">
                     

                        <div class="side-item">
                            <div class="nav-add-btn">

                                <a routerLink="/Productlogin" class="default-btn" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Free Trial</a>
                               
                            </div>
                        </div>
                    </div>
                  
                 
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        

                        <div class="side-item">
                            <div class="nav-add-btn">
                                
                                <a routerLink="/Productlogin" class="default-btn" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Free Trial</a> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>
            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div> -->


<div class="navbar-area">
    <nav class="navbar navbar-expand-lg navbar-light ">
      <div class="container-fluid">
       
        <a routerLink="" class="navbar-brand"><img src="https://medeilpos.blob.core.windows.net/posimage/medeil_logo_1.png" loading="lazy" height="50px" width="140px" alt="Medeil pharmacy logo"></a>
       
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
       
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
         
        
          <ul class="navbar-nav">
            <li class="nav-item">
                <a routerLink="" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
            </li>
            <li class="nav-item dropdown">
                <a href="javascript:void(0)" class="nav-link">Products</a>
               
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style="min-width: 230px;">
                    <li class="nav-item"><a routerLink="/medeil" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Medeil</a></li>
                    <li class="nav-item"><a routerLink="/medeilcloudpos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">MedeilCloud POS</a></li>
                    <li class="nav-item"><a routerLink="/medeilcloudhq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">MedeilCloud HQ</a></li>
                 
                    <li class="nav-item"><a routerLink="/allotmed" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Allotmed</a></li>
                </ul>
              </li>
            <li class="nav-item dropdown">
                <a href="javascript:void(0)" class="nav-link">Vertical</a> 
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style="min-width: 230px;">
                    <li class="nav-item"><a routerLink="/vertical/pharmacymanagementsoftware" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pharmacy</a></li>
                    <li class="nav-item"><a routerLink="/vertical/opticalshopmanagementsystem" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Optical</a></li>
                    <li class="nav-item"><a routerLink="/vertical/beautysoftware" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Beauty and cosmetics</a></li>
                    <li class="nav-item"><a routerLink="/vertical/veterinarysoftware" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Veterinary</a></li>
                    <li class="nav-item"><a routerLink="/vertical/softwareformedicalstore" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Healthcare store</a></li>
                    <li class="nav-item"><a routerLink="/vertical/medicaldevicesoftware" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Medical device</a></li>
                    <li class="nav-item"><a routerLink="/vertical/agriculturesoftware" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Agrochemical</a></li>
                 
                </ul>
              </li>
           
              <li class="nav-item">
                <a routerLink="/freesoftwaredownload" class="default-btn" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Free Trial</a> 
              </li>
            
              
          </ul>
        </div>
      </div>
    </nav>
  </div>