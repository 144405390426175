import { DatePipe } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MainserviceService } from 'src/app/mainservice.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-posdemorequest',
  templateUrl: './posdemorequest.component.html',
  styleUrl: './posdemorequest.component.scss',
  providers:[MainserviceService,DatePipe]
})
export class PosdemorequestComponent {
  @ViewChild('picker') picker: MatDatepicker<any>;
  @ViewChild('inputField') inputField:any;
      enquiryForm:FormGroup;
         totalcountry:any=[];
          captcha:any
          errorcaptcha:any
          ecaptcha:boolean=false;
          dialcode:any;
          showElement: boolean = false;
          showElementsave: boolean = false;
          // @ViewChild('picker') picker!: MatDatepicker<Date>;
          // @ViewChild('inputField') inputField!: any;
          currentTime: Date;
          timeSlots:any=[];
          timezone:any;
          appointment_time:boolean=false;
          slotForm:boolean=false;
          deatilsForm:boolean=true;
          selectedTime: string | null = null;
          leads:any;
        constructor(private Router:Router,private fb:FormBuilder,private mainservice:MainserviceService,private datePipe: DatePipe){
          this.timezone=sessionStorage.getItem('timezone');
           this.enquiryForm=this.fb.group({
                leadname: ['',[ Validators.required]],
                organizationname: ['',[Validators.required]],
                leadsourceid: ['',[]],
                leadsource: ['medeil website',[]],
                sourceRef2: ['medeil cloud pos',[]],
                sourceRef1: ['medeil cloud pos',[]],
                // sourceRef2: ['',[]],
                description: ['',[ Validators.required]],
                primaryphone: ['',[Validators.required]],
                primaryemail: ['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
                address1: ['',[]],
                address2: ['',[]],
                country: [sessionStorage.getItem('country'),[],[]],
                countryname1: [null,[]],
                state: [sessionStorage.getItem('state'),[]],
                city: [sessionStorage.getItem('city'),[]],
                enteredcaptcha: ['',[]],
                generatedcaptcha: ['',[]],
                phonedialcode:['',[]],
                checkbox:[false,[]],
                appfrmtime:['',[]],
                apptotime:['',[]],
                appointmentdate:['',[]],
              })
              this.generatecaptcha();
              this.mainservice.getCountryList().subscribe(data=>{
                console.log(data);
                this.totalcountry=[];
                for(let i=0;i<data.length;i++){
                  this.totalcountry.push({
                    value:data[i][0],label:data[i][1]
                  })
                }
                // this.totalcountry=data;
              })
        }


          countrychange(){
            console.log(this.enquiryForm.get('countryname1').value,'test')
            this.mainservice.getMobileCode(this.enquiryForm.get('countryname1').value.label).subscribe((data)=>{
              //console.log(data);
              this.dialcode=data.Dailingcode;
              console.log(this.dialcode);
             // this.registerForm.get('phonedialcode').patchValue(this.dialcode[1]);
            })
          }
          checkCaptcha(){

            var usercaptcha = this.enquiryForm.get('enteredcaptcha').value

            if (this.captcha == usercaptcha) {
              this.ecaptcha = false

            } else {

              this.errorcaptcha = 'Enter correct Captcha'
              this.ecaptcha = true
            }
          }
          generatecaptcha(){
            this.mainservice.generatecaptcha()
            this.captcha = localStorage.getItem('captcha')
          }


          validation(){
            let name=this.enquiryForm.get('leadname').value
            let organizationname=this.enquiryForm.get('organizationname').value
            let country=this.enquiryForm.get('countryname1').value
            let primaryemail=this.enquiryForm.get('primaryemail').value
            let primaryphone=this.enquiryForm.get('primaryphone').value
            let captcha=this.enquiryForm.get('enteredcaptcha').value
            let checkbox=this.enquiryForm.get('checkbox').value

            // var usercaptcha = this.registerForm.get('enteredcaptcha').value
            if(!name){
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Please Enter Name',
                showConfirmButton: false,
                timer: 1500
              })
              return false;
            }
            // else if(!organizationname){
            //   Swal.fire({
            //     position: 'center',
            //     icon: 'warning',
            //     title: 'Please Enter Organization Name',
            //     showConfirmButton: false,
            //     timer: 1500
            //   })
            //   return false;
            // }
            else if(!primaryemail){
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Please Enter Your Email',
                showConfirmButton: false,
                timer: 1500
              })
              return false;
            }
            else if(!country){
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Please Select Country',
                showConfirmButton: false,
                timer: 1500
              })
              return false;
            }
            else if(!primaryphone){
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Please Enter Your Mobile Number',
                showConfirmButton: false,
                timer: 1500
              })
              return false;
            }
            else if(!captcha){
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Please Enter Captcha',
                showConfirmButton: false,
                timer: 1500
              })
              return false;
            }
            else if(captcha != this.captcha){
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Please Enter Correct Captcha',
                showConfirmButton: false,
                timer: 1500
              })
              return false;
            }
            else if(checkbox==false){
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Please Select Accept & Terms condition',
                showConfirmButton: false,
                timer: 1500
              })
              return false;
            }
            return true
          }
          slectdate(event: any){
            this.showElement=true;
            const selectedDate = event.value;
            const finalDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
            this.enquiryForm.get('appointmentdate').setValue(finalDate);
           this.currentTime = new Date();

           let map={
            "timezone":this.timezone,
            "orginaltimezone":this.currentTime
          }
          this.mainservice.gettimeSlots(map).subscribe(data=>{
            this.timeSlots = data[0].map((time:any)=> {
              const [hours, minutes] = time.split(':');
              const hours12 = ((parseInt(hours) + 11) % 12) + 1;
              const ampm = parseInt(hours) < 12 ? 'AM' : 'PM';
              return `${hours12}:${minutes} ${ampm}`;
            });
            this.showElement=false;
            console.log(this.timeSlots);
          })
            this.appointment_time=true;
          }
          selectTime(time: string): void {
            const currentTime = new Date();
            const formattedTime = this.datePipe.transform(currentTime, 'hh:mm:ss a');
            console.log(formattedTime);
            this.selectedTime = time;
            // this.enquiryForm.get('apptotime').setValue(time);
            this.enquiryForm.get('appfrmtime').setValue(time);

          }
          submitenquery(){
            let valid=this.validation();
            if(valid){
              this.slotForm=true;
              this.deatilsForm=false;
              if (this.picker) {
                this.picker.open();
              } else {
                console.warn('Picker is not available at this time.');
              }
              this.inputField.focus();
              this.picker.open();


            }

          }
          savelead(){
            // let valid=this.validation();
            let valid=true;
            if (valid) {
              this.showElement=true;
              let phone = this.enquiryForm.get('primaryphone').value

              let mobileno=this.enquiryForm.get('phonedialcode').value+this.enquiryForm.get('primaryphone').value
              this.enquiryForm.get('primaryphone').setValue(mobileno)
              this.enquiryForm.get('countryname1').setValue(this.enquiryForm.get('countryname1').value.label)

               console.log('form',this.enquiryForm.value);
              //  const newTab = window.open('', '_blank');
              //  newTab.location.href = 'https://secure.medeil.io/medeilpos/eng/23pos/#/usersignup';
          this.mainservice.savelead(this.enquiryForm.value).subscribe((data: any) => {
          this.leads = data
            if (data) {
              this.enquiryForm.reset();
              this.showElement=false;
              // this.submitenquery();
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'We will contact you shortly',
                text: 'Data saved successfully.',
                showConfirmButton: false,
                timer: 4000
              });
              this.Router.navigate(['/'])
            }
             else{
              this.enquiryForm.get('primaryphone').setValue(phone);
              this.showElement=false;
            }
            // this.enquiryForm.get('countryname1').setValue('0');
            //   this.generatecaptcha();
          })
        }
          }
}
