import { createAction, props } from "@ngrx/store";
import { Blog } from "../model/blog";

export const loadBlog=createAction('[Blog] Load Blog');
export const loadBlogSuccess=createAction('[Blog] Load Blog Success',props<{blog:Blog[]}>());
export const loadBlogFailure=createAction('[Blog] Load Blog Failure',props<{error:string}>());

export const addBlog = createAction('[Blog] Add Blog',props<{ blog: Blog }>());
export const addBlogSuccess = createAction('[Blog] Add Blog Success', props<{ blog: Blog }>());
export const addBlogFailure = createAction('[Blog] Add Blog Failure', props<{ error: string }>());

export const deleteBlog=createAction('[Blog] Delete Blog',props<{id:string}>())
export const deleteBlogSuccess=createAction('[Blog] Delete Blog Success',props<{id:string}>());
export const deleteBlogFailure=createAction('[Blog] Delete Blog Failure',props<{error:string}>())
