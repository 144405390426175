import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Blog } from '../../model/blog';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { addBlog, addBlogSuccess } from '../../action/blog.actions';

@Component({
  selector: 'app-blog-dialog',
  templateUrl: './blog-dialog.component.html',
  styleUrl: './blog-dialog.component.scss'
})
export class BlogDialogComponent {
 blogForm:FormGroup
  imageUrl: string = '';
  selectedImage: string | ArrayBuffer | null = null;
  currentBlog: any;
  blogSaved = false;
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],  // Text formatting
      [{ 'font': [] }, { 'size': [] }],           // Font and size
      [{ 'align': [] }],                          // Text alignment (includes justify)
      ['link', 'image', 'video'],                 // Media (link, image, video)
      [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
      [{ 'indent': '-1' }, { 'indent': '+1' }],   // Indentation
      ['blockquote'],                             // Blockquote
      ['code-block'],                             // Code block
      [{ 'color': [] }, { 'background': [] }],    // Text and background colors
      ['clean'],                                  // Clear formatting
      ['undo', 'redo'],                           // Undo and Redo actions
      [{ 'direction': 'rtl' }],                   // Text direction (RTL)
      ['emoji'],                                  // Emoji picker (if added via plugin)
    ]
  };
  blog: Blog | undefined;
  constructor(private fb:FormBuilder,private store:Store,private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<BlogDialogComponent> ,@Inject(MAT_DIALOG_DATA) public data: { blog: Blog }){
    this.blogForm = this.fb.group({
      title: ['', Validators.required],
      content: ['', Validators.required],
      image: [null],
      // subtitle: [null],
      seotitle: [null],
      seodescription: [null],
      seokeyword: [null],
      id:[''],
      status:['0']
    });
    if (data.blog) {
      this.blog = data.blog;
      this.blogForm.patchValue(this.blog);
      this.imageBase64Url=this.blog.imageUrl;
      // this.blogForm.get('image').setValue(file)

      console.log(this.blogForm.get('image').value,'skdufsjkdfjkajfd')
    }
  }

  onSubmit() {
    if (this.blogForm.valid) {
      const blog: Blog = {
        id:this.blogForm.value.id,
        title: this.blogForm.value.title,
        content: this.blogForm.value.content,
        imageUrl: this.imageBase64Url,
        subtitle: this.blogForm.value.subtitle,
        createdAt: new Date(),
        status:'0',
        seotitle: this.blogForm.value.seotitle,
        seodescription: this.blogForm.value.seodescription,
        seokeyword:this.blogForm.value.seokeyword
      };

      this.store.dispatch(addBlog({ blog:blog }));
      this.snackBar.open('Blog added successfully!', 'Close', { duration: 3000 });
      this.dialogRef.close(); // Close the dialog after saving
    } else {
      this.snackBar.open('Please fill out all fields', 'Close', { duration: 3000 });
    }
  }
  imageBase64Url: string | null = null;

  onImageChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files && input.files.length > 0) {
      const file = input.files[0];

      const reader = new FileReader();
      reader.onload = () => {
        this.imageBase64Url = reader.result as string;
        console.log('Base64 URL:', this.imageBase64Url);
      };
      reader.onerror = (error) => {
        console.error('Error converting file to Base64:', error);
      };
      reader.readAsDataURL(file);
    }
  }

}
