import { Component } from '@angular/core';
import { Blog } from '../../model/blog';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { deleteBlog, deleteBlogSuccess, loadBlog } from '../../action/blog.actions';
import { MatDialog } from '@angular/material/dialog';
import { BlogDialogComponent } from '../blog-dialog/blog-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-medeil-blogs',
  templateUrl: './medeil-blogs.component.html',
  styleUrl: './medeil-blogs.component.scss'
})
export class MedeilBlogsComponent {
    blog$: Blog[];
    defaultImage="assets/img/loginimg.png";
    constructor(private store: Store<{blog:{blog:Blog[]}}>, private route: ActivatedRoute,private dialog:MatDialog,
      private router: Router,private sanitizer: DomSanitizer,private snackBar: MatSnackBar) {
      this.store.select('blog').subscribe((state:{blog:Blog[]})=>{
        this.blog$=state.blog;
        console.log(this.blog$,'this.blog$this.blog$')
      })
    }

    ngOnInit() {
      this.store.dispatch(loadBlog());
    }

    openAddBlogDialog(): void {
    const dialogref= this.dialog.open(BlogDialogComponent, {
        width: '800px',
        data: {  }
      });
      dialogref.afterClosed().subscribe(result => {
      });
    }

    openBlogDetail(blog: Blog) {
      const decodedTitle = decodeURIComponent(blog.title);
      const formattedTitle = decodedTitle.replace(/\s+/g, "");
      console.log(formattedTitle);
      this.router.navigate(['/blog', formattedTitle], { state: { blog } });
    }
    getImageUrl(blog: Blog): string {
      return blog.imageUrl ? blog.imageUrl : this.defaultImage;
    }
    editBlog(blog: Blog){
      const dialogRef = this.dialog.open(BlogDialogComponent, {
        width: '800px',
        data: { blog }
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }
    deleteBlog(blog:Blog){
      this.store.dispatch(deleteBlog({ id: blog.id }));
      this.snackBar.open('Blog Deleted successfully!', 'Close', { duration: 3000 });
    }
}
