export const environment = {
  production: true,
  backend: {
  
    thirdpartyUrl:"http://20.212.200.204:8181/third-party-api/api/v1",
    // apiKey: 'https://fluead.medeil.net/medeil-pos-crm/api/',
   krakendUrl:"http://4.193.169.116:8080",
   baseURL: "http://4.193.81.81:8081/PharmaAuth/oauth",
  //  paymentUrl: "http://192.168.0.188:9998/payment/",
   paymentUrl: "https://secure.medeil.io/medeilpospay/payment/",
  //  thirdpartyUrl:"http://20.212.200.204:8181/third-party-api/api/v1",
  // baseResUrl: 'https://secure.medeil.io/medeil-pos-crm/api/',
  baseResUrl: 'https://fluead.medeil.net/medeil-pos-crm/api/',
    // baseResUrl: "http://192.168.0.188:7780/api/",
  apiKey: 'https://fluead.medeil.net/medeil-pos-crm/api/',
  },
  medauthbackend: {
  // baseResUrl2: "https://medpharma.vanuston.org/Pharmaboot/medauth"
    baseResUrl2: "http://4.193.81.81:8081/Pharmaboot/medauth"
  }
};

