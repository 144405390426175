

<div style="background-color: rgb(252, 249, 249);display: flex; justify-content: flex-end; align-items: center;">
    <div class="flexbox">
        <div class="nav-item dropdown">
            <a class="nav-link" href="#" id="languageDropdown" role="button" aria-haspopup="true" aria-expanded="false">
            </a>
            <div class="dropdown-menu" aria-labelledby="languageDropdown" style="background-color: white;width: 250px; border-top: 2px solid blue;border-left: none;border-right: none;border-bottom: none;border-radius: 0;">
                <div class="nav-item">


                    <div class="row">
                        <div class="col-lg-6  mt-2">
                            <div class="row">

                                <div class="col-md-4 col-5">
                                    <a href="#" style="height:20px;width: 40px; display: flex;justify-content: center;">
                                        <i class="fa fa-globe" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flexbox">
        <div class="nav-item">
            <a class="nav-link" href="https://www.vanuston.com/">About Us</a>
        </div>
    </div>
    <div class="flexbox">
        <div class="nav-item">
            <a class="nav-link" href="https://www.vanuston.com/">Contact Us</a>
        </div>
    </div>
    <div class="flexbox" (click)="openvauston()">
        <a>
            <img src="assets/img/logo/vanuston-image.png" alt="Logo" height="22px" width="90px">
        </a>
    </div>
</div>
