<app-navbar-style-two></app-navbar-style-two>
<div class="container">
    <div class="blog-area">
      <div class="container">
        <div class="blog-header text-center">
          <h2>Latest Blog Posts</h2>
        </div>
      </div>
    </div>
    <div class="row pt-45">
      <div class="col-lg-4 col-md-4 col-sm-6 col-12" *ngFor="let blog of blog$">
        <div class="blog-card">
          <div class="card-header">
            <a (click)="openBlogDetail(blog)">
              <img [src]="getImageUrl(blog)" alt="{{ blog.title }}" />
            </a>
          </div>
          <div class="card-body">
            <h3 class="card-title">
              <a (click)="openBlogDetail(blog)">{{ blog.title }}</a>
            </h3>
            <p class="card-text">
              {{ blog.description | slice: 0:100 }}...
            </p>
            <a (click)="openBlogDetail(blog)" class="read-more">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>


  <app-footer-style-two></app-footer-style-two>