import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-posnavbar',
  templateUrl: './posnavbar.component.html',
  styleUrls: ['./posnavbar.component.scss']
})
export class PosnavbarComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
