<app-navbar-style-two></app-navbar-style-two>

<!-- <app-posnavbar></app-posnavbar> -->
<div class="banner-area-two client-slider owl-carousel owl-theme">

   
    <div class="client-item">
        <!-- <img src="assets/img/team/veterinarytest.png" alt="image"> -->
        <img src="https://medeilpos.blob.core.windows.net/websiteimage/medeicaldevicesoftware - medeilcloud pos.png" alt="medeical device software - medeilcloud pos">
      

        <div class="overlay-buttons row">
            <div class="col-lg-6">
                <button type="button" class="default-btn1">14 Days Free Trial</button>
            </div>
            <div class="col-lg-6">
                <button type="button" class="default-btn1" (click)="appointment('Medical Device Vertical')">Demo Request</button>
            </div>
              
              
        </div>
    </div>
  
</div>
<ngx-smart-modal #enquirypos identifier="enquirypos" [closable]="false">


  <div class="custom-modal">
     <button class="close-btn" (click)="closeModal()">X</button>
     <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
     </div>
       <div class="modal-body">
         <form [formGroup]="enquiryForm">
           <div class="form-group">
             <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
             <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
            </div>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Country</strong></label>
             <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
             <select class="form-control"  formControlName="countryname1" style="font-size: 12px;" (change)="countrychange()">
              <!-- (change)="countrychange()" -->
              <option select disabled value="0" >---Select Country---</option>
              <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>
  
            </select>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
             <div class="row">
               <div class="col-lg-3">
                 <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
               </div>
               <div class="col-lg-9">
                 <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
                 autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
               </div>
             </div>
            
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Description</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
           </div>
           <!-- <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name">
           </div> -->
           <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
           <div class="input-group">
              <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
               (change)="checkCaptcha()"/>
              <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
              [(ngModel)]="captcha"/>
              <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
            </div>
            <div *ngIf="ecaptcha">
              <p style="color: red">{{ errorcaptcha }}</p>
            </div>
           <div class="form-group">
            
                 <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                     <input type="checkbox" checked="checked" formControlName="checkbox">
                     <span class="checkmark"></span>
                 </label>
           </div>
           <div class="btn-group mt-3" *ngIf="!showElement">
             <button type="button" class="enquiry-btn" (click)="submitenquery()" >Submit</button>

           </div>
          
           
         </form>
       </div>
 </div>
</ngx-smart-modal>
<ngx-smart-modal #appointmentform identifier="appointmentform" [closable]="false">


  <div class="custom-modal"  >
     <button class="close-btn" (click)="closeModal()">X</button>
      <div class="modal-header-appointment">
          <h4 class="modal-title" id="exampleModalLabel">Demo Request</h4>
      </div>
        <div class="modal-body">
       
           <div class="row">
              <div class="col-lg-12">
                 <mat-form-field >
                   
                    <input matInput [matDatepicker]="picker" placeholder="Choose a date" (dateChange)="slectdate($event)" >
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                 </mat-form-field>
                
              </div>
              <div  align="center" *ngIf="showElement">
                 <img height="200px" width="200px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
               </div>
              <div class="col-lg-12" *ngIf="appointment_time">
                 
                  <div class="button-container">
                    <button class="flex-button" *ngFor="let time of timeSlots" (click)="selectTime(time)" [ngClass]="{ 'clicked': selectedTime === time }">{{ time }}</button>
                </div>
                
              <div  align="center" *ngIf="showElementsave">
                <img height="100px" width="100px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
              </div>
                  <div class="shedule-submit">
                    <button class="shedulebtn" (click)="savelead()">Submit</button>
                  </div>
              </div>
           </div>

        </div>
  </div>
</ngx-smart-modal>
<div class="newslider" >

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/anu-pharmacy.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/apollo-hospitals.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/bellamed-healthcare.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/bk-heart-hospital.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/booncholai-super-market.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/emedsure.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/express-pharmacy.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/falcon-healthcare.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/global-bridge-pharmaceutical.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/hawi-family-hostpital.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/henry-jackson-foundation.webp" alt="image" loading="lazy">
  </div>

  <div class="place-list-item">
      <img src="https://medeilpos.blob.core.windows.net/websiteimage/imcri.webp" alt="image" loading="lazy">
  </div>

</div><!-- <div class="section-title container gradient-text mb-2" >
  
    <h2>Features</h2>
</div> -->
<!-- ---------------------------- -->


<div id="services" class="services section">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
            <div class="gradient-text"><h1>Amazing  Services &amp; Features </h1>for you</div>
            <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
            <p>
                The medical device ERP (Enterprise Resource Planning) software space would likely incorporate advanced features leveraging emerging technologies to enhance efficiency, compliance, and innovation.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
            <div class="service-item third-service">
              <div class="icon"></div>
              <h4>Regulatory Compliance Management</h4>
            <p style="line-height: 1.4;">
              Enhanced functionalities for handling industry-specific recommendations, ISO (International Organization for Standardization) standards, and FDA (Food and Drug Administration) regulations. Software can ensure conformity to regulatory requirements throughout the product life cycle by automating audit trails, reporting, and compliance paperwork.
            </p>
              <div class="text-button">
                <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="service-item fourth-service">
              <div class="icon"></div>
              <h4>Remote Software Updates and Maintenance</h4>
              <p style="line-height: 1.4;">
                  To guarantee that medical equipment is always operating on the most recent firmware and software patches, over-the-air software updates and remote maintenance capabilities are provided. Updates and patches can be automatically deployed by software, eliminating the need for human intervention and minimizing interference with device functionality.
              </p>
              <div class="text-button">
                <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
              </div>
            </div>
          </div>
        <div class="col-lg-3">
          <div class="service-item first-service">
            <div class="icon"></div>
            <h4>Predictive analytics</h4>
           <p style="line-height: 1.9;">
            Using artificial intelligence to estimate medical device demand, optimize inventory levels, and improve supply chain efficiency. Machine learning algorithms can enhance forecasting accuracy and inventory planning by analyzing past data, market trends, and client preferences.
           </p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="service-item second-service">
            <div class="icon"></div>
            <h4>Supply Chain Transparency</h4>
          <p style="line-height: 1.9;">
            Making use of cutting-edge technologies to improve supply chain traceability and transparency for medical devices. Software with futuristic features can offer an unchangeable transaction ledger, guaranteeing the accuracy and consistency of data.
          </p>
            <div class="text-button">
              <a href="#">Read More <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
  <!-- ---------------------------- -->

<!-- about  -->
<div id="about" class="about-us section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 align-self-center">
          <div class="section-heading">
            <h4>Who we are?</h4>
            <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
            <!-- <img src="assets/images/heading-line-dec.png" alt=""> -->
         <p>
           In the medical device software space, we are a leading provider of innovative software solutions tailored to the unique needs of medical device manufacturers, distributors, and healthcare providers. Our mission is to empower organizations in the medical device industry with cutting-edge software that streamlines operations, ensures regulatory compliance, and drives business growth.
         </p>
          </div>
          <div class="row">
              <div class="col-lg-6">
                  <div class="box-item" routerLink="/medeil">
                    <h5><a >Standalone Version</a></h5>
                    <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="box-item" routerLink="/medeilcloudpos">
                    <h5><a >SaaS Version</a></h5>
                    <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="box-item" routerLink="/medeilcloudhq">
                    <h5><a >Chain Store Version</a></h5>
                    <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="box-item" routerLink="/allotmed">
                    <h5><a >Distribution Version</a></h5>
                    <!-- <p class="ptag">Lorem Ipsum Text</p> -->
                  </div>
                </div>
            <div class="col-lg-12">
           <p>
           We are a trusted partner in the medical device ERP space, committed to providing innovative, comprehensive, and compliant software solutions that empower organizations to thrive in an ever-evolving healthcare landscape.
           </p>
              <div>
                <!-- <button class="gradient-button"> Start Free Trial</button> -->
               </div>
              <!-- <span>*No Credit Card Required</span> -->
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="right-image">
            <img src="https://medeilpos.blob.core.windows.net/websiteimage/medicalposbilling- medeilcloudpos.gif" alt="medical pos billing- medeilcloud pos" loading="lazy">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ---- -->
<!-- ---------------------------- -->
<div class="container mt-4">
    <div class="section-title gradient-text mt-2 mb-2" >
       
        <h1>Frequently Asked Questions</h1>
    </div>
    <div class="row pt-4">
        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: How is patient data protection and the integration of electronic health records (EHR) handled by your software?</h4>
                <p><strong>A:</strong> This inquiry focuses on the software's security and privacy safeguards for patient data as well as its compatibility with EHR systems. In order to guarantee patient care continuity, responses should place a strong emphasis on encryption techniques, safe data transmission protocols, HIPAA compliance, and capabilities for easy connection with EHR platforms.</p>
            </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: What functionalities does your software provide for managing the life cycle of medical devices and filing regulatory reports? </h4>
                <p><strong>A:</strong> This inquiry concerns the features of the software that are used to manage the whole medical device lifecycle, from creation and design to production, delivery, and post-market monitoring. In order to expedite time-to-market and simplify compliance procedures, responses should emphasize elements including design controls, risk management, change management, and regulatory submissions management.</p>
            </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: Is it possible for your software to provide the remote maintenance and monitoring of medical devices used in telehealth and remote patient care?</h4>
                <p><strong>A:</strong> The software's ability to provide remote medical device monitoring, diagnosis, and maintenance for telehealth and remote patient care applications is the main focus of this question. In order to facilitate virtual consultations and proactive equipment management, responses should emphasize features like remote access, real-time data streaming, predictive maintenance algorithms, and secure communication protocols.</p>
            </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
            <div class="faq-item">
                <h4>Q: How is data interchange and interoperability with other medical devices and healthcare IT systems ensured by your software?</h4>
                <p><strong>A:</strong> The software's compatibility with medical equipment, other healthcare IT systems, and interoperability standards like HL7 and DICOM is the subject of this inquiry. In order to provide smooth data interchange and interoperability within the healthcare ecosystem, responses should outline integration capabilities, support for standardized data formats, APIs (Application Programming Interfaces), and interoperability certifications.</p>
            </div>
        </div>
    </div>
    
    
</div>
<div class="row mb-3 mt-3" style="width: 100%;">
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Crowd Review</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.3/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Google</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.8/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Capterra</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.4/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Software Suggest</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.7/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>G2 Crowd</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.1/5.0</p>
        </div>
  </div>
  <div class="col-lg-2">
      <div class="rating-section">
          <h6>Trust Radius</h6>
          <div class="rating-stars">
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9733;</div>
            <div class="starrate">&#9734;</div>
          </div>
          <p>4.6/5.0</p>
        </div>
  </div>
</div>
<app-footer-style-two></app-footer-style-two>

