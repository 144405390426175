import { Component,OnInit,TemplateRef,ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormGroup } from '@angular/forms';
import { FormBuilder,Validators } from '@angular/forms';
import { MainserviceService } from 'src/app/mainservice.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-generalagro',
  templateUrl: './generalagro.component.html',
  styleUrls: ['./generalagro.component.scss'],
  providers:[MainserviceService,DatePipe]
})
export class GeneralagroComponent {


  enquiryForm:FormGroup;
  totalcountry:any=[];
  captcha:any
  errorcaptcha:any
  ecaptcha:boolean=false;
  dialcode:any;
  showElement: boolean = false;
  showElementsave: boolean = false;
  @ViewChild('picker') picker!: MatDatepicker<Date>;
  @ViewChild('inputField') inputField!: any;
  currentTime: Date;
  timeSlots:any=[];
  timezone:any;
  appointment_time:boolean=false;
  selectedTime: string | null = null;
  leads:any;
constructor(private ngxsmartservice:NgxSmartModalService,private fb:FormBuilder,
  private mainservice:MainserviceService,private datePipe: DatePipe,private title:Title,private meta:Meta){

  this.title.setTitle('Grow Your Agro Business with Medeilcloud POS Software');

  // Add meta tags dynamically
  this.meta.addTag({name: 'description', content: 'Optimize operations, increase ROI. Automate processes for success. Streamline agro, pesticide, and dairy operations. Maximize efficiency today!'  });
  this.meta.addTag({ name: 'keywords', content: 'agriculture software, farming software, agro store software, dairy product software, agro management software' });
  this.timezone=sessionStorage.getItem('timezone');
  this.enquiryForm=this.fb.group({
      leadname: ['',[ Validators.required]],
      organizationname: ['',[Validators.required]],
      leadsourceid: ['',[]],
      leadsource: ['medeil website',[]],
      sourceRef1: ['',[]],
      sourceRef2: ['',[]],
      description: ['',[ Validators.required]],
      primaryphone: ['',[Validators.required]],
      primaryemail: ['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      address1: ['',[]],
      address2: ['',[]],
      country: [sessionStorage.getItem('country'),[],[]], 
      countryname1: ['0',[]],
      state: [sessionStorage.getItem('state'),[]], 
      city: [sessionStorage.getItem('city'),[]], 
      enteredcaptcha: ['',[]],
      generatedcaptcha: ['',[]],
      phonedialcode:['',[]],
      checkbox:[false,[]],
      appfrmtime:['',[]],
      apptotime:['',[]],
      appointmentdate:['',[]],
    })
  }

  ngOnInit(): void {
    this.mainservice.generatecaptcha();
    this.captcha = localStorage.getItem('captcha')
     // ----country list-- 
     this.mainservice.getCountryList().subscribe(data=>{
       console.log(data);
       this.totalcountry=data;
     })
  }
  countrychange(){

    this.mainservice.getMobileCode(this.enquiryForm.get('countryname1').value).subscribe((data)=>{
      //console.log(data);
      this.dialcode=data.Dailingcode;
      console.log(this.dialcode);
     // this.registerForm.get('phonedialcode').patchValue(this.dialcode[1]);
    })
  }
  checkCaptcha(){
  
    var usercaptcha = this.enquiryForm.get('enteredcaptcha').value
  
    if (this.captcha == usercaptcha) {
      this.ecaptcha = false
  
    } else {
  
      this.errorcaptcha = 'Enter correct Captcha'
      this.ecaptcha = true
    }
  }
  generatecaptcha(){
    this.mainservice.generatecaptcha()
    this.captcha = localStorage.getItem('captcha')
  }
  closeModal(): void {
    this.ngxsmartservice.close('enquirypos');
    this.ngxsmartservice.close('appointmentform');
    this.enquiryForm.reset();

    this.enquiryForm.get('countryname1').setValue('0');
    this.generatecaptcha();
  }
  validation(){
    let name=this.enquiryForm.get('leadname').value
    let organizationname=this.enquiryForm.get('organizationname').value
    let primaryemail=this.enquiryForm.get('primaryemail').value
    let primaryphone=this.enquiryForm.get('primaryphone').value
    let captcha=this.enquiryForm.get('enteredcaptcha').value
    let checkbox=this.enquiryForm.get('checkbox').value
  
    // var usercaptcha = this.registerForm.get('enteredcaptcha').value
    if(!name){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Name',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!organizationname){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Organization Name',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!primaryemail){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Your Email',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!primaryphone){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Your Mobile Number',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(!captcha){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Captcha',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(captcha != this.captcha){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Enter Correct Captcha',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
    else if(checkbox==false){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please Select Accept & Terms condition',
        showConfirmButton: false,
        timer: 1500
      })
      return false;
    }
  
  
    return true
  }
  slectdate(event: any){
    this.showElement=true;
    const selectedDate = event.value;
    const finalDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
    this.enquiryForm.get('appointmentdate').setValue(finalDate);
   this.currentTime = new Date();
  
   let map={
    "timezone":this.timezone,
    "orginaltimezone":this.currentTime
  }
  this.mainservice.gettimeSlots(map).subscribe(data=>{
    this.timeSlots = data[0].map((time:any)=> {
      const [hours, minutes] = time.split(':');
      const hours12 = ((parseInt(hours) + 11) % 12) + 1;
      const ampm = parseInt(hours) < 12 ? 'AM' : 'PM';
      return `${hours12}:${minutes} ${ampm}`;
    });
    this.showElement=false;
    console.log(this.timeSlots);
  })
  
    this.appointment_time=true;
  }
  selectTime(time: string): void {
    const currentTime = new Date();
    const formattedTime = this.datePipe.transform(currentTime, 'hh:mm:ss a');
    console.log(formattedTime);
    this.selectedTime = time;
    // this.enquiryForm.get('apptotime').setValue(time);
    this.enquiryForm.get('appfrmtime').setValue(time);
    
  }
  savelead(){
    this.showElementsave=true;
   
  
  
    // if (valid) {
  
      let phone = this.enquiryForm.get('primaryphone').value 
  
      let mobileno=this.enquiryForm.get('phonedialcode').value+this.enquiryForm.get('primaryphone').value 
      this.enquiryForm.get('primaryphone').setValue(mobileno)
      
       console.log('form',this.enquiryForm.value);
  
  this.mainservice.savelead(this.enquiryForm.value).subscribe((data: any) => {
    this.leads = data
    // console.log("this.leads",this.leads)
    if (data) {
      // this.enquiryForm.reset()
      // window.location.href= 'https://www.vanuston.com/thank-u-vanuston'
      this.enquiryForm.reset();
      this.showElementsave=false;
      // this.submitenquery();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Data saved successfully',
        text: 'We will contact you shortly.',
        showConfirmButton: false,
        timer: 4000 
      });
      this.ngxsmartservice.close('enquirypos');
      this.ngxsmartservice.close('appointmentform');
      this.enquiryForm.reset();
    } 
     else{
      this.enquiryForm.get('primaryphone').setValue(phone);
      // this.showElement=false;
    }
    this.enquiryForm.get('countryname1').setValue('0');
    this.generatecaptcha();
  })
  
  }
  appointment(datasource:any){
    this.enquiryForm.get('sourceRef1').setValue(datasource);
    this.ngxsmartservice.open('enquirypos');
    // this.ngxsmartservice.open('appointmentform');

    this.enquiryForm.get('countryname1').setValue('0');
    this.generatecaptcha();
  }
  submitenquery(){
    let valid=this.validation();
    if(valid){
      this.ngxsmartservice.close('enquirypos');
      this.ngxsmartservice.open('appointmentform');
      this.picker.open();
      this.inputField.focus();
    }
   
  }
}
