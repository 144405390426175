<app-navbar-style-two></app-navbar-style-two>

<div class="user-area">
    <div class="container-fluid m-0">
        <div class="row align-items-center">
            <div class="col-lg-7 col-xl-6 p-0">
                <div class="user-img">
                    <img src="assets/img/loginimg.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-5 col-xl-6">
                <form [formGroup]="loginForm" (ngSubmit)="submit()">
                    <div class="user-section text-center">
                        <div class="user-content">
                            <h2>Welcome <b>Back</b></h2>
                        </div>
                        <div class="tab user-tab">
                            <ul class="tabs">
                                <li><a href="#"> <i class="flaticon-contact"></i> Login</a></li>
                                <li><a href="#"> <i class="flaticon-verify"></i> Register</a></li>
                            </ul>
                            <div class="tab_content current active">
                                <div class="tabs_item current">
                                    <div class="user-all-form">
                                        <div class="contact-form">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" formControlName="username" id="username"
                                                            class="form-control" placeholder="Username or Email">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <i class="bx" [class.bx-lock-alt]="!showPassword"
                                                            [class.bx-show]="showPassword"
                                                            (click)="togglePasswordVisibility()">
                                                        </i>
                                                        <input class="form-control" formControlName="password"  [type]="showPassword ? 'text' : 'password'"
                                                            type="password" name="password" placeholder="Password">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 text-center">
                                                    <button type="submit"
                                                        class="default-btn user-all-btn">Login</button>
                                                </div>
                                                <div class="col-lg-6 col-sm-6 form-condition">
                                                    <div class="agree-label">
                                                        <input type="checkbox" id="chb1">
                                                        <label for="chb1">Remember Me</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-sm-6">
                                                    <a class="forget" routerLink="/">Forgot my password?</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>



<app-footer-style-two></app-footer-style-two>