import { createReducer,on } from "@ngrx/store";
import { Blog } from "../model/blog";
import { addBlogFailure, addBlogSuccess, deleteBlog, deleteBlogFailure, deleteBlogSuccess, loadBlogFailure, loadBlogSuccess } from "../action/blog.actions";

export interface BlogState {
    blog: Blog[] | null;
    error: string | null;
  }

  export const initialState: BlogState = {
    blog: [],
    error: null
  };
  export const blogReducer = createReducer(
    initialState,
    on(loadBlogFailure, (state, { error }) => ({ ...state, error })),
    on(loadBlogSuccess,(state,{blog})=>({...state,blog:blog})),
    on(addBlogSuccess, (state, { blog }) => ({
      ...state,
      blog: state.blog
        ? state.blog.some((b) => b.id === blog.id)
          ? state.blog.map((b) => (b.id === blog.id ? { ...b, ...blog } : b))
          : [...state.blog, blog]
        : [blog],
    })),
    on(addBlogFailure, (state, { error }) => ({ ...state, error })),
    // on(deleteBlog, (state) => ({
    //   ...state,
    //   error: null,
    // })),
    on(deleteBlogSuccess, (state, { id }) => ({
      ...state,
      blog: state.blog.filter(blog => blog.id !== id),
    })),
    on(deleteBlogFailure, (state, { error }) => ({
      ...state,
      error,
    }))
  );