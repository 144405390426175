import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { MainserviceService } from "src/app/mainservice.service";
import { addBlog, addBlogFailure, addBlogSuccess, deleteBlog, deleteBlogFailure, deleteBlogSuccess, loadBlog, loadBlogFailure, loadBlogSuccess } from "../action/blog.actions";
import { catchError, map, mergeMap, of } from "rxjs";
import { Blog } from "../model/blog";





@Injectable()
export class BlogEffects{
    constructor(private actions$:Actions,private mainservice:MainserviceService){

    }

    loadAllBlogs$=createEffect(()=>
        this.actions$.pipe(
            ofType(loadBlog),
            mergeMap(()=>
                this.mainservice.viewAllBlogs().pipe(
                    map((blog:Blog[])=>loadBlogSuccess({blog})),
                    catchError(error=>of(loadBlogFailure({error:error.message})))
                )
            )
        )
    )

    addBlogs$=createEffect(()=>
        this.actions$.pipe(
            ofType(addBlog),
            mergeMap(action =>
                this.mainservice.saveBlog(action.blog).pipe(
                    map((res:Blog)=>addBlogSuccess({blog:res})),
                    catchError(error =>of(addBlogFailure({error:error.message})))
                )
            )
        )
    )
    deleteBlog$=createEffect(()=>
        this.actions$.pipe(
            ofType(deleteBlog),
            mergeMap(action=>
                this.mainservice.deleteBlog(action.id).pipe(
                    map((res:any)=>deleteBlogSuccess({id:action.id})),
                    catchError(error =>of(deleteBlogFailure({error:error.message})))
                )
            )
        )
    )
}