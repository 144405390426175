<app-navbar-style-one></app-navbar-style-one>

<div class="slider-area owl-carousel owl-theme">
    <div class="slider-item item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1>Explore Places In Suburb <b>Keep Choose Best</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1>Find Best The Restaurants <b>In Your Choose</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1>Discover The Exact Event <b>In Your Choose</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item item-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1>Find The Amazing Hotel <b>Keep Your Choose</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="banner-form-area banner-form-mt">
    <div class="container">
        <div class="form-list text-center">
            <ul>
                <li class="active"><i class="flaticon-curve-arrow"></i> PLACES</li>
                <li><i class="flaticon-curve-arrow"></i> RESTAURANTS</li>
                <li><i class="flaticon-curve-arrow"></i> EVENTS</li>
                <li><i class="flaticon-curve-arrow"></i> HOTELS</li>
            </ul>
        </div>

        <div class="banner-form border-radius">
            <form>
                <div class="row">
                    <div class="col-lg-2 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-place'></i>
                            <input class="form-control" type="text" placeholder="Location">
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-vision'></i>
                            <input class="form-control" type="text"  placeholder="What are looking for?">
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-category'></i>
                            <select class="form-control">
                                <option>All Categories</option>
                                <option>Restaurants</option>
                                <option>Events</option>
                                <option>Hotels</option>
                            </select>	
                        </div>
                    </div>

                    <div class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0">
                        <button type="submit" class="default-btn border-radius">Search <i class="flaticon-loupe"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<section class="category-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>The Categories</span>
            <h2>Use Quick Search By Category</h2>
        </div>

        <div class="row category-bg">
            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-bake"></i></a>
                    <a routerLink="/category"><h3>Restaurant</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-hotel"></i></a>
                    <a routerLink="/category"><h3>Hotel & Resort</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-barbell"></i></a>
                    <a routerLink="/category"><h3>Fitness Club</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-store"></i></a>
                    <a routerLink="/category"><h3>Shops & Stores</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-event"></i></a>
                    <a routerLink="/category"><h3>Events & Programme</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="category-card">
                    <a routerLink="/category"><i class="flaticon-flower"></i></a>
                    <a routerLink="/category"><h3>Beauty & Spa</h3></a>
                    <p>Lorem ipsum dolor sit amet consecte tempo quaerat voluptatem.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="place-list-area pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>Places Lists</span>
            <h2>The Latest Listings Added</h2>
            <p>Porem ipsum dolor sit ame consectetur adipisicing eli sed usmod tempor</p>
        </div>

        <div class="place-slider owl-carousel owl-theme pt-45">
            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list1.jpg" alt="Images"></a>
                    
                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>By, Alfred</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Restaurant</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Billiard Restaurant</h3></a> 
                    <p><i class="flaticon-cursor"></i> Dorente rio, 104, 00184 Crono, Canada</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.9</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list2.jpg" alt="Images"></a>
                    
                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile2.png" alt="Images">
                        <h3>By, Jaein</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"> <a routerLink="/listing-details">Beauty Shop</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Beauty Shop</h3></a> 
                    <p><i class="flaticon-cursor"></i> Davisto Laterani, 104, 00184 Roma, Italy</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">5.0</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list3.jpg" alt="Images"></a>
                    
                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile3.png" alt="Images">
                        <h3>By, Normand</h3>
                    </div>

                    <div class="place-status bg-color-blue">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a> 
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/listing-details"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/listing-details"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Fitness Club</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>Ridge Fitness Club</h3></a> 
                    <p><i class="flaticon-cursor"></i> Ke visto Onterio, 104, 6789 Rcona, usa</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.5</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list4.jpg" alt="Images"></a>
                    
                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile4.png" alt="Images">
                        <h3>By, Olfred</h3>
                    </div>

                    <div class="place-status bg-color-blue">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Hotel</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Hotel</h3></a> 
                    <p><i class="flaticon-cursor"></i> Ostapin, 134, 00184 Coventry, London</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.7</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list1.jpg" alt="Images"></a>
                    
                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>By, Alfred</h3>
                    </div>
                    
                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Restaurant</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Billiard Restaurant</h3></a> 
                    <p><i class="flaticon-cursor"></i> Dorente rio, 104, 00184 Crono, Canada</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.9</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="process-area process-bg pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Process</span>
            <h2>See How It Works</h2>
            <p>Porem ipsum dolor sit ame consectetur adipisicing incididunt </p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="process-card">
                    <i class="flaticon-position icon-bg"></i>
                    <h3>Find Interesting Place</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                    <div class="process-number">1</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="process-card">
                    <i class="flaticon-to-do-list icon-bg"></i>
                    <h3>Choose What To Do</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                    <div class="process-number active">2</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="process-card">
                    <i class="flaticon-box icon-bg"></i>
                    <h3>Find What You Want</h3>
                    <p>Lorem ipsum dolor sit amet, consetetur adipisicing elit, sed do eiusmod tempor  quam voluptatem.</p>
                    <div class="process-number">3</div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="city-area ptb-100"> 
    <div class="container">
        <div class="section-title text-center">
            <span>Cities</span>
            <h2>Explore Best Cities In Area</h2>
            <p>Porem ipsum dolor sit ame consectetur adipisicing eli sed usmod tempor incididunt </p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-7 col-md-7">
                <div class="city-item">
                    <a routerLink="/" class="city-img"><img src="assets/img/city-area/city-area1.jpg" alt="Images"></a>
                    <div class="city-content">
                        <i class="flaticon-buildings"></i>
                        <div class="city-text">
                            <a routerLink="/"><h3>New York</h3></a>
                            <p>Porem ipsum dolor sit ame consectetur adipisicing consectetused </p>
                        </div>
                    </div>
                    <h3 class="city-location">22 Locations</h3>
                </div>
            </div>

            <div class="col-lg-5 col-md-5">
                <div class="city-item">
                    <a routerLink="/" class="city-img"><img src="assets/img/city-area/city-area2.jpg" alt="Images"></a>
                    <div class="city-content">
                        <i class="flaticon-buildings"></i>
                        <div class="city-text">
                            <a routerLink="/"><h3>San Antonio</h3></a>
                            <p>Porem ipsum dolor sit ame consecte</p>
                        </div>
                    </div>
                    <h3 class="city-location">13 Locations</h3>
                </div>
            </div>

            <div class="col-lg-5 col-md-5">
                <div class="city-item">
                    <a routerLink="/" class="city-img"><img src="assets/img/city-area/city-area3.jpg" alt="Images"></a>
                    <div class="city-content">
                        <i class="flaticon-buildings"></i>
                        <div class="city-text">
                            <a routerLink="/"><h3>Fort Worth</h3></a>
                            <p>Porem ipsum dolor sit ame consecte</p>
                        </div>
                    </div>
                    <h3 class="city-location">17 Locations</h3>
                </div>
            </div>

            <div class="col-lg-7 col-md-7">
                <div class="city-item">
                    <a routerLink="/" class="city-img"><img src="assets/img/city-area/city-area4.jpg" alt="Images"></a>
                    <div class="city-content">
                        <i class="flaticon-buildings"></i>
                        <div class="city-text">
                            <a routerLink="/"><h3>Milwaukee</h3></a>
                            <p>Porem ipsum dolor sit ame consectetur adipisicing consectetused </p>
                        </div>
                    </div>
                    <h3 class="city-location">20 Locations</h3>
                </div>
            </div>

            <div class="col-lg-12 text-center mt-3"> 
                <a routerLink="/" class="default-btn border-radius">View all places <i class='bx bx-plus'></i></a>
            </div>
        </div>
    </div>
</section>

<div class="video-area video-area-bg">
    <div class="container">
        <div class="video-content">
            <h2>Are You  Ready To Start Your Journey?</h2>
            <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="play-btn"><i class='bx bx-play' ></i></a>
        </div>
    </div>
</div>

<div class="counter-area">
    <div class="container">
        <div class="counter-bg">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>1254</h3>
                        <span>New Visiters Every Week</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>23165</h3>
                        <span>New Visiters Every Day</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>4563</h3>
                        <span>Won Amazing Awards</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>880</h3>
                        <span>New Listing Every Week</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="place-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6">
                <div class="section-title mb-45">
                    <span>Desire Places</span>
                    <h2>Most Popular Places</h2>
                </div>
            </div>

            <div class="col-lg-5 col-md-6">
                <div class="place-btn">
                    <a routerLink="/listing" class="default-btn border-radius">Check out all places <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area1.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.9</a></li>
                            <li>
                                <span>23 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile.png" alt="Images">
                            <h3>By, Lowis Jelda</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> Dongo 184 Crono, Canada</span>
                        <a routerLink="/listing-details"><h3>Denisto Centin Restaurant </h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"> <a href="#">$560-890</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area2.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>30 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-blue">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile2.png" alt="Images">
                            <h3>By, Austin Deli</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 40 Square Plaza, NJ, USA</span>
                        <a routerLink="/listing-details"><h3>Iconic Cafe in Ontario</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a href="#">$500-700</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area3.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.9</a></li>
                            <li>
                                <span>23 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-heliotrope">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile3.png" alt="Images">
                            <h3>By, Polin Osto</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 34-42 Montgomery St , NY, USA</span>
                        <a routerLink="/listing-details"><h3>Strong body Gym</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/listing-details">$400-800</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area4.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.8</a></li>
                            <li>
                                <span>16 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a> 
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile4.png" alt="Images">
                            <h3>By, Debit Jhon</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 27th Brooklyn New York, USA</span>
                        <a routerLink="/listing-details"><h3>Family Convenience Store</h3></a> 
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a href="#">$560-890</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area5.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>16 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-blue">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile5.png" alt="Images">
                            <h3>By, Kelvin Sasi</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 56 Street Square Plaza, NJ, USA</span>
                        <a routerLink="/listing-details"><h3>Iconic Cafe in Onterio</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a href="#">$300-600</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area6.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>39 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-green">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile6.png" alt="Images">
                            <h3>By, Creiun Hitler</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 34-42 Montgomery St , NY, USA</span>
                        <a routerLink="/listing-details"><h3>Kentorin Hotel</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a href="#">$400-800</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="application-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="application-img">
                    <img src="assets/img/mobile.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7">
                <div class="application-content">
                    <div class="section-title">
                        <span>Download app</span>
                        <h2>Get More In Our Application Sit Back And Enjoy</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    </div>

                    <div class="application-btn">
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/google-play.svg" alt="svg">
                            <div class="btn-content">
                                <span>ANDROID APP ON</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DOWNLOAD ON THE</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="testimonial-area pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>Testimonials</span>
            <h2>What Our Clients Say</h2>
        </div>

        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-item testimonial-item-bg">
                <h3>Sanaik Tubi</h3>
                <span>Arbon Restaurant</span>
                <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>

            <div class="testimonial-item testimonial-item-bg">
                <h3>Oli Rubion</h3>
                <span>Rubion Inc</span>
                <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>

            <div class="testimonial-item testimonial-item-bg">
                <h3>Mashrof Ruin</h3>
                <span>Pice Cafe</span>
                <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>

            <div class="testimonial-item testimonial-item-bg">
                <h3>Sanaik Tubi</h3>
                <span>Arbon Restaurant</span>
                <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>

            <div class="testimonial-item testimonial-item-bg">
                <h3>Sanaik Tubi</h3>
                <span>Arbon Restaurant</span>
                <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <div class="testimonial-top">
                    <i class='bx bxs-quote-left'></i>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>