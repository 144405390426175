<footer class="footer-area footer-bg2">
    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <a routerLink="/" class="logo"><img src="assets/img/logo/vanuston-logo-white-transparent-footer.png" alt="Logo"></a>
                        <p>Vanuston Intelligence Private Limited</p>
                        <ul class="footer-contact-list">
                            <!-- <li>22/2, Bazaar Rd, Saidapet, Chennai, Tamil Nadu 600015</li> -->
                            <li><span>Phone :</span> <a href="tel:+919566124105">+919566124105</a></li>
                            <li><span>Email :</span> <a href="info@vanuston.com">info&#64;vanuston.com</a></li>
                        </ul>
                        <ul class="social-link">
                            <li><a href="https://www.facebook.com/people/Vanuston-Intelligence/100054521346463/" target="_blank" rel="noopener noreferrer"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://twitter.com/vanuston" target="_blank" rel="noopener noreferrer"><i>X</i></a></li>
                            <li><a href="https://www.instagram.com/medeilsoftware/" target="_blank" rel="noopener noreferrer"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="https://in.pinterest.com/vanuston1/" target="_blank" rel="noopener noreferrer"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="https://youtu.be/dDcM1iqrYn4?si=N8_XPNYjILh1nFEI" target="_blank" rel="noopener noreferrer"><i class='bx bxl-youtube'></i></a></li>
                            <!-- <li><a href="https://www.facebook.com/people/Vanuston-Intelligence/100054521346463/"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://twitter.com/vanuston"><i>X</i></a></li>
                            <li><a href="https://www.instagram.com/medeilsoftware/"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="https://in.pinterest.com/vanuston1/"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="https://youtu.be/dDcM1iqrYn4?si=N8_XPNYjILh1nFEI"><i class='bx bxl-youtube'></i></a></li> -->
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget pl-1">
                        <h3>PRODUCTS</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/medeil-pharmacy-software">Medeil</a></li>
                            <li><a routerLink="/medeil-cloud-pos">MedeilCloud POS</a></li>
                            <li><a routerLink="/medeil-cloud-hq">MedeilCloud HQ</a></li>
                            <li><a routerLink="/allotmed-medical-supply">Allotmed</a></li>

                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget pl-5">
                        <h3>VERTICALS</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/industry/pharmacy-management-software" >Pharmacy</a></li>
                            <li><a routerLink="/industry/optical-store-management" >Optical</a></li>
                            <li><a routerLink="/industry/beauty-software" >Beauty and cosmetics</a></li>
                            <li><a routerLink="/industry/veterinary-software" >Veterinary</a></li>
                            <li><a routerLink="/industry/medical-store-software" >Healthcare store</a></li>
                            <li><a routerLink="/industry/medical-device-software" >Medical device</a></li>
                            <li><a routerLink="/industry/agriculture-software" >Agrochemical</a></li>

                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget pl-1">
                        <h3>SOLUTION</h3>

                        <ul class="footer-list">
                            <li><a>Supply Chain Management</a></li>
                            <li><a>Manufacturer</a></li>
                            <li><a>Importer & Export Companies</a></li>
                            <li><a>C&F & Wholesaler</a></li>
                            <li><a>Pharmacy Chain stores / Brand stores</a></li>
                            <li><a>Independent Pharmacy stores</a></li>

                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copy-right-area copy-right-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="copy-right-list">
                    <ul>
                        <li><a href="https://www.vanuston.com/Terms-and-Conditions/" target="_blank" rel="noopener">Terms of Use</a></li>
                        <li><a href="https://www.vanuston.com/privacypolicy/" target="_blank" rel="noopener">Privacy Policy</a></li>
                        <!-- <li><a routerLink="/blog" target="_blank">Blog</a></li>  -->
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-8 col-md-8">
                <div class="copy-right-text text-end">
                    <p>© Pilar is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                </div>
            </div> -->
        </div>
    </div>
</div>