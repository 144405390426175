<app-navbar-style-two></app-navbar-style-two>
<div class="container blog-container">
  <div class="blog-area">
      <div class="blog-header text-center">
        <h2>{{ blog.title }}</h2>
      </div>
  </div>
  <div class="blog-content-area">
    <div class="blog-card">
      <div class="blog-image-container">
        <img [src]="getImageUrl(blog)" alt="{{ blog.title }}" class="blog-image" />
      </div>
      <div class="blog-content">
        <div class="blog-description-wrapper" [innerHTML]="blog.content"></div>
        <!-- <div class="blog-description" [innerHTML]="sanitizedContent"></div> -->
      </div>
    </div>
  </div>

  <div class="social-share">
    <!-- <a
      [href]="'https://www.facebook.com/dialog/share?display=popup&href=' + currentBlogUrl + '&redirect_uri=' + currentBlogUrl"
      target="_blank"
      class="social-icon facebook">
      <i class="fab fa-facebook-f"></i> Share on Facebook
    </a> -->
    <a
      [href]="'https://www.facebook.com/sharer/sharer.php?u=#'+currentBlogUrl"
      target="_blank"
      class="social-icon facebook">
      <i class="fab fa-facebook-f"></i> Share on Facebook
    </a>

    <a
      [href]="'https://twitter.com/intent/tweet?url=' + currentBlogUrl + '&text=' + blog.title"
      target="_blank"
      class="social-icon twitter">
      <i class="fa fa-x"></i>
       Share on Twitter
    </a>

    <a
      [href]="'https://www.linkedin.com/shareArticle?mini=true&url=' + currentBlogUrl"
      target="_blank"
      class="social-icon linkedin">
      <i class="fab fa-linkedin-in"></i> Share on LinkedIn
    </a>

    <a (click)="copyToClipboard(currentBlogUrl)"class="social-icon instagram">
      <i class="fab fa-instagram"></i> Copy Link for Instagram
    </a>
  </div>


</div>
<app-footer-style-two></app-footer-style-two>
