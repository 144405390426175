<app-navbar-style-two></app-navbar-style-two>

<div class="wrapper">
    <div class="">
      <div class="box mt-3">
        <form [formGroup]="enquiryForm" (ngSubmit)="savelead()">
          <h3 style="display: flex;justify-content: center;">Enquiry Form</h3>
          <div class="form-group">
            <label class="form-label">Name<span style="color: red;">*</span></label>
            <input type="text" class="form-control" required formControlName="leadname" />
          </div>
          <div class="form-group">
            <label class="form-label">Organisation</label>
            <input type="text" class="form-control" required formControlName="organisationname" />
          </div>
          <div class="form-group">
            <label class="form-label">Email <span style="color: red;">*</span></label>
            <input type="email" class="form-control" required formControlName="primaryemail" />
            <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label">Country <span style="color: red;">*</span></label><br>
            <!-- <select class="form-control nice-select" formControlName="countryname1" (change)="countrychange()">
              <option  value="country" select disabled value="0">----Select Country Name---</option>
              <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>

            </select> -->
            <ng-select class="custom-select" [items]="totalcountry" formControlName="countryname1"
                placeholder="Select a country" (change)="countrychange()">
            </ng-select>
        </div>
          <div class="form-group">
            <label class="form-label">Mobile <span style="color: red;">*</span></label>
            <div class="row">
              <div class="col-lg-3">
                <input type="text" class="mobileinput form-control" [(ngModel)]="dialcode" formControlName="phonedialcode" readonly>
              </div>
              <div class="col-lg-9">
                <input type="text" class="mobileinput form-control" maxlength="15" required formControlName="primaryphone"
               autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label">Description</label>
            <input type="text" class="form-control" required formControlName="address1" />
          </div>
          <label class="form-label mt-2">Enter captcha</label>
          <div class="input-group">
            <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
              (change)="checkCaptcha()" />
            <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
              [(ngModel)]="captcha" />
            <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
          </div>
          <div *ngIf="ecaptcha">
            <p style="color: red">{{ errorcaptcha }}</p>
          </div>

          <div class="input-group mt-2">
            <input type="checkbox" class="" name="a" formControlName="checkbox"  required />
            <p class="text-secondary terms">
              I Accept Terms & Conditions & Privacy Policy
            </p>
          </div>
          <!-- <input type="button" class="mt-5 button" type="submit" value="Submit"> -->
          <div class="mt-2 submit" *ngIf="!showElement">
            <button type="submit" class="button">Submit</button>
          </div>
          <div *ngIf="showElement" align="center">
            <img height="100px" width="200px" src="https://cdn.dribbble.com/users/1018473/screenshots/3963419/loader.gif" alt="">
          </div>
        </form>
      </div>
    </div>
  </div>
<app-footer-style-two></app-footer-style-two>