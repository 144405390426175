<app-navbar-style-one></app-navbar-style-one>
<app-navbar-style-two></app-navbar-style-two>
<div class="container-fluid">
  <div class="row productcontainer">
    <div class="col-lg-12 top-style">
      <h2>Products & Free Trial</h2>
    </div>
   
  </div>
  
</div>

<div class="container">
 
      <div class="banner-form mt-4">
       
            <div class="row">
           
                <div class="col-lg-11">
                  <div class="form-group">
                    <i class='flaticon-category'></i>
                    <select class="form-control">
                      <option selected disabled value="0">Select Your Business Type</option>
                      <option value="1">Pharmacy</option>
                      <option value="2">Veterinary</option>
                      <option value="3">Healthcare store</option>
                      <option value="4">Agrochemical</option>
                      <option value="5">Medical devicet</option>
                      <option value="6">Optical</option>
                      <option value="7">Beauty and Cosmetics</option>
                    </select>
                  </div>
              </div>

            </div>
      
    </div>
     
</div>
<section class="place-list-three ">
  <div class="container">
      <div class="section-title gradient-text mt-4 mb-4">

          <h2>Pharmacy Software Products</h2>

      </div>
      <div class="row">
         <div class="col-lg-8" *ngIf="medeil">
          <div class="place-list-item">
            <div class="content">
                <a routerLink="/listing-details"><h3>Medeil(Standalone)</h3></a>
                <p><i class="flaticon-cursor"></i>Medeil offers a free edition as well as a free trial for the standalone edition</p>
                <ul class="place-rating">
                    <li><a routerLink="/">4.9</a></li>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star icon-color'></i>
                    </li>
                </ul>
                <button type="button" class="default-btn1" style="margin-right: 15px;" routerLink="/download"><i class="fas fa-download" style="margin-right: 15px;"></i>Free Edition</button>
                <button type="button" class="default-btn-free" routerLink="/download"><i class="fas fa-download" style="margin-right: 15px;"></i>10 Days Free Trial</button>               
            </div>
          </div>
         </div>
         <div class="col-lg-4" *ngIf="medeilpos">
          <div class="place-list-item">
            <div class="content">
                <a routerLink="/listing-details"><h3>Medeil Cloud POS</h3></a>
                <p><i class="flaticon-cursor"></i> 14 Days Free Trial</p>
                <ul class="place-rating">
                    <li><a routerLink="/">4.7</a></li>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star icon-color'></i>
                    </li>
                </ul>
                <div class="row">
                  <div class="col-lg-6">
                    <a href="https://secure.medeil.io/medeilpos/eng/23pos/#/login" target="_blank" rel="noopener">
                      <button type="button" class="default-btn1" style="margin-right: 15px;">Login</button>
                    </a>  
                  </div>
                  <div class="col-lg-6">
                    <a href="https://secure.medeil.io/medeilpos/eng/23pos/#/usersignup" target="_blank" rel="noopener">
                      <button type="button" class="default-btn-free" >Free Trial</button>
                    </a>
                  </div>
                </div>
             
             
                              
            </div>
          </div>
         </div>
         <div class="col-lg-4" *ngIf="medeilhq">
          <div class="place-list-item">
            <div class="content">
                <a routerLink="/listing-details"><h3>Medeil Cloud HQ</h3></a>
                <p><i class="flaticon-cursor"></i> 7 Days Free Trial</p>
                <ul class="place-rating">
                    <li><a routerLink="/">4.3</a></li>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star icon-color'></i>
                    </li>
                </ul>
                <!-- <button type="button" class="default-btn1" style="margin-right: 15px;">Login Now</button> -->
                <button type="button" class="default-btn-free" (click)="trynowbtn('MedeilCloud HQ')">Demo Request</button>               
            </div>
          </div>
         </div>
         <div class="col-lg-4" *ngIf="hosbay">
          <div class="place-list-item">
            <div class="content">
                <a routerLink="/listing-details"><h3>Hosbay</h3></a>
                <p><i class="flaticon-cursor"></i> 7 Days Free Trial</p>
                <ul class="place-rating">
                    <li><a routerLink="/">4.5</a></li>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star icon-color'></i>
                    </li>
                </ul>
                <!-- <button type="button" class="default-btn1" style="margin-right: 15px;">Login Now</button> -->
                <button type="button" class="default-btn-free"  (click)="trynowbtn('Hosbay')">Demo Request</button>               
            </div>
          </div>
         </div>
         <div class="col-lg-4" *ngIf="allotmed">
          <div class="place-list-item">
            <div class="content">
                <a routerLink="/listing-details"><h3>Allotmed</h3></a>
                <p><i class="flaticon-cursor"></i> 10  Days Free Trial</p>
                <ul class="place-rating">
                    <li><a routerLink="/">4.3</a></li>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star icon-color'></i>
                    </li>
                </ul>
                <!-- <button type="button" class="default-btn1" style="margin-right: 15px;">Login Now</button> -->
                <button type="button" class="default-btn-free" (click)="trynowbtn('Allotmed')">Demo Request</button>               
            </div>
          </div>
         </div>
      </div>
    
  </div>
</section>
<ngx-smart-modal #myModal identifier="myModal" [closable]="false">

  <div class="custom-modal">
      <button class="close-btn" (click)="closepopup()">X</button>
      <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
      </div>
      <div class="modal-body">
       <form [formGroup]="enquiryForm">
         <div class="form-group">
           <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
           <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
         </div>
         <div class="form-group">
           <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
           <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
         </div>
         <div class="form-group">
           <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
           <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
           <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
            <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
            <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
          </div>
         </div>
         <div class="form-group">
           <label for="message-text" class="col-form-label"><strong>Country</strong></label>
           <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
           <select class="form-control"  formControlName="countryname1" style="font-size: 13px;" (change)="countrychange()">
            <!-- (change)="countrychange()" -->
            <option select disabled value="0" >---Select Country---</option>
            <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>

          </select>
         </div>
         <div class="form-group">
           <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
           <div class="row">
             <div class="col-lg-3">
               <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
             </div>
             <div class="col-lg-9">
               <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
               autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
             </div>
           </div>
          
         </div>
         <div class="form-group">
           <label for="message-text" class="col-form-label"><strong>Description</strong></label>
           <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
         </div>
         <!-- <div class="form-group">
           <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
           <input type="text" name="rec" class="form-control" id="recipient-name">
         </div> -->
         <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
         <div class="input-group">
            <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
             (change)="checkCaptcha()"/>
            <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
            [(ngModel)]="captcha"/>
            <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
          </div>
          <div *ngIf="ecaptcha">
            <p style="color: red">{{ errorcaptcha }}</p>
          </div>
         <div class="form-group">
          
               <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                   <input type="checkbox" checked="checked" formControlName="checkbox">
                   <span class="checkmark"></span>
               </label>
         </div>
         <div  align="center" *ngIf="showElement">
          <img height="50px" width="50px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
        </div>
         <div class="btn-group mt-3" >
           <button type="button" class="enquiry-btn" (click)="savesignup()">Submit</button>

         </div>
        
         
       </form>
     </div>
  </div>
 
</ngx-smart-modal>
<div class="container">
  <div class="section-title gradient-text mt-2 mb-2" >
     
      <h2>Frequently Asked Questions</h2>
  </div>
  <div class="row pt-4">
      <div class="col-lg-6 col-md-12">
          <div class="faq-item">
              <h3>Q: What is the Free Trial Offer for Pharmacies?</h3>
              <p><strong>A:</strong>
                Learn about our free trial for pharmacies and how it can benefit your business. We have several options in pharmacy space

                Medeil - Free edition 
                Medeil - 10 days trial standard edition
                Medeilcloud POS - 14 Days free trial 
                
                </p>
          </div>
      </div>
  
      <div class="col-lg-6 col-md-12">
          <div class="faq-item">
              <h3>Q: How Can Optical Stores Benefit from the Free Trial?</h3>
              <p><strong>A:</strong> Optical inventory management software needs to be the latest update on spectacle store demand, including spectacle RX Process and electronic prescriptions. The optical ordering process is built into the Medeilcloud POS, providing a 14-day trial edition to explore the entire optical ERP process in the cloud application.</p>
          </div>
      </div>
  
      <div class="col-lg-6 col-md-12">
          <div class="faq-item">
              <h3>Q: Is the Free Trial Available for Veterinary Software?</h3>
              <p><strong>A:</strong> Yes available in medeilcoud pos trial edition to explore your veterinary software trial edition free for 14 days anyone can sign up and explore the process. Find out how our free trial can support veterinary businesses.</p>
          </div>
      </div>
  
      <div class="col-lg-6 col-md-12">
          <div class="faq-item">
              <h3>Q: What Does the Free Trial Offer for Agriculture Stores?</h3>
              <p><strong>A:</strong> Do you want to manage your entire agriculture business with a single cloud application? If yes, your right choice is Medeilcloud POS. It helps manage your agro stores, agro-chemical stores, dairy product store including ice cream parlour, etc. Learn about the features included in our free trial for agriculture stores.</p>
          </div>
      </div>
  </div>
  

</div>
<app-footer-style-two></app-footer-style-two>
 

  
  



