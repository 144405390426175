<app-navbar-style-two></app-navbar-style-two>

<app-allotmednavbar></app-allotmednavbar>


<section class="pricing-area pt-4 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <!-- <span>Pricing</span> -->
            <h2>Our Pricing Tables</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                    <div class="content">
                        <!-- <i class="flaticon-deal"></i> -->
                        <h3>Free Edition</h3>
                        <!-- <h2><sup>$</sup>60</h2> -->
                        <!-- <span>Only this week</span> -->
                    </div>
                    <ul>
                        <li>Independent distributor/wholesaler</li>
                        <li>Customer manufacturer</li>
                        <li>vendor management</li>
                        <li>Billing and invoicing</li>
                        <li>No sales force integrated</li>
                        <li>Delivery management</li>
                        <li>Only forward logistics</li>
                        <li>Limited records (max 2000)</li>
                        <li>Android supported</li>
                        <li>No payment integrated</li>
                    </ul>
                    <a class="default-btn" (click)="trynowbtn('Allotmed pricing Free Edition Edition')">Start Trial</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                    <div class="content">
                        <!-- <i class="flaticon-megaphone"></i> -->
                        <h3>Growth</h3>
                        <!-- <h2><sup>$</sup>105</h2>
                        <span>Only this month</span> -->
                    </div>
                    <ul>
                        <li>Independent distributor/wholesaler</li>
                        <li>Customer manufacturer</li>
                        <li>vendor management</li>
                        <li>Billing and invoicing</li>
                        <li>sales force integrated</li>
                        <li>Delivery management</li>
                        <li>Forward and reverse logistics</li>
                        <li>Unlimited records</li>
                        <li>Android supported</li>
                        <li>payment integrated</li>
                    </ul>
                    <a class=" default-btn" (click)="trynowbtn('Allotmed pricing Growth Edition')">Start Trial</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card">
                    <div class="content">
                        <!-- <i class="flaticon-diamond"></i> -->
                        <h3>Professional</h3>
                        <!-- <h2><sup>$</sup>134</h2>
                        <span>Only this year</span> -->
                    </div>
                    <ul>
                        <li>chain of distribution</li>
                        <li>Customer manufacturer</li>
                        <li>vendor management</li>
                        <li>Multiple distribution invoice</li>
                        <li>sales force integrated</li>
                        <li>Delivery management</li>
                        <li>Forward and reverse logistics</li>
                        <li>Unlimited records</li>
                        <li>Android supported</li>
                        <li>payment integrated</li>
                    </ul>
                    <a  class="default-btn" (click)="trynowbtn('Allotmed pricing Professional Edition')">Start Trial</a>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-smart-modal #myModal identifier="myModal" [closable]="false">

    <div class="custom-modal">
        <button class="close-btn" (click)="closepopup()">X</button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Enquiry Form</h5>
        </div>
        <div class="modal-body">
         <form [formGroup]="enquiryForm">
           <div class="form-group">
             <label for="recipient-name" class="col-form-label"><strong>Name</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="leadname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Organisation</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="organizationname" required>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Email</strong><span class="star">*</span></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="primaryemail" required>
             <div *ngIf="enquiryForm.controls.primaryemail.invalid && (enquiryForm.controls.primaryemail.dirty || enquiryForm.controls.primaryemail.touched)">
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.required" style="color: red;">Email is required.</div>
              <div *ngIf="enquiryForm.controls.primaryemail.errors?.pattern" style="color: red;">Invalid email format.</div>
            </div>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Country</strong></label>
             <!-- <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="countryname1"> -->
             <select class="form-control"  formControlName="countryname1" style="font-size: 13px;" (change)="countrychange()">
              <!-- (change)="countrychange()" -->
              <option select disabled value="0" >---Select Country---</option>
              <option  value="{{ country[1] }}" *ngFor="let country of totalcountry">{{country[1]}}</option>
  
            </select>
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Mobile</strong><span class="star">*</span></label>
             <div class="row">
               <div class="col-lg-3">
                 <input type="text" name="rec" readonly class="form-control mobile-field" [(ngModel)]="dialcode" id="recipient-name" formControlName="phonedialcode">
               </div>
               <div class="col-lg-9">
                 <input type="text" name="rec" class="form-control mobile-field" maxlength="15" id="recipient-name" formControlName="primaryphone"
                 autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
               </div>
             </div>
            
           </div>
           <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Description</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name" formControlName="address1">
           </div>
           <!-- <div class="form-group">
             <label for="message-text" class="col-form-label"><strong>Enter captcha</strong></label>
             <input type="text" name="rec" class="form-control" id="recipient-name">
           </div> -->
           <label for="message-text" class="col-form-label captchalabel"><strong>Enter captcha</strong></label>
           <div class="input-group">
              <input type="text" class="form-control ecaptcha" required formControlName="enteredcaptcha" required="required"
               (change)="checkCaptcha()"/>
              <input type="text" class="form-control gcaptcha" formControlName="generatedcaptcha" readonly
              [(ngModel)]="captcha"/>
              <i class="fa fa-arrows-rotate refresh" (click)="generatecaptcha()"></i>
            </div>
            <div *ngIf="ecaptcha">
              <p style="color: red">{{ errorcaptcha }}</p>
            </div>
           <div class="form-group">
            
                 <label class="container1 mt-3">I Accept Terms & Conditions & Privacy Policy
                     <input type="checkbox" checked="checked" formControlName="checkbox">
                     <span class="checkmark"></span>
                 </label>
           </div>
             <div  align="center" *ngIf="showElement">
                <img height="50px" width="50px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
              </div>
          
           <div class="btn-group mt-3">
          
          
             <button type="button" class="enquiry-btn" (click)="savesignup()">Submit</button>

           </div>
          
           
         </form>
       </div>
    </div>
   
</ngx-smart-modal>
<div class="container">
    <div class="section-title text-center">
        <!-- <span>Pricing</span> -->
        <h2>Can’t decide which plan is right for you?</h2>

    </div>
    <div class="text-center">
        <button class="comparison" (click)="toggleComparison()">Click Here for Comaparision table</button>
    </div>
    <div class="enquery" *ngIf="opencomparison">
        <form [formGroup]="comparisonform" class="form-container">
           
                <input type="text" formControlName="leadname" class="form-control" placeholder="Enter Name">
                <input type="number" formControlName="moblieno" class="form-control" placeholder="Enter Mobile">
                <input type="text" formControlName="emailid" class="form-control" placeholder="Enter Email">
                <div *ngIf="comparisonform.controls.emailid.invalid && (comparisonform.controls.emailid.dirty || comparisonform.controls.emailid.touched)">
                    <!-- <div *ngIf="comparisonform.controls.emailid.errors?.required" style="color: red;">Email is required.</div> -->
                    <div *ngIf="comparisonform.controls.emailid.errors?.pattern" style="color: red;">Invalid email format.</div>
                  </div>
                  <div  align="center" *ngIf="showgif">
                    <img height="100px" width="100px" src="https://medeilpos.blob.core.windows.net/posimage/loading.gif" alt="gif" loading="lazy">
                  </div>
                <div class="btn-contain">
                    <button class="button-sub" role="button" (click)="savectable()">Submit</button>
                </div>
              


        </form>
    </div>
    
</div>
<div class="container mt-4">
    <div class="section-title text-center">
        <!-- <span>Pricing</span> -->
        <h2>Frequently Asked Questions</h2>
    </div>
    <div class="row pt-45">
        <div class="col-lg-6">
            <div class="faq-item">
                <h4>Q: What is Allotmed Distribution Management Software?</h4>
                <p><strong>A:</strong>
                    Learn about the benefits and features of distribution management software Mainly managing entire Retail customers, handling vendors and manufacturers, C&F Agencies, managing inventory and taxation billing finally integrated accounts.</p>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="faq-item">
                <h4>Q: How Can Allotmed Distribution Management Software Help?</h4>
                <p><strong>A:</strong>Discover how our Allotmed software streamlines operations and boosts efficiency in your wholesale business with streamline the operations and enhance your return of investment through cloud and mobile technology.</p>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="faq-item">
                <h4>Q: Is Allotmed - Distribution Management Software Scalable?</h4>
                <p><strong>A:</strong>
                    Find out if our Allotmed software can grow with your business and adapt to changes, we have independent distributors, chain of distribution companies, even support digital distribution channel.</p>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="faq-item">
                <h4>Q: Which are the industries allotmed support?</h4>
                <p><strong>A:</strong>We support medical distribution, optical supply chain, agricultural product supply chain management process, veterinary SCM, etc.</p>
            </div>
        </div>
    </div>

</div>
<app-footer-style-two></app-footer-style-two>