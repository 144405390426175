import { Component } from '@angular/core';

@Component({
  selector: 'app-medeilnavbar',
  templateUrl: './medeilnavbar.component.html',
  styleUrls: ['./medeilnavbar.component.scss']
})
export class MedeilnavbarComponent {

}
