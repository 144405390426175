<!-- <app-navbar-style-one></app-navbar-style-one> -->
<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>

<div class="contact-area">
    <div class="container">
        <div class="contact-max">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-position"></i>
                        <h3>54 Hegmann Uninuo Apt. 890, New</h3>
                        <h3>York, NY 10018, United States.</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="contact-card">
                        <i class="flaticon-email"></i>
                        <h3><a href="mailto:info@pilar.com">Email:info&#64;pilar.com</a></h3>
                        <h3><a href="mailto:support@pilar.com">Email:support&#64;pilar.com</a></h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="contact-card">
                        <i class="flaticon-to-do-list"></i>
                        <h3><a href="tel:+(06)–5432134567">+(06) – 543 213 4567</a></h3>
                        <h3><a href="tel:+(05)–25489073901">+(05) – 254 8907 3901</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d735257.9205168115!2d-75.33502765384733!3d40.30050562979537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2a07dcfd9d0ce20e!2sUs%20Rt%2040%20%40%20Governors%20Sq%20Plaza%202!5e0!3m2!1sen!2sbd!4v1596709319151!5m2!1sen!2sbd"></iframe>

        <div class="contact-wrap">
            <div class="contact-form">
                <span>SEND MESSAGE</span>
                <h2>Contact With Us</h2>

                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name*">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="email" name="email" id="email" class="form-control" required placeholder="E-mail*">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-phone'></i>
                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <i class='bx bx-file'></i>
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <i class='bx bx-envelope'></i>
                                <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn border-radius">Send Message <i class='bx bx-plus'></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>