
<mat-dialog-content>
  <form [formGroup]="blogForm" class="mt-4">
    <mat-card class="blog-card">
      <div class="gradient-title">Add Blog</div>
      <!-- <mat-card-content> -->

        <!-- Blog Title Field -->
        <div class="input-container">
          <label for="title">Blog Title</label>
          <input type="text" formControlName="title" id="title" name="title" required />
        </div>

        <div class="input-container">
          <label for="image">Add Header Image</label>
          <div class="custom-file-input">
            <label for="image" class="custom-file-label">Choose Image(750 x 450)</label>
            <input type="file" (change)="onImageChange($event)" formControlName="image" id="image" accept="image/*"  name="image" />
          </div>
        </div>
          <div class="input-container">
            <label for="title">SEO Title (Between 50–60 Characters)</label>
            <input type="text" formControlName="seotitle" id="subtitle" name="subtitle" />
          </div>
          <div class="input-container">
            <label for="title">SEO Description (Between 150–160 characters)</label>
            <input type="text" formControlName="seodescription" id="subtitle" name="subtitle" />
          </div>
          <div class="input-container">
            <label for="title">SEO Keywords (Between 3–5 primary keywords)</label>
            <input type="text" formControlName="seokeyword" id="subtitle" name="subtitle" />
          </div>
        <div class="quill-editor-wrapper">
          <label for="title">Add Content</label>
          <quill-editor formControlName="content" style="min-width: 300px; width: 100%;"></quill-editor>
        </div>
        <!-- Submit Button (Save) -->
        <div class="submit-button">
          <button mat-raised-button color="primary" type="submit" (click)="onSubmit()" class="gradient-button">
            Save
          </button>
        </div>

      <!-- </mat-card-content> -->
    </mat-card>
  </form>
</mat-dialog-content>

  <!-- <mat-dialog-actions>
    <button mat-button (click)="closeDialog()">Cancel</button>
    <button mat-button color="primary" (click)="onSave()">Save</button>
  </mat-dialog-actions> -->
