import { Component } from '@angular/core';

@Component({
  selector: 'app-allotmednavbar',
  templateUrl: './allotmednavbar.component.html',
  styleUrls: ['./allotmednavbar.component.scss']
})
export class AllotmednavbarComponent {

}
