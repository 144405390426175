import { Component,OnInit,TemplateRef,ViewChild} from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormGroup } from '@angular/forms';
import { FormBuilder,Validators } from '@angular/forms';
import { MainserviceService } from 'src/app/mainservice.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as ngxSeo from 'ngx-seo';
@Component({
  selector: 'app-hosbay',
  templateUrl: './hosbay.component.html',
  styleUrls: ['./hosbay.component.scss'],
   providers:[MainserviceService,DatePipe]
})
export class HosbayComponent {
   enquiryForm:FormGroup;
    totalcountry:any=[];
    captcha:any
    errorcaptcha:any
    ecaptcha:boolean=false;
    dialcode:any;
    showElement: boolean = false;
    showElementsave: boolean = false;
    @ViewChild('picker') picker!: MatDatepicker<Date>;
    @ViewChild('inputField') inputField!: any;
    currentTime: Date;
    timeSlots:any=[];
    timezone:any;
    appointment_time:boolean=false;
    selectedTime: string | null = null;
    leads:any;
  constructor(private ngxsmartservice:NgxSmartModalService,private fb:FormBuilder,private router:Router,private seoService: ngxSeo.SeoSocialShareService,
    private mainservice:MainserviceService,private datePipe: DatePipe,private title:Title,private meta:Meta,private jsonLdService: ngxSeo.JsonLdService){
      this.setSchemaData();
      this.setSeoMeta();
      // this.title.setTitle("Transform Your Pharmacy with Medeil Software | Free Download");

      // Add meta tags dynamically
      // this.meta.addTag({ name: 'description', content: 'Discover how Medeil can revolutionize your pharmacy operations. Download now! Start freely access our best pharmacy software' });
      // this.meta.addTag({ name: 'keywords', content: 'Medeil, Pharmacy Management System, Pharmacy Billing Software, Medical Shop Software, Pharmacy Inventory Software' });
    this.timezone=sessionStorage.getItem('timezone');
    this.enquiryForm=this.fb.group({
        leadname: ['',[ Validators.required]],
        organizationname: ['',[Validators.required]],
        leadsourceid: ['',[]],
        leadsource: ['medeil website',[]],
        sourceRef1: ['',[]],
        sourceRef2: ['',[]],
        description: ['',[ Validators.required]],
        primaryphone: ['',[Validators.required]],
        primaryemail: ['',[Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
        address1: ['',[]],
        address2: ['',[]],
        country: [sessionStorage.getItem('country'),[],[]],
        countryname1: ['0',[]],
        state: [sessionStorage.getItem('state'),[]],
        city: [sessionStorage.getItem('city'),[]],
        enteredcaptcha: ['',[]],
        generatedcaptcha: ['',[]],
        phonedialcode:['',[]],
        checkbox:[false,[]],
        appfrmtime:['',[]],
        apptotime:['',[]],
        appointmentdate:['',[]],
      })
    }
    selectedclass: number = 1;
    ngOnInit(): void {
      // this.navigateToHomeWithQueryParams();
      this.mainservice.generatecaptcha();
      this.captcha = localStorage.getItem('captcha')
       // ----country list--
       this.mainservice.getCountryList().subscribe(data=>{
         console.log(data);
         this.totalcountry=data;
       })
    }
      setSeoMeta() {
        const seoData: ngxSeo.SeoSocialShareData = {
          title: 'Free Pharmacy Software for Efficient Pharmacy Management',
          description: 'Discover Medeil Standalone Pharmacy Software – the perfect solution for independent pharmacies. Manage inventory, billing, and prescriptions with ease.',
          image: 'https://www.medeil.com/assets/cloud-pos-software.jpg',
          url: 'https://www.medeil.com/medeil-cloud-pos',
          keywords: 'free pharmacy software, pharmacy management software, pharmacy billing software, inventory management for pharmacies'
        };

        this.seoService.setData(seoData);
      }
    setSchemaData() {
      const schemaData = {
        "@context": "https://schema.org",
        "@type": "SoftwareApplication",
        "name": "Medeil Free Pharmacy Software",
        "headline": "Free Pharmacy Software for Efficient Pharmacy Management",
        "description": "Discover Medeil Standalone Pharmacy Software – the perfect solution for independent pharmacies. Manage inventory, billing, and prescriptions with ease.",
        "url": "https://www.medeil.com/medeil-pharmacy-software",
        "image": "https://www.medeil.com/assets/images/medeil.png",
        "operatingSystem": "Windows, macOS",
        "applicationCategory": "BusinessApplication",
        "offers": {
          "@type": "Offer",
          "price": "0",
          "priceCurrency": "USD",
          "availability": "https://schema.org/InStock"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Medeil",
          "url": "https://www.medeil.com"
        },
        "keywords": "free pharmacy software, pharmacy management software, pharmacy billing software, inventory management for pharmacies"
      };

      this.jsonLdService.setData(schemaData);
    }
    selectclass(index: number): void {
      this.selectedclass = index;
    }
    appointment(datasource:any){
      this.enquiryForm.get('sourceRef1').setValue(datasource);
      this.ngxsmartservice.open('enquirypos');
      // this.ngxsmartservice.open('appointmentform');
    }
    closeModal(): void {
      this.ngxsmartservice.close('enquirypos');
      this.ngxsmartservice.close('appointmentform');
      this.enquiryForm.reset();
      this.enquiryForm.get('countryname1').setValue('0');
      this.generatecaptcha();
    }
    countrychange(){

      this.mainservice.getMobileCode(this.enquiryForm.get('countryname1').value).subscribe((data)=>{
        //console.log(data);
        this.dialcode=data.Dailingcode;
        console.log(this.dialcode);
       // this.registerForm.get('phonedialcode').patchValue(this.dialcode[1]);
      })
    }
    checkCaptcha(){

      var usercaptcha = this.enquiryForm.get('enteredcaptcha').value

      if (this.captcha == usercaptcha) {
        this.ecaptcha = false

      } else {

        this.errorcaptcha = 'Enter correct Captcha'
        this.ecaptcha = true
      }
    }
    generatecaptcha(){
      this.mainservice.generatecaptcha()
      this.captcha = localStorage.getItem('captcha')
    }
    submitenquery(){
      let valid=this.validation();
      if(valid){
        this.ngxsmartservice.close('enquirypos');
        this.ngxsmartservice.open('appointmentform');
        this.picker.open();
        this.inputField.focus();
      }

    }

    validation(){
      let name=this.enquiryForm.get('leadname').value
      let organizationname=this.enquiryForm.get('organizationname').value
      let primaryemail=this.enquiryForm.get('primaryemail').value
      let primaryphone=this.enquiryForm.get('primaryphone').value
      let captcha=this.enquiryForm.get('enteredcaptcha').value
      let checkbox=this.enquiryForm.get('checkbox').value

      // var usercaptcha = this.registerForm.get('enteredcaptcha').value
      if(!name){
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Please Enter Name',
          showConfirmButton: false,
          timer: 1500
        })
        return false;
      }
      else if(!organizationname){
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Please Enter Organization Name',
          showConfirmButton: false,
          timer: 1500
        })
        return false;
      }
      else if(!primaryemail){
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Please Enter Your Email',
          showConfirmButton: false,
          timer: 1500
        })
        return false;
      }
      else if(!primaryphone){
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Please Enter Your Mobile Number',
          showConfirmButton: false,
          timer: 1500
        })
        return false;
      }
      else if(!captcha){
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Please Enter Captcha',
          showConfirmButton: false,
          timer: 1500
        })
        return false;
      }
      else if(captcha != this.captcha){
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Please Enter Correct Captcha',
          showConfirmButton: false,
          timer: 1500
        })
        return false;
      }
      else if(checkbox==false){
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Please Select Accept & Terms condition',
          showConfirmButton: false,
          timer: 1500
        })
        return false;
      }


      return true
    }
    slectdate(event: any){
      this.showElement=true;
      const selectedDate = event.value;
      const finalDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
      this.enquiryForm.get('appointmentdate').setValue(finalDate);
     this.currentTime = new Date();

     let map={
      "timezone":this.timezone,
      "orginaltimezone":this.currentTime
    }
    this.mainservice.gettimeSlots(map).subscribe(data=>{
      this.timeSlots = data[0].map((time:any)=> {
        const [hours, minutes] = time.split(':');
        const hours12 = ((parseInt(hours) + 11) % 12) + 1;
        const ampm = parseInt(hours) < 12 ? 'AM' : 'PM';
        return `${hours12}:${minutes} ${ampm}`;
      });
      this.showElement=false;
      console.log(this.timeSlots);
    })

      this.appointment_time=true;
    }
    selectTime(time: string): void {
      const currentTime = new Date();
      const formattedTime = this.datePipe.transform(currentTime, 'hh:mm:ss a');
      console.log(formattedTime);
      this.selectedTime = time;
      // this.enquiryForm.get('apptotime').setValue(time);
      this.enquiryForm.get('appfrmtime').setValue(time);

    }
    savelead(){
      this.showElementsave=true;



      // if (valid) {

        let phone = this.enquiryForm.get('primaryphone').value

        let mobileno=this.enquiryForm.get('phonedialcode').value+this.enquiryForm.get('primaryphone').value
        this.enquiryForm.get('primaryphone').setValue(mobileno)

         console.log('form',this.enquiryForm.value);

    this.mainservice.savelead(this.enquiryForm.value).subscribe((data: any) => {
      this.leads = data
      // console.log("this.leads",this.leads)
      if (data) {
        // this.enquiryForm.reset()
        this.showElementsave=false;
        // window.location.href= 'https://www.vanuston.com/thank-u-vanuston'
        this.enquiryForm.reset();

        // this.submitenquery();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Data saved successfully',
          text: 'We will contact you shortly.',
          showConfirmButton: false,
          timer: 4000
        });
        this.ngxsmartservice.close('enquirypos');
        this.ngxsmartservice.close('appointmentform');
        this.enquiryForm.reset();
      }
       else{
        this.enquiryForm.get('primaryphone').setValue(phone);
        // this.showElement=false;
      }
      this.enquiryForm.get('countryname1').setValue('0');
      this.generatecaptcha();
    })

    }
    closepopup(){
      this.ngxsmartservice.close('myModal');
      this.enquiryForm.reset();

      this.enquiryForm.get('countryname1').setValue('0');
      this.generatecaptcha();
    }
    closepopup1(){
      this.ngxsmartservice.close('myModal1');
      this.enquiryForm.reset();

      this.enquiryForm.get('countryname1').setValue('0');
      this.generatecaptcha();
    }
    trynowbtn(data:any){
      // this.enquiryForm.reset();
      this.enquiryForm.get('sourceRef1').setValue(data);
      this.ngxsmartservice.open('myModal');

    }
    savesignup(){


      let valid=this.validation();

      if (valid) {
        this.showElement=true;
        let phone = this.enquiryForm.get('primaryphone').value

        let mobileno=this.enquiryForm.get('phonedialcode').value+this.enquiryForm.get('primaryphone').value
        this.enquiryForm.get('primaryphone').setValue(mobileno)

         console.log('form',this.enquiryForm.value);

    this.mainservice.savelead(this.enquiryForm.value).subscribe((data: any) => {
      this.leads = data
      // console.log("this.leads",this.leads)
      if (data) {
        // this.enquiryForm.reset()
        // window.location.href= 'https://www.vanuston.com/thank-u-vanuston'
        this.enquiryForm.reset();
        this.showElement=false;
        // this.submitenquery();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Data saved successfully',
          text: 'We will contact you shortly.',
          showConfirmButton: false,
          timer: 4000
        });
        this.ngxsmartservice.close('myModal');
      }
       else{
        this.enquiryForm.get('primaryphone').setValue(phone);
        this.showElement=false;
      }
      this.enquiryForm.get('countryname1').setValue('0');
      this.generatecaptcha();
    })

      }
      else{
        this.showElement=false;
      }


    }
    broucher(data:any){
      this.enquiryForm.get('sourceRef1').setValue(data);
      this.ngxsmartservice.open('myModal1');
    }
    savebroucher() {
      let valid = this.validation();


      if (valid) {
        this.showElementsave = true;
        let phone = this.enquiryForm.get('primaryphone').value;
        let mobileno = this.enquiryForm.get('phonedialcode').value + this.enquiryForm.get('primaryphone').value;
        this.enquiryForm.get('primaryphone').setValue(mobileno);

        console.log('form', this.enquiryForm.value);

        this.mainservice.savelead(this.enquiryForm.value).subscribe(
          (data: any) => {

            this.leads = data;

            if (data) {
              this.enquiryForm.reset();
              this.showElementsave = false;
              Swal.fire({
                title: "Do you want to view the Brochure?",
                // showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: "yes",

              }).then((result) => {

                if (result.isConfirmed) {

              const url = 'https://medeilpos.blob.core.windows.net/websitedocuments/MEDEIL-PMSProductBrochure.pdf';
              window.open(url, '_blank');
                }
              });
              this.ngxsmartservice.close('myModal1');


            } else {
              this.enquiryForm.get('primaryphone').setValue(phone);
              this.showElement = false;
            }

            this.enquiryForm.get('countryname1').setValue('0');
            this.generatecaptcha();
          },
          (error: any) => {
            console.error('Error saving form', error);
            this.enquiryForm.get('primaryphone').setValue(phone);
            this.showElement = false;
          }
        );
      } else {
        this.showElement = false;
      }
    }
}
