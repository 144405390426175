import { Component } from '@angular/core';

@Component({
  selector: 'app-hqnavbar',
  templateUrl: './hqnavbar.component.html',
  styleUrls: ['./hqnavbar.component.scss']
})
export class HqnavbarComponent {

}
